import { stem } from "Aux";

const initialState = {
  filter: {
    data: [],
    predictions: [],
    reviewed: [],
  },
  title: {
    data: [],
    predictions: [],
    reviewed: [],
  },
  pool: {
    data: [],
    predictions: [],
    reviewed: [],
  },
  performer: {
    data: [],
    predictions: [],
    reviewed: [],
  },
  details: {
    data: [],
    predictions: [],
    reviewed: [],
  },
  source: {
    data: [],
    predictions: [],
    reviewed: [],
  },
  income: {
    data: [],
    predictions: [],
    reviewed: [],
  },
  country: {
    data: [],
    predictions: [],
    reviewed: [],
  },
  column: "Count",
  sortsign: -1,
  artists: null,
};


// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  let data, predictions, datatype, reviewed, artists;
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "SET_UNSYNCED":
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          unsynced: action.unsynced,
        },
      };
    // -------------------------------------------------------
    case "SET_ARTIST_STATE":
      artists = [...state.artists].map((artist) => {
        if (stem(artist.artist) !== stem(action.artist)) {
          return artist;
        }
        return {
          ...artist,
          isLoading: action.isLoading,
        };
      });
      return {
        ...state,
        artists,
      };
    // -------------------------------------------------------
    case "SET_ARTISTS":
      return {
        ...state,
        artists: action.artists,
      };
    // -------------------------------------------------------
    case "ADD_ARTIST":
      const artist = {
        artist: action.artist,
        isLoading: false,
        count: 1
      }
      if (state.artists) {
        return {
          ...state,
          artists: [...state.artists, artist],
        };
      }
      return {
        ...state,
        artists: [artist],
      };

    // -------------------------------------------------------
    case "SET_NORMING_SORT_SIGN":
      return {
        ...state,
        sortsign: action.sign,
      };

    // -------------------------------------------------------
    case "SET_NORMING_SORT_KEY":
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
        },
        column: action.key,
        sortsign: 1,
      };

    // -------------------------------------------------------
    case "CLEAR_NORMING":
      return {
        ...state,
        column: "Count",
        sortsign: 1,
        [action.datatype]: {
          data: [],
          predictions: [],
          reviewed: [],
        },
      };

    // -------------------------------------------------------
    case "SET_NORMING":
      return {
        ...state,
        [action.datatype]: {
          ...action.content,
        },
      };

    // -------------------------------------------------------
    case "ASSIGN_CONSENSUS":
      const map = [...state["title"].data];
      map[action.index] = {
        ...map[action.index],
        consensus: action.title,
      };
      return {
        ...state,
        title: {
          ...state.title,
          data: map,
        },
      };

    // -------------------------------------------------------
    case "SET_NORMING_DATA":
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          data: action.data,
        },
      };
    // -------------------------------------------------------
    case "SET_PREDICTION":
      predictions = JSON.parse(
        JSON.stringify(state[action.datatype].predictions)
      );
      predictions[action.index] = action.value;
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          predictions,
        },
      };
    // -------------------------------------------------------
    case "SET_PREDICTIONS":
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          predictions: action.predictions,
        },
      };
    // -------------------------------------------------------
    case "SET_PREDICTION_APPROVAL":
      reviewed = JSON.parse(JSON.stringify(state[action.datatype].reviewed));
      reviewed[action.index] = action.approval;
      return {
        ...state,
        [action.datatype]: {
          ...state[action.datatype],
          reviewed,
        },
      };
    // -------------------------------------------------------
    case "CLEAR_NORMING_POOL":
      datatype = "pool";
      data = state[datatype].data;
      for (var ii = 0; ii < data.length; ii++) {
        data[ii]["Pool_Revenue_9LC"] = null;
      }

      return {
        ...state,
        [datatype]: {
          ...state[datatype],
          data,
        },
      };
    // -------------------------------------------------------
    case "COPY_PREDICTIONS_POOL":
      datatype = "pool";
      data = state[datatype].data;
      predictions = state[datatype].predictions;
      for (var ii = 0; ii < data.length; ii++) {
        if (predictions[ii] !== undefined && predictions[ii] !== null) {
          data[ii]["Pool_Revenue_9LC"] = predictions[ii];
        }
      }

      return {
        ...state,
        [datatype]: {
          ...state[datatype],
          data,
        },
      };

    // -------------------------------------------------------
    // -------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
