import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
// ------------------------------------------------------
import { uploadNorming } from "System/system";
import { doNorming, doPredict } from "Redux/frame/actions";

// ------------------------------------------------------
import waitbar from "Media/waitbar.gif";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({ datatype, onClose }) => {
  const dispatch = useDispatch();
  const [waiting, setWaiting] = useState(false);
  const projects = useSelector((state) => state.projects);
  const { project } = projects;
  console.log(project);

  const onDrop = useCallback((acceptedFiles) => {
    setWaiting(true);
    const formData = new FormData();
    formData.append("filetoupload", acceptedFiles[0]);
    formData.append("datatype", datatype);
    formData.append("projectid", project._id);
    if (datatype !== "triple_pre") {
      uploadNorming(formData).then(() => {
        console.log("Done uploading");
        dispatch(
          doNorming(datatype, () => {
            setWaiting(false);
            onClose();
          })
        );
      });
    }
    if (datatype === "triple_pre") {
      uploadNorming(formData).then(() => {
        dispatch(
          doPredict(project._id, datatype, project.pivot, (result) => {
            if (result) {
              window.location.href = result.uri;
            }
            setWaiting(false);
            onClose();
          })
        );
      });
    }
  });

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Upload Norming File</p>
          <button className="delete" aria-label="close" onClick={onClose} />
        </header>
        <section className="modal-card-body">
          {waiting ? (
            <div className="drop-zone">
              <img src={waitbar} />
            </div>
          ) : (
            <div {...getRootProps()} className="drop-zone">
              <input {...getInputProps()} />
              Click or Drop
            </div>
          )}
        </section>
        <footer className="modal-card-foot">
          (<i>{datatype}</i>)
        </footer>
      </div>
    </div>
  );
};

export default Index;
