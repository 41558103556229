// -------------------------------------------------------
// -------------------------------------------------------
export const signIn = (user) => dispatch => {
  dispatch({ type: "SIGN_IN", user });
}

// -------------------------------------------------------
// -------------------------------------------------------
export const signOut = (user) => dispatch => {
  dispatch({ type: "SIGN_IN", user: null });
  localStorage.removeItem('user');
  window.location.href='./';  
}

// -------------------------------------------------------
// -------------------------------------------------------
export const notify = (headline, content, duration) => dispatch => {
  dispatch({ type: "NOTIFY", note: { headline, content } });
  setTimeout(
    () => { dispatch({ type: "NOTIFY", note: null }); }, 
    duration*1000
  )
}