import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import Uploader from "./Uploader";
// -------------------------------------------------------------
import { downloadNorming } from "Redux/frame/actions";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [datatype, setDatatype] = useState(null);
  const [loadStates, setLoadStates] = useState(null);

  const [stats, setStats] = useState(null);

  const projects = useSelector((state) => state.projects);
  const {
    project,
    project: { hasFrame },
  } = projects;

  const types = [
    {
      headline: "Full Songs (Consensus)",
      icon: "fas fa-music",
      type: "triple",
    },
    {
      headline: "Full Songs (Original)",
      icon: "fas fa-music",
      type: "triple2",
    },
    {
      headline: "Income Types",
      icon: "fas fa-money-check-alt",
      type: "income",
    },
    {
      headline: "Sources",
      icon: "fas fa-code-branch",
      type: "source",
    },
    {
      headline: "Country",
      icon: "fas fa-globe-americas",
      type: "country",
    },
    {
      headline: "Production",
      icon: "fas fa-film",
      type: "production",
    },
  ];

  const makePercent = (x) => Math.round(10000 * (1 - x)) / 100;

  return (
    <div className="box">
      {datatype ? (
        <Uploader
          datatype={datatype}
          onClose={() => {
            setDatatype(null);
          }}
        />
      ) : null}

      <div className="table-container">
        <table className="table is-striped is-bordered is-fullwidth">
          <thead>
            <tr>
              <th>Category</th>
              <th>Download</th>
              <th>Upload</th>
              <th>Predict</th>
            </tr>
          </thead>
          <tbody>
            {types.map((item, itemindex) => {
              const isLoading = loadStates && loadStates[item.type];
              console.log(item.type, loadStates, isLoading);
              console.log("----");
              return (
                <tr key={item.type}>
                  <td>
                    <i className={item.icon}></i>&nbsp;&nbsp;&nbsp;
                    {item.headline}
                  </td>
                  <td>
                    <button
                      className={`button is-small is-fullwidth ${
                        isLoading ? "is-loading" : ""
                      }`}
                      onClick={() => {
                        setLoadStates({ ...loadStates, [item.type]: true });
                        dispatch(
                          downloadNorming(item.type, (result) => {
                            setLoadStates({
                              ...loadStates,
                              [item.type]: false,
                            });

                            window.location.href = result.url;
                          })
                        );
                      }}
                    >
                      <span className="icon is-small">
                        <i className="fas fa-download"></i>
                      </span>
                    </button>
                  </td>
                  <td>
                    <button
                      className="button is-small is-fullwidth"
                      onClick={() => {
                        setDatatype(item.type);
                      }}
                    >
                      <span className="icon is-small">
                        <i className="fas fa-upload"></i>
                      </span>
                    </button>
                  </td>
                  <td>
                    {itemindex ? null : (
                      <button
                        className="button is-small is-fullwidth"
                        onClick={() => {
                          setDatatype(item.type + "_pre");
                        }}
                      >
                        <span className="icon is-small">
                          <i className="fas fa-project-diagram"></i>
                        </span>
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Index;
