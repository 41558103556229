const initialState = {
  title: null,
  status: null,
  current: null,
  total: null
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = ( state = initialState, action ) => {

  // -------------------------------------------------------
  switch(action.type) {

    // --------------------------------------------------------------
    case "INIT_MAIN_PROGRESS":
      return { 
        ...state, 
        title: action.title, 
        status: "Initializing...",
        current: 0,
        total: action.total
      };

    // --------------------------------------------------------------
    case "UPDATE_MAIN_PROGRESS":
      return { ...state, current: action.current, status: action.status }

    // --------------------------------------------------------------
    case "CLOSE_MAIN_PROGRESS":
      return { ...initialState }

    // -------------------------------------------------------
    default:
      return state;


  }
}

export default reducer;