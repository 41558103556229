import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AutoComplete } from "primereact/autocomplete";
// -------------------------------------------------------------
import { atoz, zerotonine } from "Aux/";
// -------------------------------------------------------------
import {
  getSongs,
  removeExcludedSong,
  addExcludedSong,
  excludeAllSongs,
  includeAllSongs,
} from "Redux/filters/actions";
// -------------------------------------------------------------
import waitbar from "Media/waitbar.gif";
import "./css.css";

import "primereact/resources/themes/lara-light-cyan/theme.css";

const setDiff = (a, b) => {
  return a.filter((x) => !b.includes(x));
};

const aZ = atoz();
const zeroToNine = zerotonine();
const aZAll = [...aZ, ...zeroToNine];
// sort songs alphabetically
const sortfunc = (a, b) => {
  return a.toUpperCase().localeCompare(b.toUpperCase());
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [songs, setSongs] = useState(null);
  const [isUnapplied, setIsUnapplied] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSongs((unique) => {
      setSongs(unique);
    });
  }, []);

  const projects = useSelector((state) => state.projects);
  const { project } = projects;

  if (songs === null)
    return (
      <div>
        <img src={waitbar} />
      </div>
    );

  if (!projects) return null;
  if (!project.hasFrame) return null;

  const excludedSongs = project.excludedSongs;
  const included = setDiff(songs, excludedSongs);

  return (
    <div>
      <div className="columns">
        <div className="column">
          <div className="filter-top-row">
            <Dropdown
              songs={excludedSongs}
              onPickSong={(song) => {
                dispatch(removeExcludedSong(song));
              }}
              placeholder="Type to include song..."
            />

            <div className="button" onClick={() => dispatch(includeAllSongs())}>
              <span className="icon is-small">
                <i className="fa-duotone fa-check-double"></i>
              </span>
              <span>Include All</span>
            </div>
          </div>

          <h5 className="title is-5">Included ({included.length})</h5>
          {aZAll.map((letter, index) => (
            <SongCluster
              key={`atoz-included-${letter}`}
              songs={included}
              letter={letter}
              onClick={(song) => {
                dispatch(addExcludedSong(song));
              }}
            />
          ))}
        </div>
        <div className="column">
          <div className="filter-top-row">
            <Dropdown
              songs={included}
              onPickSong={(song) => {
                dispatch(addExcludedSong(song));
              }}
              placeholder="Type to exclude song..."
            />

            <div
              className="button"
              onClick={() => dispatch(excludeAllSongs(songs))}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-ban"></i>
              </span>
              <span>Exclude All</span>
            </div>
          </div>
          <h5 className="title is-5">Excluded ({excludedSongs.length})</h5>
          {aZAll.map((letter, index) => (
            <SongCluster
              key={`atoz-excluded-${letter}`}
              songs={excludedSongs}
              letter={letter}
              onClick={(song) => {
                dispatch(removeExcludedSong(song));
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const SongCluster = ({ songs, letter, onClick }) => {
  const letteredSongs = songs.filter(
    (song) => song.toUpperCase().charAt(0) === letter
  );
  const nSongs = letteredSongs.length;
  if (!nSongs) return null;
  return (
    <div style={{ margin: "15px 0" }}>
      <b>{letter}</b>
      <div className="tags">
        {letteredSongs.sort(sortfunc).map((song, songindex) => {
          return (
            <div
              key={`${letter}-${songindex}`}
              className="tag is-clickable"
              onClick={() => onClick(song)}
            >
              {song}
            </div>
          );
        })}
      </div>
    </div>
  );
};

// -------------------------------------------------------------
// -------------------------------------------------------------

const Dropdown = ({ songs, onPickSong, placeholder }) => {
  const [text, setText] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [items, setItems] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    updateSongList("");
  }, [songs]);

  const updateSongList = (query) => {
    /*const filteredSongs = query
      ? songs.filter((song) => song.toUpperCase().includes(query.toUpperCase()))
      : songs;*/
    const filteredSongs = songs.filter((song) =>
      song.toUpperCase().includes(query.toUpperCase())
    );
    setItems(filteredSongs.sort(sortfunc));
    setActive(0);
  };

  const pickSong = (song) => {
    onPickSong(song);
    setText("");
    updateSongList("");
  };

  const incActive = () => {
    if (active < items.length - 1) setActive(active + 1);
  };

  const decActive = () => {
    if (active > 0) setActive(active - 1);
  };

  return (
    <div className={`dropdown ${dropdown ? "is-active" : ""}`}>
      <input
        className="input"
        type="text"
        placeholder={placeholder}
        value={text}
        onFocus={() => {
          setText("");
          updateSongList("");
          setDropdown(true);
        }}
        onBlur={() => {
          setText("");
          setDropdown(false);
        }}
        onChange={(evt) => {
          setText(evt.target.value);
          updateSongList(evt.target.value);
        }}
        onKeyDown={(evt) => {
          switch (evt.key) {
            case "ArrowDown":
              incActive();

              break;
            case "ArrowUp":
              decActive();

              break;
            case "Enter":
            case "Tab":
              pickSong(items[active]);
              break;
            case "Escape":
              setDropdown(false);
              break;
            default:
          }
        }}
      />

      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content">
          {items.map((item, index) => (
            <a
              key={`dropdown-item-${index}`}
              className={`dropdown-item ${index === active ? "is-active" : ""}`}
            >
              {item}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
