const initialState = {
  user: null,
  lastUpdate: "August 15, 2024. 1500 CET.",
  version: "0.098",
  note: null,
  backendaccess: false,
  dbaccess: false,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    // --------------------------------------------------------------
    case "SIGN_IN":
      return { ...state, user: action.user };

    // --------------------------------------------------------------
    case "NOTIFY":
      return { ...state, note: action.note };

    // --------------------------------------------------------------
    case "SET_BACKEND_ACCESS":
      return { ...state, backendaccess: action.backendaccess };
    // --------------------------------------------------------------
    case "SET_DB_ACCESS":
      return { ...state, dbaccess: action.dbaccess };

    // -------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
