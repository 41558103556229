import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { setSortKey, flipSign } from "Redux/norming/actions";

// -------------------------------------------------------------
// -------------------------------------------------------------
export const ClickableHeader = ({ thisKey, children, datatype }) => {
  const dispatch = useDispatch();
  const norming = useSelector((state) => state.norming);
  const { sortsign, column } = norming;

  return (
    <th
      onClick={() => {
        column === thisKey
          ? dispatch(flipSign(datatype))
          : dispatch(setSortKey(datatype, thisKey));
      }}
      className="clickable-header"
    >
      {children}

      {column === thisKey ? (
        sortsign < 0 ? (
          <span className="sort-sign">&nbsp; &#9650;</span>
        ) : (
          <span className="sort-sign">&nbsp; &#9660;</span>
        )
      ) : null}
    </th>
  );
};
