const initialState = {
  
  mainDir: null,
  flatFiles: [],

  pdfs: [], 
  csvs: [],
  auditFiles: [],
  zipFiles: [],

  projectid: null,

  auditpdfs: [], auditcsvs: [],
  current: null, currentDir: '',
  header: null,
  lines: null,
  file: null,
  csv: null,
  nlines: null,
  sum: null,
  heading: null,
  headers: null,
  dbheaders: null,
  sums: null,
  sumheaders: null, 
  table: null,
  prepDownload: null,
  pdf: null,
  newProject: false,
  projects: [],
  project: null,
  unsaved: false,

  recursiveProgress: null,
  bulkProgress: null,
  xls2xlsxProgress: null,
  xls2xlsxProgress: null,
  progressPopup: null,
  viewer: null
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = ( state = initialState, action ) => {

  var auditpdfs, auditcsvs;

  // -------------------------------------------------------
  switch(action.type) {

    // -------------------------------------------------------
    case 'HIDE_FILE_VIEWER':
      return { ...state, viewer: null }

    // -------------------------------------------------------
    case 'DISPLAY_FILE_VIEWER':
      return { ...state, viewer: { filetype: action.filetype, data: null } }

    // -------------------------------------------------------
    case 'SET_FILE_VIEWER_DATA':
      return { ...state, viewer: { ...state.viewer, data: action.data } }

    // -------------------------------------------------------
    case 'SET_LINE_COUNT':
      return { 
        ...state, 
        viewer: { ...state.viewer, 
          data: { ...state.viewer.data, nLines: action.nLines } 
        } 
      }
    // -------------------------------------------------------
    case 'SET_FULL_CSV':
      return { 
        ...state, 
        viewer: { ...state.viewer, 
          data: { ...state.viewer.data, data: action.data, fullFile: true } 
        } 
      }
    // -------------------------------------------------------
    case 'SET_PROGRESS':
      
      if(action.pointer === null) {
        return { ...state, progressPopup: null }
      }
      
      return { 
        ...state,
        progressPopup: {
          headline: action.headline, mainline: action.mainline,
          pointer: action.pointer, status: action.status, 
          total: action.total
        }
      }
    // -------------------------------------------------------
    case 'SET_XLS2XLSX_PROGRESS':
      if(action.pointer === null) {
        return { 
          ...state, 
          xls2xlsxProgress: null 
        }
      }
      return { 
        ...state,
        xls2xlsxProgress: {
          headline: action.headline,
          pointer: action.pointer,
          status: action.status,
          total: action.total
        }
      }
    // -------------------------------------------------------
    case 'SET_XLSX2CSV_PROGRESS':
      if(action.pointer === null) {
        return { 
          ...state, 
          xlsx2csvProgress: null 
        }
      }
      return { 
        ...state,
        xlsx2csvProgress: {
          headline: action.headline,
          pointer: action.pointer,
          status: action.status,
          sheets: action.sheets,
          total: action.total
        }
      }

    // -------------------------------------------------------
    case "SET_PROJECT_FILES":
      return {
        ...state,
        mainDir: action.project.mainDir,
        flatFiles: action.project.flatFiles
      }

    // -------------------------------------------------------
    case 'SET_PDFS':
      return {
        ...state,
        pdfs: action.pdfs
      }
    // -------------------------------------------------------
    case 'SET_TABLE':
      return {
        ...state,
        table: action.table
      }
    // -------------------------------------------------------
    case 'SET_CSVS':
      return {
        ...state,
        csvs: action.csvs
      }
    // -------------------------------------------------------
    case 'SET_FILES':
      return {
        ...state,
        pdfs: action.pdfs,
        csvs: action.csvs
      }
    // -------------------------------------------------------
    case 'SET_AUDIT_FILES':
      auditpdfs = [ ...state.project.auditpdfs ];
      auditcsvs = [ ...state.project.auditcsvs ];

      action.pdfs.forEach(
        pdf => {
          if(!auditpdfs.find( x => x.id === pdf.id )) {
            auditpdfs.push(pdf)
          }
        }
      );

      action.csvs.forEach(
        csv => {
          if(!auditcsvs.find( x => x.id === csv.id )) {
            auditcsvs.push(csv)
          }
        }
      );

      return { 
        ...state, 
        project: {
          ...state.project, 
          auditpdfs, auditcsvs
        },
        unsaved: true
      }
      
    // -------------------------------------------------------
    case 'SET_UNZIP_PROGRESS':
      if(action.pointer === null) {
        return { ...state, recursiveProgress: null }
      }
      return { 
        ...state, 
        recursiveProgress: {
          pointer: action.pointer,
          status: action.status,
          total: action.total
        }
      }
    // -------------------------------------------------------
    case 'SET_UNZIPPED':

      return { 
        ...state, 
        recursiveProgress: null,
        project: {
          ...state.project, zipFiles: []
        }
      }
    // -------------------------------------------------------
    case 'SET_BULK_PROGRESS':
      if(action.pointer === null) {
        return { 
          ...state, 
          bulkProgress: null 
        }
      }
      return { 
        ...state,
        bulkProgress: {
          headline: action.headline,
          pointer: action.pointer,
          status: action.status,
          total: action.total
        }
      }
    // -------------------------------------------------------
    case 'SET_BULK_COMPLETED':

      return { 
        ...state, 
        bulkProgress: null,
        project: {
          ...state.project, rawFiles: []
        }
      }
    // -------------------------------------------------------
    case 'SET_CURRENT':
      return { 
        ...state, 
        current: action.current,
        currentDir: action.currentDir 
      }

    // -------------------------------------------------------
    case 'SET_SINGLE_FILE':
      return { 
        ...state, 
        csv: action.csv
      }
    // -------------------------------------------------------
    case 'SET_PDF':
      return { 
        ...state, 
        pdf: action.pdf
      }
    // -------------------------------------------------------
    case 'SET_SUMS':
      return { 
        ...state, 
        sums: action.set
      }
    // -------------------------------------------------------
    case 'SET_SUMHEADERS':
      return { 
        ...state, 
        sumheaders: action.sumheaders
      }
    // -------------------------------------------------------
    case 'SET_HEADER':
      return { 
        ...state, 
        header: action.header
      }
    // -------------------------------------------------------
    case 'SET_LINES':
      return { 
        ...state, 
        lines: action.lines
      }
    // -------------------------------------------------------
    case 'SET_SIGMA':
      return { 
        ...state, 
        nlines: action.nlines,
        sum: action.sum,
        heading: action.heading
      }
    // -------------------------------------------------------
    case 'SET_HEADERS':
      return { 
        ...state, 
        headers: action.headers
      }
    // -------------------------------------------------------
    case 'SET_DB_HEADERS':
      return { 
        ...state, 
        dbheaders: action.dbheaders
      }
    // -------------------------------------------------------
    case 'SET_DB_HEADER_DATATYPE':
      let dbheaders = [ ...state.dbheaders ];
      dbheaders[action.headerindex].datatype = action.datatype;
      return { 
        ...state, 
        dbheaders
      }
    // -------------------------------------------------------
    case 'SET_PREP_DOWNLOAD':
      return { 
        ...state, 
        prepDownload: action.prepDownload
      }

    // -------------------------------------------------------
    default:
      return state;
  }
}

export default reducer;