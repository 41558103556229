import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { ClickableHeader } from "../Shared/ClickableHeader/";
// -------------------------------------------------------------
import {
  predictIncome,
  setPredictionApproval,
  setIncome,
  acceptAllIncomeTypes,
  setIncomeDual,
  loadNorming,
} from "Redux/norming/actions";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
// -------------------------------------------------------------
import running from "Media/running.gif";
import waitbar from "Media/waitbar.gif";
import "./css.css";

const datatype = "income";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [loadingNorming, setLoadingNorming] = useState(false);
  const [predicting, setPredicting] = useState(false);
  const [reviewing, setReviewing] = useState(null);

  const norming = useSelector((state) => state.norming);
  const projects = useSelector((state) => state.projects);
  const client = projects.project.client;
  const { data, unsynced } = norming[datatype];

  useEffect(() => {
    setLoadingNorming(true);
    dispatch(
      loadNorming(datatype, () => {
        setLoadingNorming(false);
      })
    );
  }, []);

  if (loadingNorming) return <img src={waitbar} />;

  return (
    <div>
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <div className="buttons has-addons">
            <div
              className={`button is-small ${predicting ? "is-loading" : ""}`}
              onClick={async () => {
                for (var ii = 0; ii < data.length; ii++) {
                  await dispatch(predictIncome(data, ii, "Legacy"));
                }
              }}
            >
              <span className="icon is-small">
                <i className="fas fa-network-wired" />
              </span>
              <span>Classify (Legacy)</span>
            </div>
            {client ? (
              <div
                className={`button is-small ${predicting ? "is-loading" : ""}`}
                onClick={async () => {
                  for (var ii = 0; ii < data.length; ii++) {
                    await dispatch(predictIncome(data, ii, client));
                  }
                }}
              >
                <span className="icon is-small">
                  <i className="fas fa-network-wired" />
                </span>
                <span>Classify ({client})</span>
              </div>
            ) : null}
          </div>
        </td>
        <td>&nbsp;&nbsp;</td>
        <td>
          <div
            className={`button is-small`}
            onClick={() => {
              if (window.confirm("Are you sure?") == true) {
                dispatch(acceptAllIncomeTypes());
              }
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-check" />
            </span>
            <span>Accept All</span>
          </div>
        </td>
      </NormingButtons>

      <MainTable norming={norming} setReviewing={setReviewing} />
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ norming, setReviewing }) => {
  const dispatch = useDispatch();

  const datatype = "income";
  const { income } = norming;
  if (!income.data) return null;
  if (!income.data.length) return null;
  const { data, predictions, reviewed } = income;

  const onEdit = () => {};

  return (
    <div className="table-wrapper">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>#</th>
            <ClickableHeader thisKey="Count" datatype={datatype}>
              Cnt
            </ClickableHeader>
            <ClickableHeader thisKey="Income_Type_SB" datatype={datatype}>
              Income Type
            </ClickableHeader>
            <ClickableHeader thisKey="Third_Party_9LC" datatype={datatype}>
              Payor
            </ClickableHeader>
            <th>Normalized I</th>
            <th>Normalized II</th>
            <th>Predicted</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => (
            <tr key={`row-${rowindex}`} className="is-clickable">
              <td>{rowindex + 1}</td>
              <td>{row["Count"]}</td>
              <td>
                <div className="tag is-info is-light">
                  {row["Income_Type_SB"]}
                </div>
              </td>
              <td>
                <div className="tag is-info is-light">
                  {row["Third_Party_9LC"]}
                </div>
              </td>
              <td>
                <InputTag
                  row={row}
                  rowindex={rowindex}
                  thiskey="Normalized_Income_Type_9LC"
                  whichtype="type1"
                />
              </td>
              <td>
                <InputTag
                  row={row}
                  rowindex={rowindex}
                  thiskey="Normalized_Income_Type_II_9LC"
                  whichtype="type2"
                />
              </td>
              <td>
                {predictions[rowindex] === null ? (
                  <img src={running} />
                ) : (
                  <div
                    className="tag"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      dispatch(setIncomeDual(rowindex, predictions[rowindex]));
                    }}
                  >
                    {predictions[rowindex].prediction1}&nbsp;/&nbsp;
                    {predictions[rowindex].prediction2}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// -------------------------------------------------------
// -------------------------------------------------------
const PredictedTag = ({ prediction }) => {
  if (prediction === undefined) return null;
  if (prediction === null)
    return (
      <div>
        <img src={running} />
      </div>
    );
  const predictedText = prediction === "X" ? "PR" : "Song";
  return <div className="tag">{predictedText}</div>;
};

// -------------------------------------------------------
// -------------------------------------------------------
const Review = ({ reviewing, onClose, onInc, onDec }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    checkPrediction();
  }, [reviewing]);

  const dispatch = useDispatch();

  const norming = useSelector((state) => state.norming);
  const { income } = norming;
  const { data, reviewed, predictions } = income;
  const dataSize = data.length;
  const numberReviewed = reviewed.filter((x) => x === true).length;

  const canDec = reviewing > 0;
  const canInc = reviewing < dataSize - 1;

  const row = data[reviewing];
  const prediction = predictions[reviewing];

  const checkPrediction = () => {
    if (predictions[reviewing] === undefined) {
      dispatch(predictIncome(data, reviewing));
    }
  };

  const onApprove = () => {
    dispatch(setPredictionApproval("income", reviewing, true));
    if (canInc) {
      onInc();
    }
  };

  const onEdit = (evt) => {
    setValue(evt.target.value.toUpperCase());
  };

  const onSubmit = (evt) => {
    dispatch(setIncome(reviewing, value));
    setEditing(false);
  };

  const isApproved = reviewed[reviewing];

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head convert">
          <div className="review-wrapper">
            <p className="modal-card-title">
              Review ({reviewing}/{dataSize})
            </p>
            <div className="review-progress">
              <progress
                className="convert progress is-primary"
                value={numberReviewed}
                max={income.data.length}
              />
            </div>
            <button className="delete" onClick={onClose} />
          </div>
        </header>
        <section className="modal-card-body">
          <div className="title">
            {row["Income_Type_SB"]}&nbsp;/&nbsp;{row["Third_Party_9LC"]}
            <div className="status-wrapper">
              {isApproved ? (
                <div className="tag is-success is-light is-small">
                  <i className="fa fa-check" />
                  &nbsp;Approved
                </div>
              ) : (
                <div className="tag is-danger is-light is-small">
                  <i className="fa fa-times" />
                  &nbsp;Not yet approved
                </div>
              )}
            </div>
          </div>
          <div className="table-container">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>Normed I</th>
                  <th>Normed II</th>
                  <th>Predicted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <InputTag
                      row={row}
                      rowindex={reviewing}
                      thiskey="Normalized_Income_Type_9LC"
                      whichtype="type1"
                    />
                  </td>
                  <td>
                    <InputTag
                      row={row}
                      rowindex={reviewing}
                      thiskey="Normalized_Income_Type_II_9LC"
                      whichtype="type2"
                    />
                  </td>
                  <td>
                    {typeof prediction !== "object" ? (
                      <div>
                        <img src={running} />
                      </div>
                    ) : (
                      <div
                        className="tag is-clickable"
                        onClick={() => {
                          dispatch(setIncome(reviewing, prediction));
                        }}
                      >
                        {prediction.prediction1}&nbsp;/&nbsp;
                        {prediction.prediction2}
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <footer className="modal-card-foot">
          {isApproved ? (
            <div className="footer-buttons">
              <button
                className="button is-pulled-left"
                onClick={onDec}
                disabled={!canDec}
              >
                <span className="icon is-small">
                  <i className="fa fa-chevron-left" />
                </span>
                <span>Previous</span>
              </button>

              <button
                className="button is-pulled-right is-primary"
                onClick={onInc}
              >
                <span>Next</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>
              <button
                className="button is-pulled-left"
                disabled={!canInc}
                onClick={() => {
                  dispatch(setPredictionApproval("pool", reviewing, false));
                }}
              >
                <span>Un-Approve</span>
              </button>
            </div>
          ) : (
            <div className="footer-buttons">
              <button
                className="button is-pulled-left"
                onClick={onDec}
                disabled={!canDec}
              >
                <span className="icon is-small">
                  <i className="fa fa-chevron-left" />
                </span>
                <span>Previous</span>
              </button>
              <button
                className="button is-primary is-pulled-right"
                disabled={!canInc}
                onClick={onApprove}
              >
                <span>Approve</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>

              <button className="button is-pulled-left" onClick={onInc}>
                <span>Next</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

const InputTag = ({ row, rowindex, thiskey, whichtype }) => {
  const dispatch = useDispatch();
  const [isInput, setIsInput] = useState(false);
  const [value, setValue] = useState(row[thiskey] || "");

  const onSubmit = () => {
    dispatch(setIncome(rowindex, value, whichtype));
    setIsInput(false);
  };

  if (isInput) {
    return (
      <input
        onClick={(evt) => evt.stopPropagation()}
        className="input is-small"
        value={value}
        onKeyDown={(evt) => evt.key === "Enter" && onSubmit()}
        onBlur={onSubmit}
        autoFocus={true}
        onChange={(evt) => setValue(evt.target.value)}
      />
    );
  }

  return (
    <div
      className="input-wrapper"
      onClick={(evt) => {
        evt.stopPropagation();
        setIsInput(true);
      }}
    >
      {row[thiskey] ? (
        <div className="tag is-primary is-light">{row[thiskey]}</div>
      ) : (
        <div>&nbsp;&nbsp;</div>
      )}
    </div>
  );
};
