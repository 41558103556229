import { useSelector } from "react-redux";

const QCB = () => {
  const projects = useSelector((state) => state.projects);
  if (!projects || !projects.project) return null;
  const pid = projects.project._id;

  return (
    <div className="box">
      <div className="height-75">
        <iframe
          className="iframe"
          src={`https://preview.bitsonic.ai/${pid}/QC`}
        />
      </div>
    </div>
  );
};

export default QCB;
