// -------------------------------------------------------
// -------------------------------------------------------
export const explicitSize = (nBytes, fac) => {
  const giga = Math.round(nBytes / 1000000000);
  const gigaRounded = Math.round((fac * nBytes) / 1000000000) / fac;
  if (giga) return { size: gigaRounded, unit: "GB" };

  const mega = Math.round(nBytes / 1000000);
  const megaRounded = Math.round((fac * nBytes) / 1000000) / fac;
  if (mega) return { size: megaRounded, unit: "MB" };

  const kilo = Math.round(nBytes / 1000);
  const kiloRounded = Math.round((fac * nBytes) / 1000) / fac;
  if (kilo) return { size: kiloRounded, unit: "KB" };

  const bytesRounded = Math.round(fac * nBytes) / fac;
  return { size: bytesRounded, unit: "B" };
};

// -------------------------------------------------------
// -------------------------------------------------------
export const stem = (x) => {
  if (!x) return x;
  return x
    .toUpperCase()
    .replace(/\([^()]*\)/g, "")
    .replace(/[^a-zA-Z0-9]/g, "");
};

// -------------------------------------------------------
// -------------------------------------------------------
export const capitalize = (input) => {
  if (input.split("(").length === 2) {
    return input.split("(").map(capitalize).join("(");
  }
  return input
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
};

// -------------------------------------------------------
// -------------------------------------------------------
export const atoz = () => {
  return Array.from(Array(26).keys()).map((i) => String.fromCharCode(i + 65));
};

export const zerotonine = () => {
  return Array.from(Array(10).keys()).map((i) => i.toString());
};
