const initialState = {
  frameData: null,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = ( state = initialState, action ) => {

  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "SET_FRAME_DATA":
      return {
        ...state,
        frameData: action.frameData,
      };
    // -------------------------------------------------------
    case "SET_ACTIVE_FRAMES":
      return {
        ...state,
        activeFrames: action.activeFrames,
      };

    // -------------------------------------------------------
    default:
      return state;
  }
}

export default reducer;