import { useSelector } from "react-redux";

const QCA = () => {
  const projects = useSelector((state) => state.projects);

  if (!projects || !projects.project) return null;

  const pid = projects.project._id;
  console.log("pid", pid);
  return (
    <div className="height-75">
      <iframe
        className="iframe"
        src={`https://preview.bitsonic.ai/${pid}/Overview`}
      />
    </div>
  );
};

export default QCA;
