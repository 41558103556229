// -------------------------------------------------------
// -------------------------------------------------------
export const setActiveTab = (current, ancestor) => dispatch => {
  dispatch({ type: "SET_ACTIVE_TAB", current, ancestor });
}


// -------------------------------------------------------
// -------------------------------------------------------
export const setCollection = (collection) => dispatch => {
  dispatch(setActiveTab('process', 'collections'));
  dispatch({ type: "SET_COLLECTION", collection });

}