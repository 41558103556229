import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
// --------------------------------------------------------------------
import { signOut } from "Redux/user/actions";
import {
  lockProject,
  showPivotPopup,
  eraseProject,
} from "Redux/projects/actions";
import { deactivateFrame, escapeFrame } from "Redux/frame/actions";
// --------------------------------------------------------------------
import SetPivot from "./SetPivot/";
// --------------------------------------------------------------------
import bird from "Media/logo_newer.png";
// --------------------------------------------------------------------
import "./frame.css";

// --------------------------------------------------------------------
// --------------------------------------------------------------------
const Frame = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [active, setActive] = useState("");
  const [activating, setActivating] = useState(false);

  const user = useSelector((state) => state.user);
  const projects = useSelector((state) => state.projects);
  const frame = useSelector((state) => state.frame);

  const { project } = projects;

  if (!project) return null;

  const frameOn = project.hasFrame
    ? frame.activeFrames
      ? frame.activeFrames.includes(project._id)
      : false
    : false;

  const isHidden = false; //project === null || project.mainDir === null ? "hidden" : "";

  // --------------------------------------------------------------------
  const erase = () => {
    if (window.confirm("Are you sure? This action cannot be undone.")) {
      dispatch(eraseProject(projects.project));
      history.push("/");
    }
  };

  // --------------------------------------------------------------------
  const onHamburger = () => {
    setActive(active === "is-active" ? "" : "is-active");
  };

  // --------------------------------------------------------------------
  const turnFrameOff = () => {
    setActivating(true);
    dispatch(
      deactivateFrame(() => {
        setActivating(false);
      })
    );
  };

  // --------------------------------------------------------------------
  return (
    <div className="frame-wrapper">
      {user.note ? (
        <div className="notifier-wrapper">
          <div className="notification is-primary is-light">
            <span>{user.note.headline}</span>
            <br />
            <b>{user.note.content}</b>
          </div>
        </div>
      ) : null}
      <nav
        className="navbar project-nav"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">
              <img src={bird} alt="Songbird Logo" />
            </Link>
          </div>
          <a
            role="button"
            className={`navbar-burger burger ${active}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={onHamburger}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isHidden}`}>
          <div className="navbar-start">
            <a className="navbar-item">
              <div className="field has-addons">
                {project.hasFrame ? (
                  frameOn ? (
                    <div className="buttons">
                      <button
                        className={`button is-success ${
                          activating ? "is-loading" : ""
                        }`}
                        onClick={turnFrameOff}
                      >
                        <span className="icon is-small">
                          <i className="fas fa-microchip"></i>
                        </span>
                        <span>Frame</span>
                      </button>
                    </div>
                  ) : null
                ) : null}
              </div>
            </a>
            <a className="navbar-item">
              <div className="field has-addons">
                <div className="buttons">
                  <button
                    className={`button is-warning is-light`}
                    onClick={() => {
                      dispatch(lockProject(project._id));
                      history.push("/");
                    }}
                  >
                    <span className="icon is-small">
                      <i className="fa-duotone fa-circle-xmark"></i>
                    </span>
                    <span>Close Project</span>
                  </button>
                </div>
              </div>
            </a>
            <div
              className={`navbar-item backend ${
                user.backendaccess ? "active" : ""
              }`}
            >
              <span className="icon is-small">
                <i className="fas fa-circle" />
              </span>
            </div>
            <div className={`navbar-item db ${user.dbaccess ? "active" : ""}`}>
              <span className="icon is-small">
                <i className="fas fa-circle" />
              </span>
            </div>

            <a
              className="navbar-item"
              onClick={() => {
                dispatch(showPivotPopup(true));
              }}
            >
              <div className="field has-addons">
                {project ? (
                  <div>
                    {project.isPerformer ? (
                      <div className="tag is-info is-light">
                        {project.pivot}&nbsp;|&nbsp; &nbsp;
                        <span className="icon is-small">
                          &nbsp;
                          <i className="fas fa-guitar" />
                          &nbsp; &nbsp;
                        </span>
                      </div>
                    ) : (
                      <div className="tag is-primary is-light">
                        {project.pivot}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </a>
          </div>
        </div>
        <div className="navbar-end">
          {frameOn ? (
            <div className="navbar-item">
              <button
                className="button is-small"
                onClick={() => {
                  dispatch(escapeFrame(() => {}));
                }}
              >
                Esc
              </button>
            </div>
          ) : null}
          <div className="navbar-item">
            <div className="tag is-info is-light">V{user.version}</div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">{user.user}</a>
            <div className="navbar-dropdown">
              <a className="navbar-item" onClick={erase}>
                Erase Project
              </a>
              <a
                className="navbar-item"
                onClick={() => {
                  dispatch(signOut());
                }}
              >
                Sign Out
              </a>
            </div>
          </div>
        </div>
      </nav>
      {project ? projects.pivotPopup ? <SetPivot /> : null : null}
      {children}
    </div>
  );
};

export default Frame;
