import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// -------------------------------------------------------------
import MainFrame from "Components/FrameProject/";
import Breadcrumbs from "./Navigation/Breadcrumbs";
import MainTabs from "./Navigation/MainTabs";

// -------------------------------------------------------------
// -------------------------------------------------------------
import { getProject, unlockProject } from "Redux/projects/actions";
import { getActiveFrames, activateFrame } from "Redux/frame/actions";
// -------------------------------------------------------------
import Frame from "./Units/Frame";
import Offline from "./Units/Offline";

import Filter from "./Units/Norming/Filter";
import Pool from "./Units/Norming/Pool";
import Song from "./Units/Norming/Titles";
import Performer from "./Units/Norming/Performer";
import Meta from "./Units/Norming/Details";
import Income from "./Units/Norming/Income";
import Source from "./Units/Norming/Source";
import SourceGroups from "./Units/Norming/SourceGroups";
import Country from "./Units/Norming/Country";
import CheckoutRepo from "./Units/CheckoutRepo";

import Flyway from "./Units/Flyway";
import QCA from "./Units/QCA";
import QCB from "./Units/QCB";
import Currencies from "./Units/Currencies";
import FilterSongs from "./Units/FilterSongs";
import Results from "./Units/Results";
// -------------------------------------------------------------
import bars from "Media/bars.gif";
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Screens = {
  frame: Frame,
  offline: Offline,
  normFilter: Filter,
  normPool: Pool,
  normSong: Song,
  normPerformer: Performer,
  normMeta: Meta,
  normIncome: Income,
  normSource: Source,
  normSourceGroups: SourceGroups,
  normCountry: Country,
  flyway: Flyway,
  qca: QCA,
  qcb: QCB,
  repository: CheckoutRepo,
  currencies: Currencies,
  filterSongs: FilterSongs,
  results: Results,
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const projects = useSelector((state) => state.projects);
  const nav = useSelector((state) => state.nav);
  const frame = useSelector((state) => state.frame);

  useEffect(() => {
    dispatch(getProject(params.projectid));
    dispatch(getActiveFrames());
    return () => {
      console.log("Unmounting Project");
      dispatch(unlockProject(params.projectid));
    };
  }, []);

  const [activating, setActivating] = useState(false);

  const { leaf } = nav;

  const Loading = () => (
    <div className="loading-project-wrapper">
      <img src={bars} alt="Loading Project" />
      <br />
      Loading...
    </div>
  );

  if (!projects.project) {
    return <Loading />;
  }

  const { project } = projects;

  // --------------------------------------------------------------------
  const turnFrameOn = () => {
    setActivating(true);
    dispatch(
      activateFrame(() => {
        setActivating(false);
      })
    );
  };

  // --------------------------------------------------------------------
  if (!project.hasFrame) {
    return (
      <MainFrame>
        <div className="box">
          <div className="notification is-warning is-light">No Frame Found</div>
          <div className="frame-note">
            <p>
              Flyway requires a frame to be generated first. You can generate
              frames from SongBird.
            </p>
          </div>
          <a
            href={`https://songbird.bitsonic.ai/projects/${project._id}`}
            target="_blank"
            className="button is-primary"
          >
            <span className="icon is-small">
              <i className="fas fa-external-link-alt" />
            </span>
            <span>Generate Frame In Songbird</span>
          </a>
        </div>
        <Breadcrumbs />
      </MainFrame>
    );
  }

  // --------------------------------------------------------------------
  if (!frame.activeFrames) return <Loading />;

  if (!frame.activeFrames.includes(project._id)) {
    return (
      <MainFrame>
        <div className="box">
          <div className="notification is-warning is-light">
            Frame is Turned Off
          </div>
          <div className="frame-note">
            <button
              className={`button ${activating ? "is-loading" : ""}`}
              onClick={turnFrameOn}
            >
              <span className="icon is-small">
                <i className="fas fa-microchip"></i>
              </span>
              <span>Frame</span>
            </button>
          </div>
        </div>
        <Breadcrumbs />
      </MainFrame>
    );
  }

  // --------------------------------------------------------------------
  const ActiveScreen =
    Screens.hasOwnProperty(leaf) && projects.project !== null
      ? Screens[leaf]
      : () => <div></div>;

  return (
    <MainFrame>
      <div className="box">
        <MainTabs>
          <ActiveScreen />
        </MainTabs>
      </div>
      <Breadcrumbs />
    </MainFrame>
  );
};

export default Index;
