export const canonicalHeaders = [
  { header: "Original_Song_Title_SB", datatype: "varchar" },
  { header: "Royalty_Payable_SB", datatype: "float" },
  { header: "Source_SB", datatype: "varchar" },
  { header: "Source_Chain_SB", datatype: "varchar" },
  { header: "Event_Date_SB", datatype: "varchar" },
  { header: "Income_Type_SB", datatype: "varchar" },
  { header: "Income_Type_II_SB", datatype: "varchar" },

  { header: "Country_SB", datatype: "varchar" },
  { header: "Hip_Region_SB", datatype: "varchar" },

  { header: "SONGID_ISRC_SB", datatype: "varchar" },
  { header: "SONGID_ISWC_SB", datatype: "varchar" },
  { header: "SONGID_TuneCode_SB", datatype: "varchar" },
  { header: "SONGID_UPC_SB", datatype: "varchar" },
  { header: "Payer_Song_ID_SB", datatype: "varchar" },
  { header: "Plays_SB", datatype: "float" },
  { header: "Composer_SB", datatype: "varchar" },

  { header: "Bonus_Credits_SB", datatype: "varchar" },
  { header: "Bonus_Royalty_SB", datatype: "varchar" },

  { header: "Payee_Song_Share_SB", datatype: "float" },
  { header: "Payee_Control_of_Income_SB", datatype: "float" },
  { header: "Payee_Gross_Royalty_Amount_SB", datatype: "float" },
  { header: "Payee_Percent_Received_SB", datatype: "float" },
  { header: "Payer_Import_Code_SB", datatype: "varchar" },
  { header: "Source_Payer_Report_Period_SB", datatype: "varchar" },
  { header: "Source_Product_Number_SB", datatype: "varchar" },
  { header: "Source_Product_Number_II_SB", datatype: "varchar" },
  { header: "Artist_Recording_SB", datatype: "varchar" },
  { header: "Song_Recording_SB", datatype: "varchar" },
  { header: "Song_Product_SB", datatype: "varchar" },
  { header: "Inferred_Original_Currency_SB", datatype: "varchar" },
  { header: "Original_Currency_SB", datatype: "varchar" },
];

export const updateHeaders = [
  { header: "File_Name_9LC", datatype: "varchar" },
  { header: "Catalogue_9LC", datatype: "varchar" },
  { header: "Third_Party_9LC", datatype: "varchar" },
  { header: "Statement_Period_9LC", datatype: "varchar" },
  { header: "Contract_ID_9LC", datatype: "varchar" },
  { header: "Year_Statement_9LC", datatype: "varchar" },
  { header: "Half_Statement_9LC", datatype: "varchar" },
  { header: "Quarter_Statement_9LC", datatype: "varchar" },
  { header: "Month_Statement_9LC", datatype: "varchar" },
  { header: "Statement_Period_Half_9LC", datatype: "varchar" },
  { header: "Management_Note_9LC", datatype: "varchar" },
  { header: "Statement_End_Date_9LC", datatype: "varchar" },
  { header: "Distribution_Date_9LC", datatype: "varchar" },
  { header: "Payout_Currency_9LC", datatype: "varchar" },
  { header: "Rights_Type_9LC", datatype: "varchar" },
];

export const songHeaders = [
  { header: "Song_Name_9LC", datatype: "varchar" },
  { header: "Pool_Revenue_9LC", datatype: "varchar" },
  { header: "Sample_9LC", datatype: "varchar" },
  { header: "Featured_Artist_9LC", datatype: "varchar" },
  { header: "Remix_Status_9LC", datatype: "varchar" },
  { header: "Release_Artist_9LC", datatype: "varchar" },
  { header: "Project_Type_9LC", datatype: "varchar" },
  { header: "Album_Name_9LC", datatype: "varchar" },
  { header: "Release_Date_9LC", datatype: "varchar" },
  { header: "Information_Source_9LC", datatype: "varchar" },
  { header: "Spotify_ID_9LC", datatype: "varchar" },
  { header: "Apple_ID_9LC", datatype: "varchar" },
  { header: "Genius_ID_9LC", datatype: "varchar" },
];

export const sourceHeaders = [
  { header: "Normalized_Source_9LC", datatype: "varchar" },
];

export const incomeTypeHeaders = [
  { header: "Normalized_Income_Type_9LC", datatype: "varchar" },
  { header: "Normalized_Income_Type_II_9LC", datatype: "varchar" },
];

export const territoryHeaders = [
  { header: "Normalized_Country_9LC", datatype: "varchar" },
];

export const dateHeaders = [
  { header: "Event_Start_DD", datatype: "date" },
  { header: "Event_End_DD", datatype: "date" },
];

export const derivedHeaders = [
  { header: "Territory_Status_DD", datatype: "tinyint" },
  { header: "Region_DD", datatype: "varchar" },
  { header: "Subregion_DD", datatype: "varchar" },
  { header: "Intermediate_Region_DD", datatype: "varchar" },
  { header: "Regional_Block_DD", datatype: "varchar" },
];


export const collectionCoreHeaders = [...canonicalHeaders, ...updateHeaders, ...dateHeaders];
export const masterHeaders = [
  ...canonicalHeaders,
  ...updateHeaders,
  ...songHeaders,
  ...sourceHeaders,
  ...incomeTypeHeaders,
  ...territoryHeaders,
  ...dateHeaders,
  ...derivedHeaders,
];
