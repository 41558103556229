const initialState = {
  timePeriods: null
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = ( state = initialState, action ) => {

  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "SET_OUTPUT_TIME_PERIODS":
      return {
        ...state,
        timePeriods: action.timePeriods,
      };

    // -------------------------------------------------------
    case "SET_SUMMARY_TOP_VIEW":
      return {
        ...state,
        topView: action.topView,
      };

    // -------------------------------------------------------
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.summary,
      };
    // -------------------------------------------------------
    case "UPDATE_SUMMARY_DETAILS":
      const catalogs = [...state.summary.catalogs];
      catalogs[action.catalogIndex] = {
        ...catalogs[action.catalogIndex],
        royalty: action.Royalty,
        lines: action.Lines,
      };
      return {
        ...state,
        summary: {
          ...state.summary,
          catalogs,
        },
      };

    // -------------------------------------------------------
    default:
      return state;
  }
}

export default reducer;