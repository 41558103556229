import levenshtein from "fast-levenshtein";
import { downloadDataFile, backend, apFly } from "System/system";
import store from "System/mainStore";

const stem = (x) =>
  x
    .toUpperCase()
    .replace(/\([^()]*\)/g, "")
    .replace(/[ \?,\.\/']/g, "");

// -------------------------------------------------------
// -------------------------------------------------------
const setUnsynced = (datatype, unsynced) => (dispatch) => {
  dispatch({ type: "SET_UNSYNCED", datatype, unsynced });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const fetchNorming = (datatype, cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project: { database },
  } = projects;
  const result = await apFly("fetchNorming", { datatype, database });
  if (result.data) {
    const { normFrame, dataSize } = result.data;
    const columns = Object.keys(normFrame);
    const dataArray = [];
    for (var ii = 0; ii < dataSize; ii++) {
      dataArray[ii] = {};
      columns.forEach((column, columnindex) => {
        dataArray[ii][column] = normFrame[column][ii];
      });
    }
    const predictions = [];
    const reviewed = [];
    for (var ii = 0; ii < dataArray.length; ii++) {
      predictions[ii] = -1;
      reviewed[ii] = false;
    }

    let data = dataArray;

    const consensusTitles = new Set();
    if (datatype === "title") {
      const consensusData = [];
      for (const row of data) {
        if (row["Pool_Revenue_9LC"] !== "X" && row["Consensus_Title_SB"]) {
          consensusTitles.add(row["Consensus_Title_SB"]);
          /*
          consensusData.push({
            consensus: row["Original_Song_Title_SB"],
            titles: [row["Original_Song_Title_SB"]],
          });*/
        }
      }
      const allConsensi = Array.from(consensusTitles);
      const nonPRData = data.filter((x) => x["Pool_Revenue_9LC"] !== "X");
      for (const consensus of allConsensi) {
        const titles = nonPRData
          .filter((x) => x["Consensus_Title_SB"] === consensus)
          .map((x) => x["Original_Song_Title_SB"]);
        consensusData.push({
          consensus,
          titles,
        });
      }
      data = consensusData.sort((a, b) => (a.consensus > b.consensus ? 1 : -1));
    }

    if (["performer", "details"].includes(datatype)) {
      data = data.filter((x) => x["Song_Name_9LC"] !== "Pool Revenue");
    }

    dispatch({
      type: "SET_NORMING",
      datatype,
      content: {
        data,
        predictions,
        reviewed,
      },
    });
    dispatch(saveNorming(datatype, data, predictions, reviewed));
  }
  cb(result);
  dispatch(setUnsynced(datatype, false));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const groupTitles = (threshold) => async (dispatch) => {
  const datatype = "title";
  const { data } = store.getState().norming[datatype];

  const song2stem = (song) => {
    return song
      .toUpperCase()
      .replace(/\([^()]*\)/g, "")
      .replace(/\([^()]*/g, "")
      .replace(/[^A-Z]/g, "")
      .replace(/ the /gi, " ")
      .replace(/^the/gi, " ")
      .replace(/the$/g, " ")
      .trim();
  };

  const groups = {};
  const allTitles = [];
  for (const row of data) {
    for (const title of row.titles) {
      allTitles.push(title);
    }
  }

  const distance = (a, b) => {
    return (2 * levenshtein.get(a, b)) / (a.length + b.length);
  };

  for (const title of allTitles) {
    const keys = Object.keys(groups);
    const stem = song2stem(title);
    let inExistingGroup = false;
    for (const key of keys) {
      if (distance(key, stem) < threshold) {
        groups[key].push(title);
        inExistingGroup = true;
      }
    }
    if (!inExistingGroup) {
      groups[stem] = [title];
    }
  }

  const consensusData = Object.keys(groups).map((key) => ({
    stem: key,
    titles: groups[key],
    consensus: groups[key][0],
  }));
  const newData = consensusData.sort((a, b) =>
    a.consensus > b.consensus ? 1 : -1
  );

  dispatch({
    type: "SET_NORMING",
    datatype,
    content: {
      data: newData,
    },
  });
  dispatch(setUnsynced(datatype, true));
  dispatch(saveNorming(datatype, data, [], []));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const groupTitlesAI = () => async (dispatch) => {
  const datatype = "title";
  const { data } = store.getState().norming[datatype];
  let songs = data.map((d) => d.consensus);
  const total = songs.length;
  dispatch({
    type: "INIT_MAIN_PROGRESS",
    title: "Grouping Titles",
    total,
  });

  let groups = [];
  let cnt = 0;
  while (songs.length) {
    dispatch({
      type: "UPDATE_MAIN_PROGRESS",
      current: total - songs.length,
      status: `Songs remaining: ${songs.length}`,
    });

    const res = await backend("norming", "groupTitles", {
      songs: songs.join(" | "),
    });
    if (res.data) {
      const newGroups = JSON.parse(res.data.groups);

      const newTitles = [];
      for (const items of newGroups) {
        for (const item of items) {
          newTitles.push(item);
        }
      }
      groups = [...groups, ...newGroups];
      songs = songs.filter((d) => !newTitles.includes(d));
    }
  }
  console.log(groups);
  dispatch({
    type: "CLOSE_MAIN_PROGRESS",
  });

  const newData = [];
  for (const group of groups) {
    const groupSet = new Set(group);
    newData.push({
      titles: Array.from(groupSet),
      consensus: group[0],
    });
  }
  dispatch({
    type: "SET_NORMING",
    datatype,
    content: {
      data: newData,
    },
  });
  dispatch(setUnsynced(datatype, true));
  dispatch(saveNorming(datatype, data, [], []));
};
// -------------------------------------------------------
// -------------------------------------------------------
export const groupTitlesAI__ = () => async (dispatch) => {
  const datatype = "title";
  const { data } = store.getState().norming[datatype];
  let songs = data.map((d) => d.consensus);
  const total = Math.round(songs.length / 10);
  dispatch({
    type: "INIT_MAIN_PROGRESS",
    title: "Grouping Titles",
    total,
  });

  let groups = [];
  let cnt = 0;
  while (songs.length) {
    dispatch({
      type: "UPDATE_MAIN_PROGRESS",
      current: cnt++,
      status: `Songs remaining: ${songs.length}`,
    });

    const res = await backend("norming", "groupTitles", {
      songs: songs.join(" | "),
    });
    if (res.data) {
      const newGroups = JSON.parse(res.data.groups);
      console.log(cnt);
      console.log("------\n");
      console.log(newGroups);
      console.log("------\n");

      const newTitles = [];
      for (const items of newGroups) {
        for (const item of items) {
          newTitles.push(item);
        }
      }
      groups = [...groups, ...newGroups];
      songs = songs.filter((d) => !newTitles.includes(d));
    }
  }
  //groups = groups.sort((a,b) => a.length > b.length ? -1 : 1);
  console.log(groups);
  dispatch({
    type: "CLOSE_MAIN_PROGRESS",
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const syncNorming = (datatype, cb) => async (dispatch) => {
  const norming = store.getState().norming[datatype];
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;

  let normdata = [...norming.data];

  if (datatype === "title") {
    const normdataTitles = [];
    for (let row of norming.data) {
      for (let title of row.titles) {
        normdataTitles.push({
          Original_Song_Title_SB: title,
          Consensus_Title_SB: row.consensus,
          Pool_Revenue_9LC: "",
        });
      }
    }
    normdata = normdataTitles;
  }

  if (datatype === "performer") {
    const normdataPerformers = [...normdata];
    normdataPerformers.push({
      Consensus_Title_SB: "",
      Song_Name_9LC: "Pool Revenue",
      Release_Artist_9LC: "",
      Composer_SB: "",
    });
    normdata = normdataPerformers;
    console.log("Normdata Performers", normdataPerformers);
  }

  await apFly("normFromData", {
    datatype,
    datadir,
    database,
    normdata: JSON.stringify(normdata),
  });
  dispatch(setUnsynced(datatype, false));
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const clearNorming = (datatype, cb) => async (dispatch) => {
  const norming = store.getState().norming[datatype];
  const fieldMapper = {
    pool: ["Pool_Revenue_9LC", "Song_Name_9LC"],
    performer: ["Song_Name_9LC", "Release_Artist_9LC"],
    details: [
      "Featured_Artist_9LC",
      "Release_Date_9LC",
      "Project_Type_9LC",
      "Album_Name_9LC",
    ],
    source: ["Normalized_Source_9LC"],
    income: ["Normalized_Income_Type_9LC", "Normalized_Income_Type_II_9LC"],
    country: ["Normalized_Country_9LC"],
  };

  let data = JSON.parse(JSON.stringify(norming.data));
  const predictions = [];

  switch (datatype) {
    case "title":
      const allTitles = [],
        newData = [];
      for (var row in data) {
        for (var title of data[row].titles) {
          allTitles.push(title);
        }
      }
      for (var title of allTitles) {
        newData.push({
          consensus: title,
          titles: [title],
        });
      }
      data = newData;
      break;
    case "filter":
      for (var ii in data) {
        data[ii]["Filtered_Title_SB"] = data[ii]["Original_Song_Title_SB"];
      }
      break;
    case "performer":
      dispatch({
        type: "SET_ARTISTS",
        artists: null,
      });
    default:
      for (var ii in data) {
        for (const col of fieldMapper[datatype]) {
          data[ii][col] = "";
        }
      }
  }

  for (var ii in data) {
    predictions[ii] = -1;
  }
  dispatch({
    type: "SET_NORMING",
    datatype,
    content: { data, predictions },
  });
  dispatch(setUnsynced(datatype, true));
  dispatch(saveNorming(datatype, data, predictions, []));

  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateTitles = (cb) => async (dispatch) => {
  const norming = store.getState().norming["title"];
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;

  const data = norming.data;
  const updates = [];
  data
    .filter((x) => x.titles.length > 1)
    .forEach((item) => {
      item.titles.forEach((title) => {
        updates.push({ from: title, to: item.consensus });
      });
    });

  console.log("Updates are", updates);
  await apFly("updateTitles", {
    datadir,
    database,
    updates: JSON.stringify(updates),
  });
  console.log("Done");
  if (cb) cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const assignConsensus = (index, title) => async (dispatch) => {
  const datatype = "title";
  const norming = store.getState().norming[datatype];
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;

  const data = [...norming.data];
  if (data[index].consensus !== title) {
    const item = {
      ...data[index],
      consensus: title,
    };
    data[index] = item;

    dispatch({
      type: "SET_NORMING",
      datatype,
      content: { data },
    });
    dispatch(saveNorming(datatype, data, [], []));
    dispatch(setUnsynced(datatype, true));

    /*
    const updates = item.titles.map((prevtitle) => ({
      from: prevtitle,
      to: title,
    }));

    console.log("Updates are", updates);
    await apFly("updateTitles", {
      datadir,
      database,
      updates: JSON.stringify(updates),
    });
    console.log("Done updating", updates);*/
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const deleteFromConsensus = (index, title) => async (dispatch) => {
  const datatype = "title";
  const norming = store.getState().norming[datatype];
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;

  const data = [...norming.data];
  const item = {
    ...data[index],
    titles: data[index].titles.filter((x) => x !== title),
  };
  data[index] = item;

  data.splice(index, 0, { consensus: title, titles: [title] });

  dispatch({
    type: "SET_NORMING",
    datatype,
    content: { data },
  });

  dispatch(saveNorming(datatype, data, [], []));
  dispatch(setUnsynced(datatype, true));

  /*
  const updates = [{ from: title, to: title }];
  console.log("Updating", updates);
  await apFly("updateTitles", {
    datadir,
    database,
    updates: JSON.stringify(updates),
  });
  console.log("Done updating", updates);*/
};
// -------------------------------------------------------
// -------------------------------------------------------
export const moveTitle = (indexFrom, indexTo, title) => async (dispatch) => {
  const datatype = "title";
  const norming = store.getState().norming[datatype];
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;

  const data = [...norming.data];

  const itemFrom = {
    ...data[indexFrom],
    titles: data[indexFrom].titles.filter((x) => x !== title),
  };
  data[indexFrom] = itemFrom;

  const itemTo = {
    ...data[indexTo],
    titles: [...data[indexTo].titles, title],
  };
  data[indexTo] = itemTo;

  if (!itemFrom.titles.length) {
    data.splice(indexFrom, 1);
  }

  dispatch({
    type: "SET_NORMING",
    datatype,
    content: { data },
  });

  dispatch(saveNorming(datatype, data, [], []));
  dispatch(setUnsynced(datatype, true));

  /*
  const updates = [{ from: title, to: itemTo.consensus }];
  await apFly("updateTitles", {
    datadir,
    database,
    updates: JSON.stringify(updates),
  });

  console.log("Done updating", updates);*/
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setConsensus = (index, consensus) => async (dispatch) => {
  const datatype = "title";
  const norming = store.getState().norming[datatype];
  const data = [...norming.data];
  data[index]["consensus"] = consensus;

  dispatch({
    type: "SET_NORMING",
    datatype,
    content: { data },
  });
  dispatch(saveNorming(datatype, data, [], []));
  dispatch(setUnsynced(datatype, true));
};
// -------------------------------------------------------
// -------------------------------------------------------
export const applyTextFilter = (value) => async (dispatch) => {
  const datatype = "filter";
  const norming = store.getState().norming[datatype];
  const data = [...norming.data];

  const pattern = new RegExp(value, "gi");
  for (const row of data) {
    row["Filtered_Title_SB"] = row["Original_Song_Title_SB"]
      .replace(pattern, "")
      .trim();
  }
  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data,
  });
  dispatch(saveNorming(datatype, data, [], []));
  dispatch(setUnsynced(datatype, true));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setPerformer = (index, type, value) => async (dispatch) => {
  const datatype = "performer";
  const norming = store.getState().norming[datatype];
  const data = [...norming.data];
  data[index][type] = value;

  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data,
  });
  dispatch(saveNorming(datatype, data, [], []));
  dispatch(setUnsynced(datatype, true));
};
// -------------------------------------------------------
// -------------------------------------------------------
export const setSongDetails = (index, type, value) => async (dispatch) => {
  console.log("Setting song details", index, type, value);
  const datatype = "details";
  const norming = store.getState().norming[datatype];
  const data = [...norming.data];
  data[index][type] = value;

  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data,
  });
  dispatch(saveNorming(datatype, data, [], []));
  dispatch(setUnsynced(datatype, true));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const saveNorming =
  (datatype, data, predictions, reviewed) => async (dispatch) => {
    const projects = store.getState().projects;
    const projectid = projects.project._id;

    const normingDataString = JSON.stringify({
      data,
      predictions,
      reviewed,
    });

    backend("norming", "save", { projectid, datatype, normingDataString });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const loadNorming = (datatype, cb) => async (dispatch) => {
  console.log("In Load Norming", datatype);
  const projects = store.getState().projects;
  const projectid = projects.project._id;

  dispatch({
    type: "CLEAR_NORMING",
    datatype,
  });

  const result = await backend("norming", "load", { projectid, datatype });
  if (result.data) {
    if (result.data.result) {
      const content = result.data.result.data;
      if (datatype !== "title") {
        for (
          var ii = content.predictions.length;
          ii < content.data.length;
          ii++
        ) {
          content.predictions[ii] = -1;
        }
        for (var ii = content.reviewed.length; ii < content.data.length; ii++) {
          content.reviewed[ii] = false;
        }
      }
      dispatch({
        type: "SET_NORMING",
        datatype,
        content,
      });
    }
  }
  console.log("Done Load Norming", datatype);
  if (cb) cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const clearNorming__ = (datatype) => async (dispatch) => {
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming[datatype];

  const data = JSON.parse(JSON.stringify(norming.data));
  for (var ii = 0; ii < norming.data.length; ii++) {
    data[ii]["Normalized_Source_9LC"] = "";
  }
  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data,
  });

  backend("norming", "update", {
    projectid,
    datatype: "pool",
    updateType: "data",
    dataString: JSON.stringify(data),
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const acceptAllSources = (threshold) => async (dispatch) => {
  const datatype = "source";
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming[datatype];

  const data = JSON.parse(JSON.stringify(norming.data));
  const predictions = JSON.parse(JSON.stringify(norming.predictions));

  for (var ii = 0; ii < norming.data.length; ii++) {
    if (predictions[ii][1] > threshold) {
      data[ii]["Normalized_Source_9LC"] = predictions[ii][0];
    }
  }
  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data,
  });

  backend("norming", "update", {
    projectid,
    datatype,
    updateType: "data",
    dataString: JSON.stringify(data),
  });
  dispatch(setUnsynced(datatype, true));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const acceptAllIncomeTypes = () => async (dispatch) => {
  const datatype = "income";
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming[datatype];

  const data = JSON.parse(JSON.stringify(norming.data));
  const predictions = JSON.parse(JSON.stringify(norming.predictions));

  for (var ii = 0; ii < norming.data.length; ii++) {
    data[ii]["Normalized_Income_Type_9LC"] = predictions[ii].prediction1;
    data[ii]["Normalized_Income_Type_II_9LC"] = predictions[ii].prediction2;
  }
  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data,
  });

  backend("norming", "update", {
    projectid,
    datatype,
    updateType: "data",
    dataString: JSON.stringify(data),
  });
  dispatch(setUnsynced(datatype, true));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const sortNorming = (datatype, key, sign) => async (dispatch) => {
  const sortFunc = (a, b) => {
    if (a === null) return sign;
    if (b === null) return -sign;
    return a[key] > b[key] ? sign : -sign;
  };

  const norming = store.getState().norming[datatype];

  const data = norming.data
    .map((item, index) => ({
      ...item,
      prediction: norming.predictions[index],
      reviewed: norming.reviewed[index],
    }))
    .sort(sortFunc);

  const predictions = data.map((item) => item.prediction);
  const reviewed = data.map((item) => item.reviewed);

  delete data["prediction"];
  delete data["reviewed"];

  dispatch({
    type: "SET_NORMING",
    datatype,
    content: {
      data,
      predictions,
      reviewed,
    },
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const flipSign = (datatype) => async (dispatch) => {
  const norming = store.getState().norming;
  const { sortsign, column } = norming;
  const sign = sortsign * -1;
  dispatch(sortNorming(datatype, column, sign));
  dispatch({
    type: "SET_NORMING_SORT_SIGN",
    sign,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setSortKey = (datatype, key) => async (dispatch) => {
  dispatch({
    type: "SET_NORMING_SORT_KEY",
    datatype,
    key,
  });

  dispatch(sortNorming(datatype, key, 1));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const predictPool = (data, rowindex) => async (dispatch) => {
  const datatype = "pool";
  dispatch({
    type: "SET_PREDICTION",
    datatype: "pool",
    index: rowindex,
    value: null,
  });

  const title = data[rowindex]["Consensus_Title_SB"];
  const result = await apFly("predictPool", { title });
  let prediction;
  if (result.data) {
    prediction = result.data.prediction === "NONE" ? "" : "X";
  }

  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const { predictions } = norming["pool"];
  predictions[rowindex] = prediction;

  backend("norming", "update", {
    projectid,
    datatype,
    updateType: "predictions",
    dataString: JSON.stringify(predictions),
  });

  dispatch({
    type: "SET_PREDICTION",
    datatype,
    index: rowindex,
    value: prediction,
  });
  dispatch(setUnsynced(datatype, true));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const predictSource = (data, rowindex) => async (dispatch) => {
  dispatch({
    type: "SET_PREDICTION",
    datatype: "source",
    index: rowindex,
    value: null,
  });

  const title = data[rowindex]["Source_SB"];
  const result = await apFly("predictSource", { title });
  let prediction;
  if (result.data) {
    prediction = result.data.prediction;
  }

  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const { predictions } = norming["source"];
  predictions[rowindex] = prediction;

  if (!data[rowindex]["Normalized_Source_9LC"]) {
    dispatch(setSource(rowindex, prediction));
  }

  backend("norming", "update", {
    projectid,
    datatype: "source",
    updateType: "predictions",
    dataString: JSON.stringify(predictions),
  });

  dispatch({
    type: "SET_PREDICTION",
    datatype: "source",
    index: rowindex,
    value: prediction,
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const batchSources = (cb) => async (dispatch) => {
  const norming = store.getState().norming;
  const projects = store.getState().projects;
  const projectid = projects.project._id;

  const { data } = norming["source"];
  const titles = data.map((item) => item["Source_SB"]);
  const result = await apFly("predictSources", {
    titles: JSON.stringify(titles),
  });

  if (result.data) {
    const predictions = result.data;
    dispatch({
      type: "SET_PREDICTIONS",
      datatype: "source",
      predictions,
    });
    backend("norming", "update", {
      projectid,
      datatype: "source",
      updateType: "predictions",
      dataString: JSON.stringify(predictions),
    });
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const batchPool = (cb) => async (dispatch) => {
  const datatype = "pool";
  const norming = store.getState().norming;
  const projects = store.getState().projects;
  const projectid = projects.project._id;

  const { data } = norming[datatype];
  const titles = data.map((item) =>
    item["Original_Song_Title_SB"].toUpperCase()
  );

  const result = await apFly("predictPools", {
    titles: JSON.stringify(titles),
  });

  if (result.data) {
    const predictions = result.data.map((x, index) => {
      const isSong = x[0] === "NONE";
      const str = isSong ? "" : "X";
      data[index]["Pool_Revenue_9LC"] = isSong ? "" : "X";
      data[index]["Song_Name_9LC"] = isSong ? "" : "Pool Revenue";
      return [str, x[1]];
    });

    const reviewed = predictions.map((x) => false);
    dispatch({
      type: "SET_NORMING",
      datatype,
      content: {
        data,
        predictions,
        reviewed,
      },
    });
    dispatch(saveNorming(datatype, data, predictions, reviewed));
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const predictIncome = (data, rowindex, client) => async (dispatch) => {
  dispatch({
    type: "SET_PREDICTION",
    datatype: "income",
    index: rowindex,
    value: null,
  });

  const income = data[rowindex]["Income_Type_SB"];
  const payor = data[rowindex]["Third_Party_9LC"];

  const result = await apFly("predictIncome", { income, payor, client });
  let prediction;
  if (result.data) {
    prediction = result.data.prediction;
  }

  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const { predictions } = norming["income"];
  predictions[rowindex] = prediction;

  backend("norming", "update", {
    projectid,
    datatype: "income",
    updateType: "predictions",
    dataString: JSON.stringify(predictions),
  });

  dispatch({
    type: "SET_PREDICTION",
    datatype: "income",
    index: rowindex,
    value: prediction,
  });
};
// -------------------------------------------------------
// -------------------------------------------------------
export const runPredictions = (data, datatype, cb) => async (dispatch) => {
  switch (datatype) {
    case "source":
      for (var ii = 0; ii < data.length; ii++) {
        await dispatch(predictSource(data, ii));
      }
      break;
    case "pool":
      for (var ii = 0; ii < data.length; ii++) {
        await dispatch(predictPool(data, ii));
      }
      break;
    case "income":
      for (var ii = 0; ii < data.length; ii++) {
        await dispatch(predictIncome(data, ii));
      }
      break;
  }
  if (cb) cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const clearAll = (datatype, cb) => async (dispatch) => {
  dispatch({
    type: "CLEAR_NORMING_POOL",
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const copyPredictions = (datatype, cb) => async (dispatch) => {
  dispatch({
    type: "COPY_PREDICTIONS_POOL",
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setPredictionApproval =
  (datatype, index, approval) => async (dispatch) => {
    const projects = store.getState().projects;
    const projectid = projects.project._id;
    const norming = store.getState().norming;
    const { reviewed } = norming[datatype];
    reviewed[index] = approval;

    backend("norming", "update", {
      projectid,
      datatype,
      updateType: "reviewed",
      dataString: JSON.stringify(reviewed),
    });

    dispatch({
      type: "SET_PREDICTION_APPROVAL",
      datatype,
      index,
      approval,
    });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const setPoolRevenue = (index, pr) => async (dispatch) => {
  const datatype = "pool";
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const { data } = norming["pool"];

  data[index]["Pool_Revenue_9LC"] = pr;

  if (pr === "X") {
    data[index]["Song_Name_9LC"] = "Pool Revenue";
  } else {
    data[index]["Song_Name_9LC"] = data[index]["Original_Song_Title_SB"];
  }

  backend("norming", "update", {
    projectid,
    datatype,
    updateType: "data",
    dataString: JSON.stringify(data),
  });

  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data: JSON.parse(JSON.stringify(data)),
  });
  dispatch(setUnsynced(datatype, true));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setPoolRevenueIndividual =
  (rowindex, titles) => async (dispatch) => {
    const projects = store.getState().projects;
    const projectid = projects.project._id;
    const norming = store.getState().norming;
    const data = norming["pool"].data;
    const titleData = norming["title"].data;

    for (var title of titles) {
      const index = data.findIndex(
        (item) => item.Original_Song_Title_SB === title
      );
      console.log(title, index);
      console.log(data[index]);
      console.log("----");
      if (index !== -1) {
        data[index]["Pool_Revenue_9LC"] = "X";
        data[index]["Song_Name_9LC"] = "Pool Revenue";
        await dispatch(
          updateNorming(
            "pool",
            JSON.parse(JSON.stringify(data[index])),
            ["Pool_Revenue_9LC", "Song_Name_9LC"],
            () => {
              console.log("pool updated");
            }
          )
        );
      }
    }

    backend("norming", "update", {
      projectid,
      datatype: "pool",
      updateType: "data",
      dataString: JSON.stringify(data),
    });
    dispatch({
      type: "SET_NORMING_DATA",
      datatype: "pool",
      data: JSON.parse(JSON.stringify(data)),
    });

    titleData.splice(rowindex, 1);
    backend("norming", "update", {
      projectid,
      datatype: "title",
      updateType: "data",
      dataString: JSON.stringify(titleData),
    });
    dispatch({
      type: "SET_NORMING_DATA",
      datatype: "title",
      data: JSON.parse(JSON.stringify(titleData)),
    });
  };
// -------------------------------------------------------
// -------------------------------------------------------
export const updateIncomeType = async (client) => {
  const norming = store.getState().norming;
  const { data } = norming["income"];

  await backend("norming", "updateIncomeType", {
    client,
    dataString: JSON.stringify(data),
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setSource = (index, sourceTerm) => async (dispatch) => {
  const datatype = "source";
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const { data } = norming[datatype];

  data[index]["Normalized_Source_9LC"] = sourceTerm;

  backend("norming", "update", {
    projectid,
    datatype,
    updateType: "data",
    dataString: JSON.stringify(data),
  });

  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data: JSON.parse(JSON.stringify(data)),
  });
  dispatch(setUnsynced(datatype, true));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setCountry = (index, country) => async (dispatch) => {
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const datatype = "country";

  const { data } = norming[datatype];

  data[index]["Normalized_Country_9LC"] = country;

  if (country) {
    backend("norming", "addCountryMapping", {
      from: data[index]["Country_SB"],
      to: country,
    });
  }

  backend("norming", "update", {
    projectid,
    datatype,
    updateType: "data",
    dataString: JSON.stringify(data),
  });

  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data: JSON.parse(JSON.stringify(data)),
  });

  dispatch(setUnsynced(datatype, true));
};

// -------------------------------------------------------
// -------------------------------------------------------
export const buildCountriesFromDB = () => async (dispatch) => {
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const datatype = "country";
  const { data } = norming[datatype];
  const countriesList = data
    .filter((x) => !x["Normalized_Country_9LC"])
    .map((x) => x["Country_SB"]);
  console.log("Countries", countriesList);
  const countries = JSON.stringify(countriesList);
  const result = await backend("norming", "populateFromCountryMapping", {
    countries,
  });
  if (result.data) {
    const mapping = result.data;
    for (var index in data) {
      const country = data[index]["Country_SB"].toUpperCase();
      if (mapping[country]) {
        data[index]["Normalized_Country_9LC"] = mapping[country];
      }
    }
    backend("norming", "update", {
      projectid,
      datatype,
      updateType: "data",
      dataString: JSON.stringify(data),
    });

    dispatch({
      type: "SET_NORMING_DATA",
      datatype,
      data: JSON.parse(JSON.stringify(data)),
    });
    dispatch(setUnsynced(datatype, true));
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const setIncome = (index, term, which) => async (dispatch) => {
  const datatype = "income";
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const { data } = norming[datatype];

  const where =
    which === "type1"
      ? "Normalized_Income_Type_9LC"
      : "Normalized_Income_Type_II_9LC";

  data[index][where] = term;

  backend("norming", "update", {
    projectid,
    datatype,
    updateType: "data",
    dataString: JSON.stringify(data),
  });

  dispatch({
    type: "SET_NORMING_DATA",
    datatype,
    data: JSON.parse(JSON.stringify(data)),
  });
  dispatch(setUnsynced(datatype, true));
};
// -------------------------------------------------------
// -------------------------------------------------------
export const setIncomeDual = (index, prediction) => async (dispatch) => {
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const { data } = norming["income"];

  data[index]["Normalized_Income_Type_9LC"] = prediction.prediction1;
  data[index]["Normalized_Income_Type_II_9LC"] = prediction.prediction2;

  dispatch({
    type: "SET_NORMING_DATA",
    datatype: "income",
    data: JSON.parse(JSON.stringify(data)),
  });

  backend("norming", "update", {
    projectid,
    datatype: "income",
    updateType: "data",
    dataString: JSON.stringify(data),
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateNorming =
  (datatype, row, updatecols, cb) => async (dispatch) => {
    const projects = store.getState().projects;
    const {
      project: { datadir, database },
    } = projects;

    console.log("Update norming", row, updatecols);
    await apFly("updateNorming", {
      datadir,
      datatype,
      database,
      updatecols: JSON.stringify(updatecols),
      row: JSON.stringify(row),
    });
    console.log("Update norming done");

    cb();
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const queryDiscogs = (index) => async (dispatch) => {
  const projects = store.getState().projects;
  const norming = store.getState().norming;

  const { data } = norming["performer"];
  const title = data[index]["Consensus_Title_SB"];
  const person = projects.project.keys[0].pivot;
  const results = await backend("songnorm", "discogs", {
    title,
    person,
  });

  data[index]["Song_Name_9LC"] = "";
  data[index]["Release_Artist_9LC"] = "";

  if (results.data.result) {
    console.log(results.data.result);
    data[index]["Song_Name_9LC"] = results.data.result.song;
    data[index]["Release_Artist_9LC"] = results.data.result.artist;
  }

  dispatch({
    type: "SET_NORMING_DATA",
    datatype: "income",
    data: JSON.parse(JSON.stringify(data)),
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
let canRun = false;
export const cancelDiscogs = () => {
  canRun = false;
};

export const batchDiscogs__ = () => async (dispatch) => {
  canRun = true;
  const datatype = "performer";
  const projects = store.getState().projects;
  const projectid = projects.project._id;
  const norming = store.getState().norming;
  const {
    project: { datadir, database },
  } = projects;

  const { data } = norming["performer"];
  const discogsid = projects.project.keys[0].discogsid;
  if (!discogsid) {
    alert("No discogsid for project");
    return;
  }
  for (var index = 0; index < data.length; index++) {
    if (canRun) {
      const songTitle = data[index]["Consensus_Title_SB"];

      data[index]["Song_Name_9LC"] = null;
      data[index]["Release_Artist_9LC"] = null;
      dispatch({
        type: "SET_NORMING_DATA",
        datatype: "performer",
        data: JSON.parse(JSON.stringify(data)),
      });

      const results = await backend("brainz", "searchDiscogsReleases", {
        songTitle,
        discogsArtistId: discogsid,
      });
      if (results.data.results) {
        console.log(results.data.results);
      }

      /*const results = await backend("songnorm", "discogs", {
        title,
        person,
      });

      
      data[index]["Song_Name_9LC"] = "";
      data[index]["Release_Artist_9LC"] = "";

      if (results.data.result) {
        console.log(results.data.result);
        data[index]["Song_Name_9LC"] = results.data.result.song;
        data[index]["Release_Artist_9LC"] = results.data.result.artist;
      }

      dispatch({
        type: "SET_NORMING_DATA",
        datatype,
        data: JSON.parse(JSON.stringify(data)),
      });
      backend("norming", "update", {
        projectid,
        datatype,
        updateType: "data",
        dataString: JSON.stringify(data),
      });
      */
    }

    /*
    apFly("updateNorming", {
      datadir,
      datatype: "performer",
      database,
      updatecols: JSON.stringify(["Song_Name_9LC", "Release_Artist_9LC"]),
      row: JSON.stringify(data[index]),
    });*/
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const batchDiscogs = (cb) => async (dispatch) => {
  const datatype = "performer";
  const { data } = store.getState().norming[datatype];
  const { project } = store.getState().projects;

  const titles = data.map((x) => x["Consensus_Title_SB"]);
  const discogsIds = project.keys.map((x) => x.discogsid);

  dispatch({
    type: "SET_ARTISTS",
    artists: null,
  });
  dispatch({
    type: "SET_PREDICTIONS",
    datatype,
    predictions: [],
  });

  const predictionsArray = [];
  for (var discogsId of discogsIds) {
    const res = await backend("songnorm", "query", {
      discogsId,
      titles: JSON.stringify(titles),
    });
    if (res.data.results) {
      console.log("Predictions Are", res.data.results);
      predictionsArray.push(res.data.results);
    }
  }
  const predictions = predictionsArray[0];
  for (var ii = 1; ii < predictionsArray.length; ii++) {
    const newPredictions = predictionsArray[ii];
    for (var jj = 0; jj < newPredictions.length; jj++) {
      const stemComboArray = predictions[jj].map(
        (x) => stem(x.track) + stem(x.artist)
      );
      for (const newPred of newPredictions[jj]) {
        const stemCombo = stem(newPred.track) + stem(newPred.artist);
        if (!stemComboArray.includes(stemCombo)) {
          predictions[jj].push(newPred);
        }
      }
    }
  }
  dispatch(processPredictions(predictions));
  cb();
};

const processPredictions = (predictions) => async (dispatch) => {
  const datatype = "performer";
  const { data } = JSON.parse(
    JSON.stringify(store.getState().norming[datatype])
  );
  const projects = store.getState().projects;
  const projectid = projects.project._id;

  const inc = (struct, entry) => {
    if (struct[entry] === undefined) {
      struct[entry] = 1;
      return;
    }
    struct[entry] += 1;
  };

  const artistRegistry = {};
  const artistStem2Artist = {};

  predictions = predictions.map((x) => (x === -1 ? [] : x));
  predictions.forEach((x) => {
    x.forEach((y) => {
      inc(artistRegistry, stem(y.artist));
      artistStem2Artist[stem(y.artist)] = y.artist;
    });
  });

  predictions = predictions.map((x) => {
    return x.map((y) => {
      return {
        ...y,
        artistCount: artistRegistry[stem(y.artist)],
      };
    });
  });
  predictions = predictions.map((x) => {
    return x.sort((x, y) => y.artistCount - x.artistCount);
  });

  let artists = [];
  Object.keys(artistRegistry).forEach((key) => {
    artists.push({
      artist: artistStem2Artist[key],
      count: artistRegistry[key],
      isLoading: false,
    });
  });
  artists = artists.sort((x, y) => y.count - x.count);
  console.log(artists);

  for (var rowindex in data) {
    if (predictions[rowindex]) {
      if (predictions[rowindex].length) {
        data[rowindex]["Song_Name_9LC"] = predictions[rowindex][0].track;
        data[rowindex]["Release_Artist_9LC"] = predictions[rowindex][0].artist;
      }
    }
  }

  dispatch({
    type: "SET_ARTISTS",
    artists,
  });

  dispatch({
    type: "SET_NORMING",
    datatype,
    content: {
      data: JSON.parse(JSON.stringify(data)),
      predictions,
      reviewed: [],
    },
  });

  dispatch(
    saveNorming(datatype, JSON.parse(JSON.stringify(data)), predictions, [])
  );
  dispatch(setUnsynced(datatype, true));

  /*backend("norming", "update", {
    projectid,
    datatype,
    updateType: "predictions",
    dataString: JSON.stringify(predictions),
  });*/
};

export const getGeniusId = async (uri) => {
  const res = await backend("songnorm", "getGeniusArtistId", {
    uri,
  });
  return res;
};
// -------------------------------------------------------
// -------------------------------------------------------
export const geniusByCredits = (cb) => async (dispatch) => {
  const datatype = "performer";
  const { project } = store.getState().projects;
  if (project.artists && project.artists.length) {
    const credits = project.artists.map((x) => x.id);
    const norming = store.getState().norming[datatype];
    const data = norming.data;
    const titles = data.map((x) => x["Consensus_Title_SB"]);
    const predictions = JSON.parse(JSON.stringify(norming.predictions));

    dispatch({
      type: "INIT_MAIN_PROGRESS",
      title: "Scanning Genius.com",
      total: data.length,
    });
    for (var rowindex in data) {
      dispatch({
        type: "UPDATE_MAIN_PROGRESS",
        current: rowindex,
        status: `${titles[rowindex]} (${rowindex} / ${data.length})`,
      });
      //const rowindex = 79;
      const prediction =
        predictions[rowindex] !== -1 ? predictions[rowindex] : [];
      if (
        data[rowindex]["Release_Artist_9LC"] === "" ||
        data[rowindex]["Release_Artist_9LC"] === null
      ) {
        console.log(rowindex, titles[rowindex]);
        const res = await backend("songnorm", "singleGeniusByCredits", {
          prediction: JSON.stringify(prediction),
          title: titles[rowindex],
          credits: JSON.stringify(credits),
        });
        if (res.data.prediction) {
          if (res.data.prediction.length) {
            console.log("\tPrediction Found");
            predictions[rowindex] = res.data.prediction;
          }
        }
      }
    }
    await dispatch(processPredictions(predictions));
    dispatch({
      type: "CLOSE_MAIN_PROGRESS",
    });
    cb();
    return;
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const spotifyByArtists = (cb) => async (dispatch) => {
  const datatype = "performer";
  const { artists } = store.getState().norming;
  if (artists && artists.length) {
    const allArtists = JSON.stringify(artists.map((x) => x.artist));
    const norming = store.getState().norming[datatype];
    const data = norming.data;
    const titles = data.map((x) => x["Consensus_Title_SB"]);
    const predictions = JSON.parse(JSON.stringify(norming.predictions));
    dispatch({
      type: "INIT_MAIN_PROGRESS",
      title: "Scanning Spotify",
      total: data.length,
    });
    for (const rowindex in data) {
      dispatch({
        type: "UPDATE_MAIN_PROGRESS",
        current: rowindex,
        status: `${titles[rowindex]} (${rowindex} / ${data.length})`,
      });

      //const rowindex = 78;
      const prediction =
        predictions[rowindex] !== -1 ? predictions[rowindex] : [];
      if (
        data[rowindex]["Release_Artist_9LC"] === "" ||
        data[rowindex]["Release_Artist_9LC"] === null
      ) {
        console.log(rowindex, titles[rowindex]);
        const res = await backend("songnorm", "singleSpotifyByArtists", {
          prediction: JSON.stringify(prediction),
          title: titles[rowindex],
          allArtists,
        });
        if (res.data.prediction) {
          if (res.data.prediction.length) {
            console.log("\tPrediction Found");
            predictions[rowindex] = res.data.prediction;
          }
        }
      }
    }
    await dispatch(processPredictions(predictions));
    dispatch({
      type: "CLOSE_MAIN_PROGRESS",
    });

    cb();
    return;
  }
  cb();
};

export const complementDiscogs__ = (artist, cb) => async (dispatch) => {
  const datatype = "performer";
  const { data, predictions } = store.getState().norming[datatype];
  const titles = data
    .filter((x) => x["Song_Name_9LC"] === "")
    .map((x) => x["Consensus_Title_SB"]);
  const allTitles = data.map((x) => x["Consensus_Title_SB"]);

  const res = await backend("songnorm", "queryByArtist", {
    artist,
    predictionsString: JSON.stringify(predictions),
    titles: JSON.stringify(titles),
    allTitles: JSON.stringify(allTitles),
  });
  if (res.data) {
    dispatch(processPredictions(res.data.predictions));
  }
  cb();
};

export const complementDiscogs = (artist, cb) => async (dispatch) => {
  const datatype = "performer";
  const { data, predictions } = store.getState().norming[datatype];
  const titles = data.map((x) => x["Consensus_Title_SB"]);

  const res = await backend("songnorm", "spotifyByArtist", {
    artist,
    titles: JSON.stringify(titles),
    predictions: JSON.stringify(predictions),
  });
  if (res.data) {
    dispatch(processPredictions(res.data.predictions));
    console.log(res.data);
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const batchSpotify = (cb) => async (dispatch) => {
  const datatype = "details";
  const norming = store.getState().norming;
  const { data } = norming[datatype];

  const predictions = [];
  for (var index in data) {
    if (3) {
      const title = data[index]["Song_Name_9LC"];
      const artist = data[index]["Release_Artist_9LC"];
      predictions[index] = {};
      if (title && artist) {
        data[index]["Featured_Artist_9LC"] = null;
        data[index]["Release_Date_9LC"] = null;
        data[index]["Project_Type_9LC"] = null;
        data[index]["Album_Name_9LC"] = null;

        dispatch({
          type: "SET_NORMING_DATA",
          datatype: "details",
          data: JSON.parse(JSON.stringify(data)),
        });

        const results = await backend("songnorm", "spotify", {
          title,
          artist,
        });

        data[index]["Featured_Artist_9LC"] = null;
        data[index]["Release_Date_9LC"] = null;
        data[index]["Project_Type_9LC"] = null;
        data[index]["Album_Name_9LC"] = null;

        if (results.data.result) {
          console.log(title, artist);
          console.log(results.data.result);
          data[index]["Featured_Artist_9LC"] = results.data.result.featured;
          data[index]["Release_Date_9LC"] = results.data.result.releaseDate;
          data[index]["Project_Type_9LC"] =
            results.data.result.albumType.toUpperCase();
          data[index]["Album_Name_9LC"] = results.data.result.album;
          predictions[index]["Performer"] = results.data.result.artist;
          predictions[index]["Song"] = results.data.result.song.toUpperCase();
        }

        dispatch({
          type: "SET_NORMING_DATA",
          datatype: "details",
          data: JSON.parse(JSON.stringify(data)),
        });
        dispatch({
          type: "SET_PREDICTIONS",
          datatype: "details",
          predictions: JSON.parse(JSON.stringify(predictions)),
        });
      }
    }
  }
  dispatch(saveNorming(datatype, data, predictions, []));
  dispatch(setUnsynced(datatype, true));
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const songDetails = (cb) => async (dispatch) => {
  const datatype = "details";
  const norming = store.getState().norming;
  const { data } = norming[datatype];

  const predictions = [];

  dispatch({
    type: "INIT_MAIN_PROGRESS",
    title: "Scanning APIs",
    total: data.length,
  });

  for (var index in data) {
    const song = data[index]["Song_Name_9LC"];
    const artist = data[index]["Release_Artist_9LC"];
    dispatch({
      type: "UPDATE_MAIN_PROGRESS",
      current: index,
      status: `${artist} : ${song} (${index} / ${data.length})`,
    });

    predictions[index] = {};
    if (song && artist) {
      dispatch({
        type: "SET_NORMING_DATA",
        datatype: "details",
        data: JSON.parse(JSON.stringify(data)),
      });
      const results = await backend("songdetails", "get", {
        song,
        artist,
      });
      if (results.data) {
        predictions[index] = results.data;
        if (results.data.resSP) {
          data[index]["Spotify_URL_9LC"] = results.data.resSP.spotifyURL;
        }
        const pick = results.data.resAM;
        if (pick) {
          console.log(index, pick);
          data[index]["Featured_Artist_9LC"] = pick.featured;
          data[index]["Release_Date_9LC"] = pick.releaseDate;
          data[index]["Project_Type_9LC"] = pick.albumType.toUpperCase();
          data[index]["Album_Name_9LC"] = pick.album;
          console.log(data[index]);
          dispatch({
            type: "SET_NORMING_DATA",
            datatype: "details",
            data: JSON.parse(JSON.stringify(data)),
          });
        }
      }

      dispatch({
        type: "SET_PREDICTIONS",
        datatype: "details",
        predictions: JSON.parse(JSON.stringify(predictions)),
      });
    }
  }
  dispatch(saveNorming(datatype, data, predictions, []));
  dispatch(setUnsynced(datatype, true));
  dispatch({
    type: "CLOSE_MAIN_PROGRESS",
  });
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getSpotifyArtist = (spotifyId) => async (dispatch) => {
  const res = await backend("songnorm", "getSpotifyArtist", {
    spotifyId,
  });
  return res;
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getGeniusArtist = (geniusId) => async (dispatch) => {
  const res = await backend("songnorm", "getGeniusArtistById", {
    geniusId,
  });
  return res;
};
