import { useEffect } from "react";


const Index = () => {

  useEffect(() => {
    window.VANTA.BIRDS({
      el: "#birds",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
    });

  }, [])
  return(
    <div className = "box" id="birds" style = {{height: 800}} />
  )
}

export default Index;