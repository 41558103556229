import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import {
  assignConsensus,
  deleteFromConsensus,
  moveTitle,
  loadNorming,
  groupTitles,
  groupTitlesAI,
  setConsensus,
  setPoolRevenueIndividual,
} from "Redux/norming/actions";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
import ProgressBar from "Components/ProgressBar";
// -------------------------------------------------------------
import "./css.css";
import waitbar from "Media/waitbar.gif";
const datatype = "title";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [loadingNorming, setLoadingNorming] = useState(false);
  const [threshold, setThreshold] = useState(0.2);
  const [songs, setSongs] = useState([]);

  const norming = useSelector((state) => state.norming);

  const { data, unsynced } = norming[datatype];

  useEffect(() => {
    dispatch(loadNorming(datatype));
  }, []);

  if (loadingNorming) return <img src={waitbar} />;

  return (
    <div>
      <ProgressBar />
      {songs.length && 9 ? (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="box">{songs.map((entry, i) => entry + ", ")}</div>
          </div>
          <button class="modal-close is-large" aria-label="close"></button>
        </div>
      ) : null}
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <button
            className="button is-small"
            onClick={() => {
              dispatch(groupTitles(threshold));
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-object-group" />;
            </span>
            <span>Group Titles</span>
          </button>
        </td>
        <td>&nbsp;</td>
        <td>
          <Threshold threshold={threshold} setThreshold={setThreshold} />
        </td>
        <td>&nbsp;&nbsp;</td>
        {9 ? (
          <td>
            <button
              className="button is-small"
              onClick={() => {
                dispatch(groupTitlesAI());
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-sparkles" />
              </span>
              <span>AI Group</span>
            </button>
          </td>
        ) : null}
      </NormingButtons>
      <div className="columns">
        <div className="column">
          <MainTable norming={norming} datatype={datatype} />
        </div>
        <div className="column">
          <MainTable norming={norming} />
        </div>
      </div>
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ norming }) => {
  const dispatch = useDispatch();
  const { data } = norming[datatype];
  const [editable, setEditable] = useState(null);
  const [draggingOver, setDraggingOver] = useState(null);

  if (!data) return null;
  if (!data.length) return null;

  return (
    <div className="table-wrapper">
      <table
        className="table is-striped_ is-bordered"
        onClick={() => {
          setEditable(null);
        }}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Consensus Title</th>
            <th>Variants</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => (
            <tr
              key={`row-${rowindex}`}
              onDragOver={(evt) => {
                evt.preventDefault();
              }}
              onDrop={(evt) => {
                const mode = evt.dataTransfer.getData("mode");
                console.log("Mode is", mode);
                switch (mode) {
                  case "title":
                    const dataTitle = JSON.parse(
                      evt.dataTransfer.getData("title")
                    );
                    if (dataTitle.rowindex !== rowindex) {
                      dispatch(
                        moveTitle(dataTitle.rowindex, rowindex, dataTitle.title)
                      );
                    }
                    break;
                  case "titles":
                    const dataTitles = JSON.parse(
                      evt.dataTransfer.getData("titles")
                    );
                    if (dataTitles.rowindex !== rowindex) {
                      for (const title of dataTitles.titles) {
                        dispatch(
                          moveTitle(dataTitles.rowindex, rowindex, title)
                        );
                      }
                    }
                    break;
                  default:
                }
              }}
              className={draggingOver === rowindex ? "dragging-over" : ""}
            >
              <td>{rowindex + 1}</td>
              <td>
                <Consensus
                  currentValue={row["consensus"]}
                  rowindex={rowindex}
                  onSubmit={(value) => {
                    dispatch(setConsensus(rowindex, value));
                  }}
                  editable={editable}
                  setEditable={setEditable}
                />
              </td>
              <td
                className="is-draggable"
                draggable
                onDragStart={(evt) => {
                  evt.dataTransfer.setData(
                    "titles",
                    JSON.stringify({ titles: row.titles, rowindex })
                  );
                  evt.dataTransfer.setData("mode", "titles");
                }}
              >
                <div className="tags">
                  {row.titles
                    ? row.titles.map((title, titleindex) => (
                        <div
                          className="tag is-draggable"
                          key={`title-${titleindex}`}
                          onClick={() => {
                            dispatch(assignConsensus(rowindex, title));
                          }}
                          draggable
                          onDragStart={(evt) => {
                            evt.stopPropagation();
                            setEditable(null);
                            evt.dataTransfer.setData(
                              "title",
                              JSON.stringify({ title, rowindex })
                            );
                            evt.dataTransfer.setData("mode", "title");
                          }}
                        >
                          {title}
                          <button
                            className="delete is-small"
                            onClick={() => {
                              dispatch(deleteFromConsensus(rowindex, title));
                            }}
                          />
                        </div>
                      ))
                    : null}
                </div>
              </td>
              <td>
                <div
                  className="button is-small"
                  onClick={() => {
                    dispatch(setPoolRevenueIndividual(rowindex, row.titles));
                  }}
                >
                  PR
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Consensus = ({
  currentValue,
  rowindex,
  onSubmit,
  editable,
  setEditable,
}) => {
  const [value, setValue] = useState(currentValue);

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const submit = () => {
    setEditable(null);
    onSubmit(value);
  };

  if (editable === rowindex)
    return (
      <input
        value={value}
        onChange={(evt) => {
          setValue(evt.target.value);
        }}
        className="input"
        type="text"
        onBlur={submit}
        onKeyDown={(evt) => evt.key === "Enter" && submit()}
        onClick={(evt) => evt.stopPropagation()}
      />
    );
  return (
    <div
      className="tags has-addons"
      onClick={(evt) => {
        evt.stopPropagation();
        setEditable(rowindex);
      }}
    >
      <div className="tag is-clickable is-info is-light">{value}</div>
      <div
        className="tag is-warning is-light is-clickable"
        onClick={() => {
          const uri = "https://www.google.com/search?q=";
          window.open(`${uri}${value}`, "_blank");
        }}
      >
        <span className="icon is-small">
          <i className="fas fa-external-link-alt" />
        </span>
      </div>
    </div>
  );
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Threshold = ({ threshold, setThreshold }) => {
  const [value, setValue] = useState(threshold);
  const onSubmit = (evt) => {
    if (value > 0 && value <= 1) {
      setThreshold(value);
      return;
    }
    setValue(threshold);
  };

  return (
    <input
      className="input is-small"
      type="text"
      placeholder="Set Distance Threshold"
      value={value}
      onChange={(evt) => {
        setValue(evt.target.value);
      }}
      onKeyPress={(event) => {
        if (!/[0-9\.]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      onBlur={onSubmit}
      onKeyDown={(evt) => {
        if (evt.key === "Enter") onSubmit(evt);
      }}
    />
  );
};
