import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
// -------------------------------------------------------------
import { atoz, zerotonine } from "Aux/";
// -------------------------------------------------------------
import { setPoolRevenue, loadNorming, batchPool } from "Redux/norming/actions";
// -------------------------------------------------------------
import waitbar from "Media/waitbar.gif";
import "./css.css";

const aZ = atoz();
const zeroToNine = zerotonine();
const aZAll = [...aZ, ...zeroToNine];

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const datatype = "pool";
  const dispatch = useDispatch();
  const [loadingNorming, setLoadingNorming] = useState(false);
  const [predicting, setPredicting] = useState(false);
  const [threshold, setThreshold] = useState(0.9);

  const norming = useSelector((state) => state.norming);
  const { data, unsynced, predictions } = norming[datatype];

  useEffect(() => {
    console.log("azAll", aZAll);
    setLoadingNorming(true);
    dispatch(
      loadNorming("pool", () => {
        setLoadingNorming(false);
      })
    );
  }, []);

  if (loadingNorming) return <img src={waitbar} />;

  data.forEach((d, i) => {
    data[i].index = i;
  });
  const songs = data
    .filter((x) => x["Pool_Revenue_9LC"] !== "X")
    .sort((x, y) =>
      x["Original_Song_Title_SB"] > y["Original_Song_Title_SB"] ? 1 : -1
    );
  const prs = data
    .filter((x) => x["Pool_Revenue_9LC"] === "X")
    .sort((x, y) =>
      x["Original_Song_Title_SB"] > y["Original_Song_Title_SB"] ? 1 : -1
    );
  console.log("number pool", prs.length);
  return (
    <div>
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <div
            className={`button is-small ${predicting ? "is-loading" : ""}`}
            onClick={() => {
              setPredicting(true);
              dispatch(
                batchPool(() => {
                  setPredicting(false);
                })
              );
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-network-wired" />
            </span>
            <span>Classify</span>
          </div>
        </td>
        <td>
          <Threshold threshold={threshold} setThreshold={setThreshold} />
        </td>
      </NormingButtons>

      <div className="table-wrapper-shorter">
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Songs</th>
                <th>Pool Revenue</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <table>
                    <tbody>
                      {aZAll.map((letter, index) => (
                        <SongCluster
                          key={`atoz-song-${letter}`}
                          songs={songs}
                          letter={letter}
                          predictions={predictions}
                          threshold={threshold}
                          isPR={false}
                        />
                      ))}
                      <NonAlphanumericCluster
                        songs={songs}
                        predictions={predictions}
                        threshold={threshold}
                        isPR={false}
                      />
                    </tbody>
                  </table>
                </td>
                <td>
                  <table>
                    <tbody>
                      {aZAll.map((letter, index) => (
                        <SongCluster
                          key={`atoz-pr-${letter}`}
                          songs={prs}
                          letter={letter}
                          predictions={predictions}
                          threshold={threshold}
                          isPR={true}
                        />
                      ))}
                      <NonAlphanumericCluster
                        songs={prs}
                        predictions={predictions}
                        threshold={threshold}
                        isPR={true}
                      />
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
const SongCluster = ({ songs, letter, predictions, threshold, isPR }) => {
  const nSongs = songs.filter((song) => {
    const tag = song["Original_Song_Title_SB"];
    if (!tag) return false;
    const isInLetter = tag.toUpperCase().charAt(0) === letter;
    return isInLetter;
  }).length;
  if (!nSongs) return null;
  return (
    <tr>
      <td>
        <b>{letter}</b>
        <div className="field is-grouped is-grouped-multiline">
          {songs.map((song, songindex) => {
            const tag = song["Original_Song_Title_SB"];
            if (!tag) return null;
            const isInLetter = tag.toUpperCase().charAt(0) === letter;
            if (!isInLetter) return null;
            return (
              <SongTag
                rowindex={song.index}
                key={`song-${letter}-${isPR}-${songindex}`}
                tag={tag}
                prediction={predictions[song.index]}
                threshold={threshold}
                isPR={isPR}
              />
            );
          })}
        </div>
      </td>
    </tr>
  );
};
// -------------------------------------------------------------
// -------------------------------------------------------------
const NonAlphanumericCluster = ({ songs, predictions, threshold, isPR }) => {
  const nanSongs = songs.filter((song) => {
    const tag = song["Original_Song_Title_SB"];
    if (!tag) return false;
    const isInLetter = !aZAll.includes(tag.toUpperCase().charAt(0));
    return isInLetter;
  });

  if (!nanSongs.length) return null;
  return (
    <tr>
      <td>
        <b>Non-alphanumeric</b>
        <div className="field is-grouped is-grouped-multiline">
          {nanSongs.map((song, songindex) => {
            const tag = song["Original_Song_Title_SB"];
            return (
              <SongTag
                rowindex={song.index}
                key={`song-nan-${songindex}`}
                tag={tag}
                prediction={predictions[song.index]}
                threshold={threshold}
                isPR={isPR}
              />
            );
          })}
        </div>
      </td>
    </tr>
  );
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const SongTag = ({ tag, prediction, threshold, rowindex, isPR }) => {
  const dispatch = useDispatch();
  const notAboveThreshold =
    prediction !== -1 ? prediction[1] < threshold : false;
  const tagClass = isPR ? "tag is-warning is-light" : "tag is-info is-light";
  return (
    <div
      className="control is-clickable"
      onClick={() => {
        dispatch(setPoolRevenue(rowindex, isPR ? "" : "X"));
      }}
    >
      <div className="tags has-addons">
        <div className={tagClass}>{tag}</div>
        {notAboveThreshold ? (
          <div className="tag is-danger is-light">
            {prediction[1].toFixed(1)}
          </div>
        ) : null}
      </div>
    </div>
  );
};
// -------------------------------------------------------------
// -------------------------------------------------------------
const Threshold = ({ threshold, setThreshold }) => {
  const [value, setValue] = useState(threshold);
  const onSubmit = (evt) => {
    if (value > 0 && value <= 1) {
      setThreshold(value);
      return;
    }
    setValue(threshold);
  };

  return (
    <input
      className="input is-small"
      type="text"
      placeholder="Set Confidence Threshold"
      value={value}
      onChange={(evt) => {
        setValue(evt.target.value);
      }}
      onKeyPress={(event) => {
        if (!/[0-9\.]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      onBlur={onSubmit}
      onKeyDown={(evt) => {
        if (evt.key === "Enter") onSubmit(evt);
      }}
    />
  );
};

/*
// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ norming, setReviewing }) => {
  const dispatch = useDispatch();
  const datatype = "pool";
  const { pool } = norming;
  if (!pool.data) return null;
  if (!pool.data.length) return null;
  const { data, predictions, reviewed } = pool;

  const onEdit = () => {};

  return (
    <div className="table-wrapper-shorter">
      <div className="table-container">
        <table className="table is-striped">
          <thead>
            <tr>
              <th>#</th>
              <ClickableHeader thisKey="Count" datatype={datatype}>
                Cnt
              </ClickableHeader>
              <ClickableHeader
                thisKey="Original_Song_Title_SB"
                datatype={datatype}
              >
                Original Title
              </ClickableHeader>
              <ClickableHeader thisKey="Pool_Revenue_9LC" datatype={datatype}>
                Classification
              </ClickableHeader>
              <th>Predicted</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowindex) => (
              <tr
                key={`row-${rowindex}`}
                onClick={() => {
                  setReviewing(rowindex);
                }}
                className="is-clickable"
              >
                <td>{rowindex + 1}</td>
                <td>{row["Count"]}</td>
                <td>
                  <div className="tag">{row["Original_Song_Title_SB"]}</div>
                </td>
                <td>
                  <div
                    className="tags has-addons pool-tags"
                    onClick={() => {
                      onEdit(rowindex);
                    }}
                  >
                    <PoolTag
                      content={row["Pool_Revenue_9LC"]}
                      rowindex={rowindex}
                    />
                  </div>
                </td>
                <td>
                  <PredictedTag prediction={predictions[rowindex]} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
// -------------------------------------------------------
// -------------------------------------------------------
const PoolTag = ({ content, rowindex }) => {
  const dispatch = useDispatch();
  if (content === null) {
    return null;
  }
  return content === "X" ? (
    <div className="tags has-addons">
      <div
        className="tag is-warning is-light"
        onClick={(evt) => {
          evt.stopPropagation();
          dispatch(setPoolRevenue(rowindex, ""));
          dispatch(setPredictionApproval("pool", rowindex, true));
        }}
      >
        PR
      </div>
    </div>
  ) : (
    <div className="tags has-addons">
      <div
        className="tag is-info is-light"
        onClick={(evt) => {
          evt.stopPropagation();
          dispatch(setPoolRevenue(rowindex, "X"));
          dispatch(setPredictionApproval("pool", rowindex, true));
        }}
      >
        Song
      </div>
    </div>
  );
};

// -------------------------------------------------------
// -------------------------------------------------------
const PredictedTag = ({ prediction }) => {
  if (prediction === -1) return null;
  const predictedText = prediction[0] === "X" ? "PR" : "Song";
  return <div className="tag is-success is-light">{predictedText}</div>;
};

// -------------------------------------------------------
// -------------------------------------------------------
const Review = ({ reviewing, onClose, onInc, onDec }) => {
  useEffect(() => {
    checkPrediction();
  }, [reviewing]);

  const dispatch = useDispatch();

  const norming = useSelector((state) => state.norming);
  const { pool } = norming;
  const { data, reviewed, predictions } = pool;
  const dataSize = data.length;
  const numberReviewed = reviewed.filter((x) => x === true).length;

  const canDec = reviewing > 0;
  const canInc = reviewing < dataSize - 1;

  const row = data[reviewing];
  const prediction = predictions[reviewing];

  const checkPrediction = () => {
    if (predictions[reviewing] === -1) {
      dispatch(predictPool(data, reviewing));
    }
  };

  const text =
    row["Pool_Revenue_9LC"] === -1
      ? null
      : row["Pool_Revenue_9LC"] === "X"
      ? "PR"
      : "Song";
  const predictedText =
    prediction === -1 ? "..." : prediction === "X" ? "PR" : "Song";

  const matchesPrediction = prediction === row["Pool_Revenue_9LC"];

  const onApprove = () => {
    dispatch(setPredictionApproval("pool", reviewing, true));
    if (canInc) {
      onInc();
    }
  };

  const isApproved = reviewed[reviewing];

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head convert">
          <div className="review-wrapper">
            <p className="modal-card-title">
              Review ({reviewing}/{dataSize})
            </p>
            <div className="review-progress">
              <progress
                className="convert progress is-primary"
                value={numberReviewed}
                max={pool.data.length}
              />
            </div>
            <button className="delete" onClick={onClose} />
          </div>
        </header>
        <section className="modal-card-body">
          <div className="title">
            {row["Original_Song_Title_SB"]}
            <div className="status-wrapper">
              {isApproved ? (
                <div className="tag is-success is-light is-small">
                  <i className="fa fa-check" />
                  &nbsp;Approved
                </div>
              ) : (
                <div className="tag is-danger is-light is-small">
                  <i className="fa fa-times" />
                  &nbsp;Not yet approved
                </div>
              )}
            </div>
          </div>
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Classification</th>
                  <th>Predicted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div
                      className="tag is-large is-info is-light is-clickable"
                      onClick={() => {
                        dispatch(
                          setPoolRevenue(reviewing, text === "PR" ? "" : "X")
                        );
                      }}
                    >
                      {text}
                    </div>
                  </td>
                  <td>
                    {typeof prediction !== "string" ? (
                      <div>
                        <img src={running} />
                      </div>
                    ) : (
                      <div
                        className={`tag ${
                          matchesPrediction ? "is-success" : "is-danger"
                        }  is-light`}
                      >
                        {predictedText}
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <footer className="modal-card-foot">
          {isApproved ? (
            <div className="footer-buttons">
              <button
                className="button is-pulled-left"
                onClick={onDec}
                disabled={!canDec}
              >
                <span className="icon is-small">
                  <i className="fa fa-chevron-left" />
                </span>
                <span>Previous</span>
              </button>

              <button
                className="button is-pulled-right is-primary"
                onClick={onInc}
              >
                <span>Next</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>
              <button
                className="button is-pulled-left"
                disabled={!canInc}
                onClick={() => {
                  dispatch(setPredictionApproval("pool", reviewing, false));
                }}
              >
                <span>Un-Approve</span>
              </button>
            </div>
          ) : (
            <div className="footer-buttons">
              <button
                className="button is-pulled-left"
                onClick={onDec}
                disabled={!canDec}
              >
                <span className="icon is-small">
                  <i className="fa fa-chevron-left" />
                </span>
                <span>Previous</span>
              </button>
              <button
                className="button is-primary is-pulled-right"
                disabled={!canInc}
                onClick={onApprove}
              >
                <span>Approve</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>

              <button className="button is-pulled-left" onClick={onInc}>
                <span>Next</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

*/
