const initialState = {
  tabular: null,
  totalSongs: null,
  current: null,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "SONGBRIDGE_SET_SONG":
      return {
        ...state,
        rawSong: action.rawSong,
        song: action.song,
        performer: action.performer,
        songIndex: action.songIndex
      };

    // -------------------------------------------------------
    case "SHOW_HIDE_SONG_NORM":
      return { ...state, showSongNorm: action.show };
    // -------------------------------------------------------
    case "SHOW_HIDE_SONG_DETAILS":
      return { ...state, showSongDetails: action.show };

    // -------------------------------------------------------
    case "SONGBRIDGE_NORM_SONG":
      return { ...state, song: action.song, performer: action.performer };

    // -------------------------------------------------------
    case "SONGBRIDGE_SHOW_HIDE":
      return {
        ...state,
        tabular: action.show ? [] : null,
      };
    // -------------------------------------------------------
    case "INIT_SONGBRIDGE":
      return {
        tabular: [],
        current: 0,
        totalSongs: action.totalSongs,
      };
    // -------------------------------------------------------
    case "ADD_SONGBRIDGE_ENTRY":
      return {
        ...state,
        tabular: [...state.tabular, action.entry],
        current: action.index,
      };

    default:
      return state;
  }
};

export default reducer;
