import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// ------------------------------------------
// ------------------------------------------
const EditableTag = ({ currentValue, rowindex, className, updateData }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(currentValue);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const onSubmit = () => {
    setEditing(false);
    dispatch(updateData(rowindex, value));
  };

  if (editing) {
    return (
      <input
        className="input"
        onBlur={onSubmit}
        autoFocus={true}
        value={value}
        onChange={(evt) => setValue(evt.target.value)}
        onKeyDown={(evt) => evt.key === "Enter" && onSubmit()}
      />
    );
  }
  return (
    <div
      className="input-wrapper"
      onClick={() => {
        setEditing(true);
      }}
    >
      {value ? <div className={className}>{value}</div> : <div>&nbsp;</div>}
    </div>
  );
};

export default EditableTag;
