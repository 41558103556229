import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// -------------------------------------------------------------
import Projects from "Screens/Projects";
import Project from "Screens/Project";
// -------------------------------------------------------------
import SignIn from "SignIn/";
// -------------------------------------------------------------
import { signIn } from "Redux/user/actions/";
// -------------------------------------------------------------
import store from "System/mainStore";
import "./index.css";
import "Styles/bulma.min.css";
import "Fontawesome/css/all.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const user = localStorage.getItem("user");

if (user) {
  const { dispatch } = store;

  dispatch(signIn(user));

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/projects/:projectid" component={Project} />
            <Route path="/" component={Projects} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(<SignIn />, document.getElementById("root"));
}
