import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { ClickableHeader } from "../Shared/ClickableHeader";
// -------------------------------------------------------------
import {
  predictSource,
  setPredictionApproval,
  batchSources,
  setSource,
  clearNorming,
  loadNorming,
  acceptAllSources,
} from "Redux/norming/actions";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
// -------------------------------------------------------------
import running from "Media/running.gif";
import waitbar from "Media/waitbar.gif";
import "./css.css";

const datatype = "source";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [loadingNorming, setLoadingNorming] = useState(false);
  const [predicting, setPredicting] = useState(false);
  const [reviewing, setReviewing] = useState(null);
  const [threshold, setThreshold] = useState(0.9);

  const norming = useSelector((state) => state.norming);
  const { data, unsynced } = norming[datatype];

  useEffect(() => {
    setLoadingNorming(true);
    dispatch(
      loadNorming(datatype, () => {
        setLoadingNorming(false);
      })
    );
  }, []);

  if (loadingNorming) return <img src={waitbar} />;

  return (
    <div>
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <div
            className={`button is-small ${predicting ? "is-loading" : ""}`}
            onClick={() => {
              setPredicting(true);
              dispatch(
                batchSources(() => {
                  setPredicting(false);
                })
              );
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-network-wired" />
            </span>
            <span>Classify</span>
          </div>
        </td>
        <td>&nbsp;</td>
        <td>
          <div
            className={`button is-small`}
            onClick={() => {
              if (
                window.confirm("Are you sure? This cannot be undone.") == true
              ) {
                dispatch(clearNorming(datatype));
              }
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-trash-alt" />
            </span>
            <span>Clear</span>
          </div>
        </td>
        <td>&nbsp;</td>
        <td>
          <Threshold threshold={threshold} setThreshold={setThreshold} />
        </td>
        <td>
          <div
            className={`button is-small`}
            onClick={() => {
              if (window.confirm("Are you sure?") == true) {
                dispatch(acceptAllSources(threshold));
              }
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-check" />
            </span>
            <span>Accept All</span>
          </div>
        </td>
      </NormingButtons>

      <MainTable
        norming={norming}
        setReviewing={setReviewing}
        threshold={threshold}
      />
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const Threshold = ({ threshold, setThreshold }) => {
  const [value, setValue] = useState(threshold);
  const onSubmit = (evt) => {
    if (value > 0 && value <= 1) {
      setThreshold(value);
      return;
    }
    setValue(threshold);
  };

  return (
    <input
      className="input is-small"
      type="text"
      placeholder="Set Confidence Threshold"
      value={value}
      onChange={(evt) => {
        setValue(evt.target.value);
      }}
      onKeyPress={(event) => {
        if (!/[0-9\.]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      onBlur={onSubmit}
      onKeyDown={(evt) => {
        if (evt.key === "Enter") onSubmit(evt);
      }}
    />
  );
};
// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ norming, setReviewing, threshold }) => {
  const dispatch = useDispatch();
  const [groups, setGroups] = useState([]);

  const datatype = "source";
  const { source } = norming;

  useEffect(() => {
    if (source && source.predictions) {
      const theseGroups = Array.from(
        new Set(predictions.map((prediction) => prediction[0]))
      ).map((x) => ({
        source: x,
        members: predictions
          .map((y, i) => (y[0] === x ? source.data[i]["Source_SB"] : null))
          .filter((x) => x !== null),
      }));
      setGroups(theseGroups);
    }
  }, [source]);

  if (!source.data) return null;
  if (!source.data.length) return null;
  const { data, predictions, reviewed } = source;
  if (!predictions) return null;

  console.log("Los Predictiones", predictions);
  console.log("Lose Groupos", groups);
  return (
    <div className="table-wrapper">
      <table className="table is-fullwidth is-striped">
        <tbody>
          {groups.map((group, index) => (
            <tr key={`group-${index}`}>
              <td>{group.source}</td>
              <td>
                <div className="tags multiline">
                  {group.members.map((member, midx) => (
                    <div key={`member-${index}-${midx}`} className="tag">
                      {member}
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
