const initialState = {
  newCollectionPopup: false,
  updateCollection: false,
  collectionid: null,
  aggregateHeaders: null,
  bulkReference: null,
  bulkCut: null,
  sourceLinkHeaders: null,
  uploadSourcesPopup: null
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = ( state = initialState, action ) => {

  // -------------------------------------------------------
  switch(action.type) {
    


    // -------------------------------------------------------
    case "SET_BULK_REFERENCE":
      return { 
        ...state,
        aggregateHeaders: action.aggregateHeaders,
        bulkCollection: action.bulkCollection
      }
    // -------------------------------------------------------
    case "SET_BULK_HEADERS":
      return { 
        ...state,
        aggregateHeaders: action.aggregateHeaders,
        bulkCollection: action.bulkCollection
      }
    // -------------------------------------------------------
    case 'SET_UPDATE_PROGRESS':
      
      if(action.pointer === null) {
        return { ...state, updateProgress: null }
      }
      
      return { 
        ...state,
        updateProgress: {
          headline: action.headline, mainline: action.mainline,
          pointer: action.pointer, status: action.status, 
          total: action.total
        }
      }
    
    // -------------------------------------------------------
    case "SET_BULK_CUT":
      return { ...state, bulkCut: action.bulkCut }

      // -------------------------------------------------------
    case "SET_COLLECTION_AGGREGATE_HEADERS":
      return { 
        ...state,
        aggregateHeaders: action.aggregateHeaders 
      }

    // -------------------------------------------------------
    case "SET_COLLECTION_ID__":
      return { 
        ...state,
        collectionid: action.collectionid 
      }
    // -------------------------------------------------------
    case "EDIT_UPDATE_TABLE":
      return { 
        ...state,
      updateCollection: action.collection 
      }

    // -------------------------------------------------------
    case "DISPLAY_NEW_COLLECTION_POPUP":
      return { ...state, newCollectionPopup: action.showPopup }

    // -------------------------------------------------------
    default:
      return state;
  }
}

export default reducer;