import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
import { ClickableHeader } from "../Shared/ClickableHeader/";
// -------------------------------------------------------------
import { backend } from "System/system";
// -------------------------------------------------------------
import {
  setCountry,
  buildCountriesFromDB,
  loadNorming,
} from "Redux/norming/actions";
import { geoData } from "CountriesData/geoData";
// -------------------------------------------------------------
import "./css.css";
import waitbar from "Media/waitbar.gif";

const datatype = "country";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [syncing, setSyncing] = useState(false);
  const [loadingNorming, setLoadingNorming] = useState(false);

  const norming = useSelector((state) => state.norming);

  const { country } = norming;
  //const { data, reviewed, predictions } = country;
  const { data, unsynced } = norming[datatype];

  useEffect(() => {
    setLoadingNorming(true);
    dispatch(
      loadNorming(datatype, () => {
        setLoadingNorming(false);
      })
    );
  }, []);

  const primeDB = async () => {
    for (var country of geoData.countries) {
      const items = [];
      const item = country.country.trim();
      if (country.country) items.push(country.country);
      if (country.subRegion) items.push(country.subRegion);
      if (country.intermediateRegion) items.push(country.intermediateRegion);
      if (country.region) items.push(country.region);
      const entry = items.join(" : ").trim();
      await backend("norming", "addCountryMapping", {
        from: item,
        to: entry,
      });
    }
  };

  if (loadingNorming) return <img src={waitbar} />;

  return (
    <div>
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <button
            className={`button is-small ${syncing ? "is-loading" : ""}`}
            onClick={() => {
              dispatch(buildCountriesFromDB());
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-database" />
            </span>
            <span>Populate</span>
          </button>
        </td>
      </NormingButtons>

      <div className="columns">
        <div className="column">
          <MainTable norming={norming} />
        </div>
        <div className="column page-height">
          Countries
          <div className="tags box">
            {geoData.countries.map((country) => {
              return <GeoTag key={country.alpha2} country={country} />;
            })}
          </div>
          Sub-Region
          <div className="tags box">
            {geoData.subRegions.map((subRegion) => {
              return <GeoTag key={subRegion.subRegion} subRegion={subRegion} />;
            })}
          </div>
          Intermediate Region
          <div className="tags box">
            {geoData.intermediateRegions.map((intermediateRegion) => {
              return (
                <GeoTag
                  key={intermediateRegion.intermediateRegion}
                  intermediateRegion={intermediateRegion}
                />
              );
            })}
          </div>
          Region
          <div className="tags box">
            {geoData.regions.map((region) => {
              return <GeoTag key={region.region} region={region} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ norming }) => {
  const dispatch = useDispatch();
  const { data } = norming[datatype];
  if (!data) return null;
  if (!data.length) return null;

  return (
    <div className="table-wrapper">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>#</th>
            <ClickableHeader thisKey="Count" datatype={datatype}>
              Cnt
            </ClickableHeader>
            <ClickableHeader thisKey="Country_SB" datatype={datatype}>
              Original Country
            </ClickableHeader>
            <th>Normalized</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => {
            return (
              <tr
                key={`row-${rowindex}`}
                onDragOver={(evt) => evt.preventDefault()}
                onDrop={(evt) => {
                  const entry = evt.dataTransfer.getData("entry");
                  dispatch(setCountry(rowindex, entry));
                }}
              >
                <td>{rowindex + 1}</td>
                <td>{row["Count"]}</td>
                <td>
                  <div className="tag">{row["Country_SB"]}</div>
                </td>
                <td>
                  {row["Normalized_Country_9LC"] ? (
                    <div className="tag">
                      {row["Normalized_Country_9LC"]}
                      &nbsp;
                      <button
                        className="delete is-small"
                        onClick={() => {
                          dispatch(setCountry(rowindex, ""));
                        }}
                      />
                    </div>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const GeoTag = ({ country, region, subRegion, intermediateRegion }) => {
  const items = [];
  let item;
  if (country) {
    item = country.country;
    if (country.country) items.push(country.country);
    if (country.subRegion) items.push(country.subRegion);
    if (country.intermediateRegion) items.push(country.intermediateRegion);
    if (country.region) items.push(country.region);
  }
  if (subRegion) {
    item = subRegion.subRegion;
    if (subRegion.subRegion) items.push(subRegion.subRegion);
    if (subRegion.intermediateRegion) items.push(subRegion.intermediateRegion);
    if (subRegion.region) items.push(subRegion.region);
  }
  if (intermediateRegion) {
    item = intermediateRegion.intermediateRegion;
    if (intermediateRegion.intermediateRegion)
      items.push(intermediateRegion.intermediateRegion);
    if (intermediateRegion.region) items.push(intermediateRegion.region);
  }
  if (region) {
    item = region.region;
    if (region.region) items.push(region.region);
  }

  const entry = items.join(" : ");

  return (
    <div
      className="tag country-is-draggable"
      draggable
      onDragStart={(evt) => {
        evt.dataTransfer.setData("entry", entry);
      }}
    >
      {item}
    </div>
  );
};
