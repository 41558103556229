import { useSelector } from 'react-redux';
// ------------------------------------------------------
import "./css.css";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = ({}) => {

  const progress = useSelector( state => state.progress );
  const { title, status, current, total } = progress;

  if(!progress.total) return null;

  
  return(
    <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card sum-attributes">
      <header className="modal-card-head">
        <p className="modal-card-title">
          { title }
        </p>
      </header>

      <section className="modal-card-body">
        <div className = "box">
          <progress className = "progress is-primary" max = { total } value = { current } />
          <div>{ status }</div>
        </div>
      </section>

      <footer className="modal-card-foot" />
    </div>
  </div>
  )
}
export default Index;