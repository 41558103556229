import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// ----------------------------------------------------------------
import "./css.css";

// ----------------------------------------------------------------
// ----------------------------------------------------------------
const Index = () => {
  const history = useHistory();

  const projects = useSelector((state) => state.projects);
  const nav = useSelector((state) => state.nav);

  const { project } = projects;
  const { path } = nav;

  return (
    <>
    <div className="breadcrumbs-wrapper box">
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <a
              onClick={() => {
                history.push("/");
              }}
            >
              Projects
            </a>
          </li>
          <li>
            <a>{project ? project.title : "..."}</a>
          </li>
          {path.map((entry) => {
            return (
              <li
                key={entry.tab}
                className={entry.hasSubTabs ? "" : "is-active"}
              >
                <a>{entry.headline}</a>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
    </>
  );
};

export default Index;
