export const clients = [
  { entity: "Mojo" },
  { entity: "Nine Lives Capital" },
  { entity: "ARC" },
  { entity: "AMR" },
  { entity: "Concord" },
  { entity: "Alternative" },
  { entity: "Tailwind" },
  { entity: "Utopia" },
  { entity: "Hipgnosis" },
  { entity: "Royalty Earner" },
  { entity: "Art Issue" },
  { entity: "BitSonic" },
  { entity: "GRF" },
  { entity: "CC Young" },
  { entity: "David Ravden" },
  { entity: "Letter One" },
  { entity: "Music Royalties Inc" },
  { entity: "Bitter and Twisted Management" },
  { entity: "Tempo" },
  { entity: "Influence Media" },
  { entity: "Kilometre" },
  { entity: "Lyric" },
];
