import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
// -------------------------------------------------------------
import Frame from "Components/FrameMain/";
import NewProject from "./Components/NewProject";
// -------------------------------------------------------------
import { lockProject, listProjects } from "Redux/projects/actions";
import { getActiveFrames } from "Redux/frame/actions";
import { clients } from "Data/clients";

// -------------------------------------------------------------
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const batchSize = 500;

  const projects = useSelector((state) => state.projects);
  const user = useSelector((state) => state.user);

  const [thisUser, setThisUser] = useState(null);
  const [mode, setMode] = useState("clients");
  const [howMany, setHowMany] = useState(batchSize);
  const [client, setClient] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listProjects(thisUser, batchSize));
    dispatch(getActiveFrames());
  }, []);

  const changeTab = (thisMode, setUser) => {
    setMode(thisMode);
    if (thisMode === "user") {
      setThisUser(setUser);
    }
    if (thisMode === "clients") {
      setClient(null);
      setThisUser(null);
      return;
    }
    dispatch(listProjects(setUser, null));
  };

  const changeClient = (newClient) => {
    setClient(newClient);
    dispatch(listProjects(null, newClient));
  };

  const projectsList = projects.list || [];
  const isLoading = projects.list === null;
  return (
    <Frame isLoading={isLoading}>
      {projects.newProject ? <NewProject /> : null}

      <div className="tabs is-boxed">
        <ul>
          <li className={mode === "clients" ? "is-active" : ""}>
            <a
              onClick={() => {
                changeTab("clients");
              }}
            >
              By Client
            </a>
          </li>

          <li className={mode === "all" ? "is-active" : ""}>
            <a
              onClick={() => {
                changeTab("all");
              }}
            >
              All Projects
            </a>
          </li>
          <li className={mode === "user" ? "is-active" : ""}>
            <a
              onClick={() => {
                changeTab("user", user.user);
              }}
            >
              My Projects
            </a>
          </li>
        </ul>
      </div>

      {mode === "clients" ? (
        <div>
          <ClientList client={client} changeClient={changeClient} />
          {client ? (
            <MainTable isLoading={isLoading} projectsList={projectsList} />
          ) : null}
        </div>
      ) : (
        <MainTable isLoading={isLoading} projectsList={projectsList} />
      )}
    </Frame>
  );
};
export default Index;

// ----------------------------------------------------
// ----------------------------------------------------
const ClientList = ({ client, changeClient }) => {
  return (
    <div className="tags">
      {clients.map((thisClient, index) => (
        <div
          className={`tag is-client ${
            thisClient.entity === client ? "is-primary is-large" : ""
          }`}
          key={thisClient.entity}
          onClick={() => changeClient(thisClient.entity)}
        >
          {thisClient.entity}
        </div>
      ))}
    </div>
  );
};

// ----------------------------------------------------
// ----------------------------------------------------
const MainTable = ({ isLoading, projectsList }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const frame = useSelector((state) => state.frame);

  if (!frame.activeFrames) return null;

  return (
    <div className="table-container">
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          {isLoading ? null : (
            <tr>
              <th>Project</th>
              <th>Status</th>
              <th>Client</th>
              <th>Set Up</th>
              <th>Modified</th>
            </tr>
          )}
        </thead>
        <tbody>
          {projectsList.map((project, prindex) => {
            const { _id, title, user, modified, setup, client } = project;
            //const setupDate = new Date(setup).toLocaleDateString("de-de");
            const setupDate = moment(setup).fromNow();
            const modifiedDate = moment(modified).fromNow();
            const { locked } = project;
            const spacer = frame.activeFrames.includes(_id)
              ? "spacer-active"
              : "spacer-inactive";

            return (
              <tr
                key={`project-key-${prindex}`}
                className="project-item"
                onClick={() => {
                  let open = true;
                  if (locked)
                    if (
                      !window.confirm("This project is locked. Open anyway?")
                    ) {
                      open = false;
                    }
                  if (open) {
                    dispatch(lockProject(_id));
                    dispatch({ type: "RESET_PROJECT" });
                    history.push(`/projects/${_id}`);
                  }
                }}
              >
                <td>
                  <span>{title}</span>
                  {project.hasFrame ? (
                    <span className={`icon is-small ${spacer}`}>
                      <i className="fa fa-microchip" />
                    </span>
                  ) : null}
                </td>
                {locked ? (
                  <td>
                    <div className="tag is-warning">
                      <span>{locked}&nbsp;</span>
                      <span className="icon is-small">
                        <i className="fa-duotone fa-user-lock" />
                      </span>
                    </div>
                  </td>
                ) : (
                  <td>
                    <i className="fa-duotone fa-lock-keyhole-open" />
                  </td>
                )}

                <td>{client}</td>
                <td>{setupDate}</td>
                <td>{modifiedDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
