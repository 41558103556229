import { useSelector, useDispatch } from "react-redux";
import { setActiveTab } from "Redux/nav/actions";

const Index = ({ children }) => {
  const dispatch = useDispatch();
  const nav = useSelector((state) => state.nav);
  const { leaf, mainTabs } = nav;

  const setTab = (tab, ancestor) => {
    dispatch(setActiveTab(tab, ancestor));
  };

  return (
    <MainTabs
      tabs={mainTabs}
      setTab={setTab}
      ancestor="root"
      children={children}
    />
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTabs = ({ tabs, ancestor, setTab, depth, children }) => {
  const pickedTab = tabs.find((tab) => tab.active);

  return (
    <div className="box__">
      <div className="tabs">
        <ul>
          {tabs.map((tab) => {
            if (!tab.invisible) {
              return (
                <Tab
                  tab={tab}
                  key={tab.tab}
                  setTab={setTab}
                  isActive={tab.active}
                  depth={depth}
                  ancestor={ancestor}
                />
              );
            }
          })}
        </ul>
      </div>
      {pickedTab.tabs ? (
        <MainTabs
          tabs={pickedTab.tabs}
          setTab={setTab}
          depth={depth + 1}
          ancestor={pickedTab.tab}
          children={children}
        />
      ) : (
        children
      )}
    </div>
  );
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Tab = ({ tab, setTab, ancestor, isActive }) => {
  return (
    <li className={isActive ? "is-active" : ""}>
      <a
        onClick={() => {
          setTab(tab.tab, ancestor);
        }}
      >
        <span className="icon is-small">
          <i className={tab.iconclass} aria-hidden="true" />
        </span>
        <span>{tab.headline}</span>
      </a>
    </li>
  );
};
