import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import currencies from "Data/currencies";
// -------------------------------------------------------------
import {
  setProjectCurrencies,
  setOutputCurrency,
  calculateCurrencyAdjustments,
} from "Redux/currencies/actions";
// -------------------------------------------------------------
import "./css.css";

const confirm = () => {
  return window.confirm("Are you sure? This action cannot be undone.");
};

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [adjusting, setAdjusting] = useState(false);
  const [setting, setSetting] = useState(false);

  const projects = useSelector((state) => state.projects);

  const {
    project,
    project: { outputCurrency, frameCurrencyAdjusted },
  } = projects;

  const changeOutputCurrency = (evt) => {
    setSetting(true);
    dispatch(
      setOutputCurrency(project, evt.target.value, () => {
        setSetting(false);
      })
    );
  };

  return (
    <div className="master-wrapper">
      <div className="box">
        {frameCurrencyAdjusted ? (
          <div className="select-wrapper">
            <div>Output Currency</div>
            <div className="select">
              <select
                className="select"
                value={outputCurrency}
                onChange={changeOutputCurrency}
              >
                <option value="USD">$&nbsp;|&nbsp;USD</option>
                {currencies.map((x) => {
                  return (
                    <option key={x.currency} value={x.currency.toUpperCase()}>
                      {x.symbol}&nbsp;|&nbsp;
                      {x.currency.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        ) : null}
        <div className="select-wrapper" style={{ marginTop: 35 }}>
          <button
            className={`button is-primary ${adjusting ? "is-loading" : ""}`}
            onClick={() => {
              setAdjusting(true);
              dispatch(
                calculateCurrencyAdjustments(project, () => {
                  setAdjusting(false);
                })
              );
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-cogs" />
            </span>
            <span>
              {frameCurrencyAdjusted
                ? "Re-calculate Currency Adjustments"
                : "Calculate Currency Adjustments"}
            </span>
          </button>
        </div>
      </div>

      <Currencies />
    </div>
  );
};
export default Index;

// ----------------------------------------------------
// ----------------------------------------------------
const Currencies = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects);
  const {
    project: { currency },
  } = projects;

  const changeCurrency = (evt) => {
    dispatch(
      setProjectCurrencies({
        ...currency,
        [evt.target.id]: parseFloat(evt.target.value),
      })
    );
  };

  return (
    <div className="box">
      <div>Exchange Rates. Value in USD.</div>
      <div className="currency-box">
        {currencies.map((entry) => {
          const thisCurrency = entry.currency;
          return (
            <div
              key={entry.currency}
              className="field is-horizontal box curr-item"
            >
              <div className="field-label is-normal">
                <label className="label">{entry.currency.toUpperCase()}</label>
              </div>
              <p className="control has-icons-left">
                <input
                  className="input currency-input"
                  type="number"
                  placeholder="Dollar value"
                  value={currency[thisCurrency]}
                  onChange={changeCurrency}
                  id={thisCurrency}
                />
                <span className="icon is-small is-left">{entry.symbol}</span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
