import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { fromDB, toCSV, readFrame } from "Redux/frame/actions";
// -------------------------------------------------------------
import "./css.css";
import waitbar from "Media/waitbar.gif";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [building, setBuilding] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const projects = useSelector((state) => state.projects);
  const frame = useSelector((state) => state.frame);
  const [headers, setHeaders] = useState([
    "Adjusted_Royalty_SB",
    "Adjusted_Royalty_USD_SB",
  ]);

  const {
    project,
    project: { hasFrame, collectionsRepo },
  } = projects;

  if (!hasFrame) return null;

  const frames = project.frames || [];
  const allHeaders =
    frame.frameData && frame.frameData.data
      ? Object.keys(frame.frameData.data[0])
      : [];
  const freeCols = allHeaders.filter((h) => !headers.includes(h));

  return (
    <div>
      <div className="box">
        <button
          className={`button ${downloading ? "is-loading" : ""}`}
          onClick={() => {
            setDownloading(true);
            dispatch(
              toCSV((result) => {
                window.location.href = result.url;
                setDownloading(false);
              })
            );
          }}
        >
          Export Main Frame to CSV
        </button>
      </div>

      <div className="box">
        <table className="table">
          <tr>
            <td>
              <div className="select">
                <select
                  className="select"
                  onChange={(evt) => {
                    switch (evt.target.value) {
                      case "pick":
                        break;
                      default:
                        dispatch(readFrame(evt.target.value, () => {}));
                        break;
                    }
                  }}
                >
                  <option value="pick">- Pick a Frame -</option>
                  <option value="main">Main</option>
                  {frames.map((f) => (
                    <option key={f} value={f}>
                      {collectionsRepo[f].headline}
                    </option>
                  ))}
                </select>
              </div>
            </td>
            {/* <td>
              <div className="buttons">
                <button
                  className={`button is-small ${
                    downloading ? "is-loading" : ""
                  }`}
                  onClick={() => {
                    dispatch(readFrame(() => {}));
                  }}
                >
                  Display Frame Data
                </button>
                {frame.frameData ? (
                  <div className="tag is-large">{frame.frameData.nrows}</div>
                ) : null}
              </div>
            </td>*/}
            {frame.frameData ? (
              <td>
                <div className="tag is-large">{frame.frameData.nrows}</div>
              </td>
            ) : null}
          </tr>
        </table>
      </div>
      {frame.frameData === "loading" ? (
        <div>
          <img src={waitbar} />
        </div>
      ) : (
        <div className="columns">
          <div className="column is-one-quarter">
            <div className="tags">
              {freeCols.map((col) => {
                return (
                  <div
                    className="tag is-small is-clickable"
                    key={col}
                    onClick={() => {
                      setHeaders([...headers, col]);
                    }}
                  >
                    {col}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="column">
            <FrameData
              res={frame.frameData}
              headers={headers}
              setHeaders={setHeaders}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default Index;

const clean = (str) => {
  if (typeof str !== "string") return str;
  if (str === null) return null;
  return str.replace(/ /g, "\u00A0");
};

const FrameData = ({ res, headers, setHeaders }) => {
  console.log("Data is", res);
  if (!res || !res.data || !res.data.length) return null;
  if (typeof res.data === "string") {
    return null;
    console.log(data);
    data = JSON.parse(data);
  }
  const data = res.data;
  //const headers = Object.keys(data[0]);
  return (
    <div className="table-container">
      <table className="table is-fullwidth is-striped is-bordered is-small-table">
        <thead>
          <tr>
            {headers.map((h) => (
              <th key={h}>
                <div className="tags has-addons">
                  <span className="tag is-small is-info is-light">
                    {h}
                    <button
                      className="delete is-small"
                      onClick={() => {
                        setHeaders(headers.filter((hh) => hh !== h));
                      }}
                    />
                  </span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => (
            <tr key={`row-${rowindex}`}>
              {headers.map((h) => (
                <td key={`${rowindex}-${h}`}>{clean(row[h])}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
