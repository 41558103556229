const initialState = {
  linkHeaders: null,
  uploadSourcesPopup: null,
  song: "",
  totalRevenue: null,
  totalCount: null,
  coverageRevenue: null,
  coverageCount: null,
  poolCandidates: null,
  songs: null,
  genius: null,
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "UPDATE_GENIUS_PROGRESS":
      if(action.pointer === null) return { ...state, geniusProgress: null };
      
      return {
        ...state,
        geniusProgress: {
          pointer: action.pointer,
          total: action.total,
          status: action.status,
        },
      };
    // -------------------------------------------------------
    case "SET_GENIUS_ALBUM_DETAILS":
      return {
        ...state,
        genius: {
          ...state.genius,
          albumDetails: {
            ...state.genius.albumDetails,
            [action.album.id]: action.album,
          },
        },
      };

    // -------------------------------------------------------
    case "SET_GENIUS_SONG_DETAILS":
      return {
        ...state,
        genius: {
          ...state.genius,
          songDetails: {
            ...state.genius.songDetails,
            [action.song.id]: action.song,
          },
        },
      };

    // -------------------------------------------------------
    case "SET_GENIUS_HITS":
      return { ...state, genius: { ...state.genius, hits: action.hits } };

    // -------------------------------------------------------
    case "SET_GENIUS":
      return { ...state, genius: action.genius };


    // -------------------------------------------------------
    case "SET_POOL_CANDIDATES":
      return { ...state, poolCandidates: action.poolCandidates };
    // -------------------------------------------------------
    case "UPDATES_SET_TOTALS":
      return {
        ...state,
        totalRevenue: action.totalRevenue,
        totalCount: action.totalCount,
        totalSongs: action.totalSongs,
        coverageRevenue: 0,
        coverageCount: 0,
        coverageSongs: 0,
      };
    // -------------------------------------------------------
    case "UPDATES_SET_COVERAGE":
      return {
        ...state,
        coverageRevenue: action.coverageRevenue,
        coverageCount: action.coverageCount,
        coverageSongs: action.coverageSongs,
      };
    // -------------------------------------------------------
    case "UPLOAD_SOURCES_POPUP":
      return { ...state, uploadSourcesPopup: action.collectionid };

    // -------------------------------------------------------
    case "ADD_LINK_HEADERS":
      return { ...state, linkHeaders: action.linkHeaders };

    default:
      return state;
  }
};

export default reducer;
