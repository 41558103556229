import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import {
  spotifyByArtists,
  geniusByCredits,
  complementDiscogs,
  batchDiscogs,
  setPerformer,
  loadNorming,
} from "Redux/norming/actions";
// -------------------------------------------------------------
import ProgressBar from "Components/ProgressBar";
import NormingButtons from "../Shared/NormingButtons";
import EditableTag from "../Shared/EditableTag";
import { stem, capitalize } from "Aux";
// -------------------------------------------------------------
import "./css.css";

const datatype = "performer";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [predicting, setPredicting] = useState(false);
  const [genius, setGenius] = useState(false);
  const [spotify, setSpotify] = useState(false);
  const [reviewing, setReviewing] = useState(null);
  const [cycling, setCycling] = useState(false);

  const norming = useSelector((state) => state.norming);
  const { artists } = norming;
  const { data, unsynced } = norming[datatype];

  useEffect(() => {
    dispatch({ type: "SET_ARTISTS" });
    dispatch(loadNorming(datatype));
  }, []);

  return (
    <div>
      <ProgressBar />
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <div className="buttons has-addons">
            <div
              className={`button is-small ${predicting ? "is-loading" : ""}`}
              onClick={() => {
                setPredicting(true);
                dispatch(
                  batchDiscogs((result) => {
                    setPredicting(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fas fa-wifi" />
              </span>
              <span>Batch Discogs</span>
            </div>
            <div
              className={`button is-small ${genius ? "is-loading" : ""}`}
              onClick={async () => {
                setGenius(false);
                dispatch(
                  await geniusByCredits(() => {
                    setGenius(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fas fa-wifi" />
              </span>
              <span>Batch Genius</span>
            </div>
            <div
              className={`button is-small ${spotify ? "is-loading" : ""}`}
              onClick={async () => {
                dispatch(
                  await spotifyByArtists(() => {
                    setSpotify(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fas fa-wifi" />
              </span>
              <span>Batch Spotify</span>
            </div>
          </div>
        </td>
        <td>&nbsp;&nbsp;</td>
        <td>
          <Stats />
        </td>
        {artists ? (
          <div className="buttons">
            <div
              className={`button is-small ${cycling ? "is-loading" : ""}`}
              onClick={async () => {
                setCycling(true);
                for (const artist of artists) {
                  dispatch({
                    type: "SET_ARTIST_STATE",
                    artist: artist.artist,
                    isLoading: true,
                  });
                  await dispatch(complementDiscogs(artist.artist, () => {}));
                  dispatch({
                    type: "SET_ARTIST_STATE",
                    artist: artist.artist,
                    isLoading: false,
                  });
                }
                setCycling(false);
              }}
            >
              <span className="icon is-small">
                <i className="fas fa-redo" />
              </span>
              <span>Cycle Artists</span>
            </div>
          </div>
        ) : null}
      </NormingButtons>
      <div className="artist-wrapper">
        <div className="buttons">
          {artists
            ? artists.map((artist, artistindex) => {
                return <Artist artist={artist} key={`artist-${artistindex}`} />;
              })
            : null}
          <ArtistAdd />
        </div>
      </div>
      <MainTable
        norming={norming}
        setReviewing={setReviewing}
        datatype={datatype}
      />
    </div>
  );
};
export default Index;

const ArtistAdd = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [artist, setArtist] = useState("");
  const onSubmit = () => {
    if (artist) {
      dispatch({ type: "ADD_ARTIST", artist });
      setEdit(false);
      setArtist("");
    }
  };
  const onChange = (evt) => {
    setArtist(evt.target.value);
  };
  if (edit)
    return (
      <div style={{ width: 98 }}>
        <input
          className="input is-small"
          type="text"
          value={artist}
          placeholder="Artist Name"
          onChange={onChange}
          onBlur={() => {
            setArtist("");
            setEdit(false);
          }}
          onKeyDown={(evt) => evt.key === "Enter" && onSubmit()}
          autoFocus={true}
        />
      </div>
    );
  return (
    <div className={`button is-rounded is-small`} onClick={() => setEdit(true)}>
      <span className="icon is-small">
        <i className="fas fa-plus" />
      </span>
    </div>
  );
};
// -------------------------------

const Artist = ({ artist }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div
      className={`button is-rounded is-small ${
        artist.isLoading ? "is-loading" : ""
      }`}
      onClick={async () => {
        //setIsLoading(true);
        dispatch({
          type: "SET_ARTIST_STATE",
          artist: artist.artist,
          isLoading: true,
        });
        await dispatch(complementDiscogs(artist.artist, () => {}));
        //setIsLoading(false);
        dispatch({
          type: "SET_ARTIST_STATE",
          artist: artist.artist,
          isLoading: false,
        });
      }}
    >
      {artist.artist}&nbsp;|&nbsp;{artist.count}
    </div>
  );
};
// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ norming, setReviewing, datatype }) => {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.projects);

  const { data, predictions } = norming[datatype];
  if (!data) return null;
  if (!data.length) return null;
  const person = project.keys[0].pivot;

  const splitComposers = (str) => {
    str = str.replace(/[,&\(\)]/g, "-");
    return str.split("-");
  };

  let total = 0;
  for (var row of data) {
    total += row["Count"];
  }
  const cumul = [];
  let sum = 0;
  for (var row of data) {
    sum += row["Count"];
    cumul.push(sum);
  }

  const percent = (x, total) => {
    return ((x * 100) / total).toFixed(2) + "%";
  };

  const setSong = (rowindex, value) => {
    return setPerformer(rowindex, "Song_Name_9LC", value);
  };
  const setArtist = (rowindex, value) => {
    return setPerformer(rowindex, "Release_Artist_9LC", value);
  };

  return (
    <div className="table-wrapper">
      <table className="table is-striped_ is-bordered">
        <thead>
          <tr>
            <th>Consensus Title</th>
            <th>Song Title</th>
            <th>Performer</th>
            <th>Composer</th>
            <th>APIs</th>
            <th>Ext. Lookup</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => {
            const item = `${row["Consensus_Title_SB"].toUpperCase()}`;
            //const item = `${person.toUpperCase()} ${row[
            //  "Consensus_Title_SB"
            //].toUpperCase()}`;

            const consensus = stem(row["Consensus_Title_SB"]);
            const song = stem(row["Song_Name_9LC"]);
            const isWarning = song !== "" && consensus !== song;

            return (
              <tr key={`row-${rowindex}`}>
                <td>
                  <div className="tags has-addons bottom-zero">
                    <div className="tag">{rowindex + 1}</div>
                    <div
                      className="tag is-info is-light is-clickable"
                      onClick={() => {
                        const value = capitalize(row["Consensus_Title_SB"]);
                        dispatch(
                          setPerformer(rowindex, "Song_Name_9LC", value)
                        );
                      }}
                    >
                      {row["Consensus_Title_SB"]}
                    </div>
                  </div>
                  <small>
                    ({row["Count"]} | {percent(row["Count"], total)} |{" "}
                    {percent(cumul[rowindex], total)})
                  </small>
                </td>
                <td>
                  <EditableTag
                    currentValue={row["Song_Name_9LC"]}
                    rowindex={rowindex}
                    className={
                      isWarning
                        ? "tag is-warning click"
                        : "tag is-success is-light click"
                    }
                    updateData={setSong}
                  />
                </td>
                <td>
                  <EditableTag
                    currentValue={row["Release_Artist_9LC"]}
                    rowindex={rowindex}
                    className="tag is-success is-light click"
                    updateData={setArtist}
                  />
                </td>
                <td>
                  <div className="tags">
                    {splitComposers(row["Composer_SB"]).map((x, i) => {
                      if (x.length >= 2)
                        return (
                          <div key={`${x}-${i}`} className="tag">
                            {x}
                          </div>
                        );
                    })}
                  </div>
                </td>
                <td>
                  <div className="field">
                    {predictions[rowindex] === undefined ||
                    predictions[rowindex] === -1
                      ? null
                      : predictions[rowindex].map((item, itemindex) => (
                          <div className="control">
                            <div className="tags has-addons">
                              <div className="tag is-success is-light">
                                <span className="icon is-small">
                                  <i className="fas fa-chevron-left" />
                                </span>
                              </div>
                              <div className="tag">
                                {item.track} / {item.artist}
                              </div>
                              <div className="tag is-info is-light">
                                {item.artistCount}
                              </div>

                              <div className="tag is-warning is-light">
                                <span className="icon is-small">
                                  <i className="fas fa-external-link-alt" />
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </td>
                <td>
                  <div className="buttons has-addons">
                    <button
                      className="button is-small"
                      onClick={() => {
                        const uri = `https://www.discogs.com/search/?q=${item}&type=all&type=all`;
                        window.open(uri);
                      }}
                    >
                      DC
                    </button>
                    <button
                      className="button is-small"
                      onClick={() => {
                        const uri = `https://genius.com/search?q=${item}&type=all&type=all`;
                        window.open(uri);
                      }}
                    >
                      Ge
                    </button>

                    <button
                      className="button is-small"
                      onClick={() => {
                        const uri = `https://www.google.com/search?q="${item}" ${person}`;
                        window.open(uri);
                      }}
                    >
                      Goog
                    </button>
                    <button
                      className="button is-small"
                      onClick={() => {
                        const uri = `https://www.youtube.com/results?search_query=${item}`;
                        window.open(uri);
                      }}
                    >
                      YT
                    </button>
                    <button
                      className="button is-small"
                      onClick={() => {
                        const uri = `https://music.youtube.com/search?q=${item}`;
                        window.open(uri);
                      }}
                    >
                      YTM
                    </button>
                    <button
                      className="button is-small"
                      onClick={() => {
                        const uri = `https://search.azlyrics.com/search.php?q=${item}`;
                        window.open(uri);
                      }}
                    >
                      AZ
                    </button>
                    <button
                      className="button is-small"
                      onClick={() => {
                        const uri = `https://www.lyrics.com/lyrics/${item}`;
                        window.open(uri);
                      }}
                    >
                      LC
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Stats = () => {
  const datatype = "performer";
  const norming = useSelector((state) => state.norming);
  const { data } = norming[datatype];
  if (!data) return null;
  if (!data.length) return null;
  const subset = data.filter((x) => x["Pool_Revenue_9LC"] !== "X");
  const value = subset.filter(
    (x) => x["Release_Artist_9LC"] !== "" && x["Release_Artist_9LC"] !== null
  ).length;
  const total = subset.length;
  return (
    <div className="progress-wrapper">
      <span>
        {value}&nbsp;of&nbsp;{total}
      </span>
      <progress max={total} value={value} className="progress is-primary" />
    </div>
  );
};

const SongItem = ({ row, type, onSubmit, isWarning }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(row[type] || "");

  const tagColor = isWarning ? "is-warning" : "is-success is-light";
  const submit = () => {
    setEdit(false);
    onSubmit(value);
  };

  if (edit)
    return (
      <input
        value={value}
        onChange={(evt) => {
          setValue(evt.target.value);
        }}
        className="input"
        type="text"
        onBlur={submit}
        onKeyDown={(evt) => evt.key === "Enter" && submit()}
      />
    );
  return (
    <div
      onClick={() => setEdit(true)}
      className={`tag ${tagColor} is-clickable`}
    >
      {row[type]}
    </div>
  );
};
