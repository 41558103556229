import { backend, apFly } from "System/system";
import store from "System/mainStore";
// -------------------------------------------------------
import { saveProjectAfterTimeout } from "Redux/projects/actions";

// -------------------------------------------------------
// -------------------------------------------------------
export const getSongs = async (cb) => {
  const projects = store.getState().projects;
  const {
    project: { database, datadir },
  } = projects;
  const result = await apFly("get_unique_titles", { database, datadir });
  if (!result || !result.data) cb([]);
  if (result.data) {
    cb(result.data.unique_titles);
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const addExcludedSong = (song) => (dispatch) => {
  dispatch({
    type: "ADD_EXCLUDED_SONG",
    excludedSong: song,
  });
  dispatch(saveProjectAfterTimeout());
};

// -------------------------------------------------------
// -------------------------------------------------------
export const removeExcludedSong = (song) => (dispatch) => {
  dispatch({
    type: "REMOVE_EXCLUDED_SONG",
    excludedSong: song,
  });
  dispatch(saveProjectAfterTimeout());
};

// -------------------------------------------------------
// -------------------------------------------------------
export const includeAllSongs = (songs) => (dispatch) => {
  dispatch({
    type: "SET_EXCLUDED_SONGS",
    excludedSongs: [],
  });
  dispatch(saveProjectAfterTimeout());
};

// -------------------------------------------------------
// -------------------------------------------------------
export const excludeAllSongs = (songs) => (dispatch) => {
  dispatch({
    type: "SET_EXCLUDED_SONGS",
    excludedSongs: JSON.parse(JSON.stringify(songs)),
  });
  dispatch(saveProjectAfterTimeout());
};




// -------------------------------------------------------
// -------------------------------------------------------
export const applyExcludedSongs_can_erase = (excludedSongs, cb) => async (dispatch) => {

  dispatch(saveProjectAfterTimeout());
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;

  const result = await apFly("apply_excluded_songs", {
    datadir,
    database,
    excluded_songs: JSON.stringify(excludedSongs),
  });
  console.log("applyExcludedSongs", result);

  cb();
};

