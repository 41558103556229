const initialState = {};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    // -------------------------------------------------------
    case "SET_SUMMARY_TOP_VIEW":
      return {
        ...state,
        topView: action.topView,
      };

    // -------------------------------------------------------
    case "SET_SUMMARY":
      return {
        ...state,
        summary: action.summary
      };
    // -------------------------------------------------------
    case "RESET_SUMMARY":
      return {
        ...initialState
      };

    // -------------------------------------------------------
    case "UPDATE_SUMMARY_DETAILS":
      const catalogs = [...state.summary.catalogs];
      catalogs[action.catalogIndex] = {
        ...catalogs[action.catalogIndex],
        nominal: action.Nominal,
        usd: action.USD,
        adjusted: action.Adjusted,
        lines: action.Lines,
        currency: action.Currency,
      };
      return {
        ...state,
        summary: {
          ...state.summary,
          catalogs,
        },
      };

    // -------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
