import { notify } from "Redux/user/actions";
import { apFly, backend, apPySharpies } from "System/system";
// -------------------------------------------------------
import {saveProjectAfterTimeout} from "Redux/projects/actions";

// -------------------------------------------------------
// -------------------------------------------------------
export const setOutputCurrency =
  (project, outputCurrency, cb) => async (dispatch) => {
    dispatch({ type: "SET_OUTPUT_CURRENCY", outputCurrency });
    const { database, datadir, currency } = project;
    const exchange_rate =
      outputCurrency === "USD" ? 1 : 1 / currency[outputCurrency.toLowerCase()];
    
    backend("outputs", "setOutputCurrency", {
      outputCurrency, projectid: project._id
    });

    await apFly("set_output_currency", {
      datadir,
      database,
      exchange_rate
    });

    cb();
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const calculateCurrencyAdjustments =
  (project, cb) => async (dispatch) => {
    dispatch({ type: "SET_OUTPUT_CURRENCY", outputCurrency: "USD" });
    const { database, datadir, currency } = project;
    const exchange_rates = {};
    Object.keys(currency).forEach((key) => {
      exchange_rates[key.toUpperCase()] = currency[key];
    });
    await apFly("adjust_currencies", {
      datadir,
      database,
      exchange_rates: JSON.stringify(exchange_rates),
    });
    dispatch({ type: "FRAME_CURRENCY_ADJUSTED", frameCurrencyAdjusted: true });
    backend("outputs", "frameCurrencyAdjusted", { projectid: project._id });

    cb();
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const setProjectCurrencies = (currency) => async (dispatch) => {
  dispatch({ type: "RESET_SUMMARY" });
  dispatch({ type: "IS_CURRENCY_ADJUSTED", currencyAdjusted: false });

  dispatch({
    type: "UPDATE_CURRENCY",
    currency,
  });
  dispatch(saveProjectAfterTimeout());

  dispatch(
    notify("Currency Changed", "Make sure to rebuild Master and outputs", 8)
  );
};
