import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { fetchNorming, syncNorming, clearNorming } from "Redux/norming/actions";

// ----------------------------------------------------------------
// ----------------------------------------------------------------
const NormingButtons = ({ datatype, size, unsynced, children }) => {
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);

  const dispatch = useDispatch();

  return (
    <table>
      <tr>
        <td>
          <div className="buttons has-addons">
            <button
              className={`button is-small ${loading ? "is-loading" : ""}`}
              onClick={() => {
                setLoading(true);
                dispatch(
                  fetchNorming(datatype, (result) => {
                    setLoading(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-cloud-download-alt" />
              </span>
              <span>Generate from Frame</span>
            </button>
            <button
              className={`button ${unsynced ? "is-warning" : ""} is-small ${
                syncing ? "is-loading" : ""
              }`}
              onClick={() => {
                setSyncing(true);
                dispatch(
                  syncNorming(datatype, (result) => {
                    setSyncing(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-cloud-upload-alt" />
              </span>
              <span>Sync with Frame</span>
            </button>
            <button
              className={`button is-small`}
              onClick={() => {
                if (
                  window.confirm("Are you sure? This action cannot be undone.")
                ) {
                  dispatch(clearNorming(datatype, (result) => {}));
                }
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-hand-sparkles" />
              </span>
              <span>Clear</span>
            </button>
          </div>
        </td>
        <td>&nbsp;&nbsp;</td>
        <td className="vmid">
          Number of entries: <div className="tag">{size}</div>
        </td>
        <td>&nbsp;&nbsp;</td>
        <td>&nbsp;&nbsp;</td>
        <td>&nbsp;&nbsp;</td>
        {children}
      </tr>
    </table>
  );
};

export default NormingButtons;
