import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
import ProgressBar from "Components/ProgressBar";
// -------------------------------------------------------------
import {
  setSongDetails,
  songDetails,
  loadNorming,
} from "Redux/norming/actions";
// -------------------------------------------------------------
import { stem } from "Aux/";
import waitbar from "Media/waitbar.gif";

const datatype = "details";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [loadingNorming, setLoadingNorming] = useState(false);
  const [predicting, setPredicting] = useState(false);
  const [reviewing, setReviewing] = useState(null);

  const norming = useSelector((state) => state.norming);

  const { data, unsynced } = norming[datatype];

  useEffect(() => {
    dispatch(loadNorming(datatype));
  }, []);

  if (loadingNorming) return <img src={waitbar} />;

  return (
    <div>
      <ProgressBar />
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        {data ? (
          <td>
            <button
              className={`button is-small ${predicting ? "is-loading" : ""}`}
              onClick={() => {
                setPredicting(true);
                dispatch(
                  songDetails((result) => {
                    setPredicting(false);
                  })
                );
              }}
            >
              <span className="icon is-small">
                <i className="fa-duotone fa-wifi" />
              </span>
              <span>Scan Song Details</span>
            </button>
          </td>
        ) : null}
      </NormingButtons>
      <MainTable />
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({}) => {
  const dispatch = useDispatch();
  const norming = useSelector((state) => state.norming);
  const { data, predictions } = norming[datatype];
  if (!data) return null;

  const onEdit = (type, rowindex, value) => {
    dispatch(setSongDetails(rowindex, type, value));
  };

  return (
    <div className="table-wrapper">
      <table className="table is-fullwidth table-compact">
        <thead>
          <tr>
            <th>Cnt</th>
            <th>ID/URL</th>
            <th>Artist</th>
            <th>Song</th>
            <th>Release</th>
            <th>Featured</th>
            <th>Type</th>
            <th>Album</th>
            <th>ISRC</th>
            <th>Duration</th>
            <th>Spotify URL</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => {
            const idx = rowindex + 1;
            const predAM = predictions[rowindex]
              ? predictions[rowindex].resAM
              : null;
            const predSP = predictions[rowindex]
              ? predictions[rowindex].resSP
              : null;

            return (
              <tr key={`row-${rowindex}`}>
                <td>{idx}</td>
                <td>
                  {row["Consensus_Title_SB"]}
                  <br />
                  {predAM ? (
                    <>
                      <a href={predAM.appleURL} target="_blank">
                        AAPL
                      </a>
                      <br />
                    </>
                  ) : null}
                  {predSP ? (
                    <>
                      <a href={predSP.spotifyURL} target="_blank">
                        SPOT
                      </a>
                      <br />
                    </>
                  ) : null}
                </td>

                <Triple
                  row={row}
                  rowindex={rowindex}
                  top="Release_Artist_9LC"
                  which={"artist"}
                  predAM={predAM}
                  predSP={predSP}
                />
                <Triple
                  row={row}
                  rowindex={rowindex}
                  top="Song_Name_9LC"
                  which="song"
                  predAM={predAM}
                  predSP={predSP}
                />
                <Triple
                  row={row}
                  rowindex={rowindex}
                  top="Release_Date_9LC"
                  which="releaseDate"
                  predAM={predAM}
                  predSP={predSP}
                />
                <Triple
                  row={row}
                  rowindex={rowindex}
                  top="Featured_Artist_9LC"
                  which="featured"
                  predAM={predAM}
                  predSP={predSP}
                />
                <Triple
                  row={row}
                  rowindex={rowindex}
                  top="Project_Type_9LC"
                  which="albumType"
                  predAM={predAM}
                  predSP={predSP}
                />
                <Triple
                  row={row}
                  rowindex={rowindex}
                  top="Album_Name_9LC"
                  which="album"
                  predAM={predAM}
                  predSP={predSP}
                />
                <Triple top="" which="isrc" predAM={predAM} predSP={predSP} />
                <Triple
                  row={row}
                  rowindex={rowindex}
                  top=""
                  which="duration"
                  predAM={predAM}
                  predSP={predSP}
                />
                <td>{row["Spotify_URL_9LC"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Triple = ({ top, row, rowindex, predAM, predSP, which }) => {
  const isEqual = (a, b) => {
    if (!a) a = "";
    if (!b) b = "";
    return stem(String(a)) === stem(String(b));
  };

  const apple = predAM ? predAM[which] : "";
  const spotify = predSP ? predSP[which] : "";
  const color = isEqual(apple, spotify) ? "is-success" : "is-danger";
  return (
    <td>
      {top ? (
        <DetailsItem
          tagcolor="is-info"
          rowindex={rowindex}
          row={row}
          top={top}
        />
      ) : null}
      <br />
      <div className={`tag is-small is-light ${color}`}>
        {predAM ? predAM[which] : ""}
      </div>
      <br />
      <div className={`tag is-small is-light ${color}`}>
        {predSP ? predSP[which] : ""}
      </div>
      <br />
    </td>
  );
};

const DetailsItem = ({ row, rowindex, top, tagcolor }) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(row[top]);
  const dispatch = useDispatch();

  const submit = () => {
    setEdit(false);
    dispatch(setSongDetails(rowindex, top, value));
  };

  if (edit)
    return (
      <input
        value={value}
        onChange={(evt) => {
          setValue(evt.target.value);
        }}
        className="input"
        type="text"
        onBlur={submit}
        onKeyDown={(evt) => evt.key === "Enter" && submit()}
        autoFocus={true}
      />
    );

  return (
    <div
      onClick={() => setEdit(true)}
      className={`tag ${tagcolor} is-light is-clickable`}
    >
      {row[top]}
    </div>
  );
};
