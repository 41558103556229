export const geoData = {
  countries: [
    {
      country: "Unknown",
      alpha2: "00",
      alpha3: "000",
      region: "",
      subRegion: "",
      intermediateRegion: "",
    },
    {
      country: "Afghanistan",
      alpha2: "AF",
      alpha3: "AFG",
      region: "Asia",
      subRegion: "Southern Asia",
      intermediateRegion: "",
    },
    {
      country: "Åland Islands",
      alpha2: "AX",
      alpha3: "ALA",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Albania",
      alpha2: "AL",
      alpha3: "ALB",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Algeria",
      alpha2: "DZ",
      alpha3: "DZA",
      region: "Africa",
      subRegion: "Northern Africa",
      intermediateRegion: "",
    },
    {
      country: "American Samoa",
      alpha2: "AS",
      alpha3: "ASM",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "Andorra",
      alpha2: "AD",
      alpha3: "AND",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Angola",
      alpha2: "AO",
      alpha3: "AGO",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      country: "Anguilla",
      alpha2: "AI",
      alpha3: "AIA",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Antarctica",
      alpha2: "AQ",
      alpha3: "ATA",
      region: "",
      subRegion: "",
      intermediateRegion: "",
    },
    {
      country: "Antigua and Barbuda",
      alpha2: "AG",
      alpha3: "ATG",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Argentina",
      alpha2: "AR",
      alpha3: "ARG",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Armenia",
      alpha2: "AM",
      alpha3: "ARM",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Aruba",
      alpha2: "AW",
      alpha3: "ABW",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Australia",
      alpha2: "AU",
      alpha3: "AUS",
      region: "Oceania",
      subRegion: "Australia and New Zealand",
      intermediateRegion: "",
    },
    {
      country: "Austria",
      alpha2: "AT",
      alpha3: "AUT",
      region: "Europe",
      subRegion: "Western Europe",
      intermediateRegion: "",
    },
    {
      country: "Azerbaijan",
      alpha2: "AZ",
      alpha3: "AZE",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Bahamas",
      alpha2: "BS",
      alpha3: "BHS",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Bahrain",
      alpha2: "BH",
      alpha3: "BHR",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Bangladesh",
      alpha2: "BD",
      alpha3: "BGD",
      region: "Asia",
      subRegion: "Southern Asia",
      intermediateRegion: "",
    },
    {
      country: "Barbados",
      alpha2: "BB",
      alpha3: "BRB",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Belarus",
      alpha2: "BY",
      alpha3: "BLR",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "Belgium",
      alpha2: "BE",
      alpha3: "BEL",
      region: "Europe",
      subRegion: "Western Europe",
      intermediateRegion: "",
    },
    {
      country: "Belize",
      alpha2: "BZ",
      alpha3: "BLZ",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Central America",
    },
    {
      country: "Benin",
      alpha2: "BJ",
      alpha3: "BEN",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Bermuda",
      alpha2: "BM",
      alpha3: "BMU",
      region: "Americas",
      subRegion: "Northern America",
      intermediateRegion: "",
    },
    {
      country: "Bhutan",
      alpha2: "BT",
      alpha3: "BTN",
      region: "Asia",
      subRegion: "Southern Asia",
      intermediateRegion: "",
    },
    {
      country: "Bolivia",
      alpha2: "BO",
      alpha3: "BOL",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Bonaire, Sint Eustatius and Saba",
      alpha2: "BQ",
      alpha3: "BES",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Bosnia And Herzegovina",
      alpha2: "BA",
      alpha3: "BIH",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Botswana",
      alpha2: "BW",
      alpha3: "BWA",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Southern Africa",
    },
    {
      country: "Bouvet Island",
      alpha2: "BV",
      alpha3: "BVT",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Brazil",
      alpha2: "BR",
      alpha3: "BRA",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "British Indian Ocean Territory",
      alpha2: "IO",
      alpha3: "IOT",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Brunei Darussalam",
      alpha2: "BN",
      alpha3: "BRN",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Bulgaria",
      alpha2: "BG",
      alpha3: "BGR",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "Burkina Faso",
      alpha2: "BF",
      alpha3: "BFA",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Burundi",
      alpha2: "BI",
      alpha3: "BDI",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Cabo Verde",
      alpha2: "CV",
      alpha3: "CPV",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Cambodia",
      alpha2: "KH",
      alpha3: "KHM",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Cameroon",
      alpha2: "CM",
      alpha3: "CMR",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      country: "Canada",
      alpha2: "CA",
      alpha3: "CAN",
      region: "Americas",
      subRegion: "Northern America",
      intermediateRegion: "",
    },
    {
      country: "Cayman Islands",
      alpha2: "KY",
      alpha3: "CYM",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Central African Republic",
      alpha2: "CF",
      alpha3: "CAF",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      country: "Chad",
      alpha2: "TD",
      alpha3: "TCD",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      country: "Chile",
      alpha2: "CL",
      alpha3: "CHL",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "China",
      alpha2: "CN",
      alpha3: "CHN",
      region: "Asia",
      subRegion: "Eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Christmas Island",
      alpha2: "CX",
      alpha3: "CXR",
      region: "Oceania",
      subRegion: "Australia and New Zealand",
      intermediateRegion: "",
    },
    {
      country: "Cocos (Keeling) Islands",
      alpha2: "CC",
      alpha3: "CCK",
      region: "Oceania",
      subRegion: "Australia and New Zealand",
      intermediateRegion: "",
    },
    {
      country: "Colombia",
      alpha2: "CO",
      alpha3: "COL",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Comoros",
      alpha2: "KM",
      alpha3: "COM",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Congo",
      alpha2: "CG",
      alpha3: "COG",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      country: "Congo (Democratic Republic Of The)",
      alpha2: "CD",
      alpha3: "COD",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      country: "Cook Islands",
      alpha2: "CK",
      alpha3: "COK",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "Costa Rica",
      alpha2: "CR",
      alpha3: "CRI",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Central America",
    },
    {
      country: "Côte D'Ivoire",
      alpha2: "CI",
      alpha3: "CIV",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Croatia",
      alpha2: "HR",
      alpha3: "HRV",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Cuba",
      alpha2: "CU",
      alpha3: "CUB",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Curaçao",
      alpha2: "CW",
      alpha3: "CUW",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Cyprus",
      alpha2: "CY",
      alpha3: "CYP",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Czech Republic",
      alpha2: "CZ",
      alpha3: "CZE",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "Denmark",
      alpha2: "DK",
      alpha3: "DNK",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Djibouti",
      alpha2: "DJ",
      alpha3: "DJI",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Dominica",
      alpha2: "DM",
      alpha3: "DMA",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Dominican Republic",
      alpha2: "DO",
      alpha3: "DOM",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Ecuador",
      alpha2: "EC",
      alpha3: "ECU",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Egypt",
      alpha2: "EG",
      alpha3: "EGY",
      region: "Africa",
      subRegion: "Northern Africa",
      intermediateRegion: "",
    },
    {
      country: "El Salvador",
      alpha2: "SV",
      alpha3: "SLV",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Central America",
    },
    {
      country: "Equatorial Guinea",
      alpha2: "GQ",
      alpha3: "GNQ",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      country: "Eritrea",
      alpha2: "ER",
      alpha3: "ERI",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Estonia",
      alpha2: "EE",
      alpha3: "EST",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Eswatini",
      alpha2: "SZ",
      alpha3: "SWZ",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Southern Africa",
    },
    {
      country: "Ethiopia",
      alpha2: "ET",
      alpha3: "ETH",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Falkland Islands (Malvinas)",
      alpha2: "FK",
      alpha3: "FLK",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Faroe Islands",
      alpha2: "FO",
      alpha3: "FRO",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Fiji",
      alpha2: "FJ",
      alpha3: "FJI",
      region: "Oceania",
      subRegion: "Melanesia",
      intermediateRegion: "",
    },
    {
      country: "Finland",
      alpha2: "FI",
      alpha3: "FIN",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "France",
      alpha2: "FR",
      alpha3: "FRA",
      region: "Europe",
      subRegion: "Western Europe",
      intermediateRegion: "",
    },
    {
      country: "French Guiana",
      alpha2: "GF",
      alpha3: "GUF",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "French Polynesia",
      alpha2: "PF",
      alpha3: "PYF",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "French Southern Territories",
      alpha2: "TF",
      alpha3: "ATF",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Gabon",
      alpha2: "GA",
      alpha3: "GAB",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      country: "Gambia",
      alpha2: "GM",
      alpha3: "GMB",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Georgia",
      alpha2: "GE",
      alpha3: "GEO",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Germany",
      alpha2: "DE",
      alpha3: "DEU",
      region: "Europe",
      subRegion: "Western Europe",
      intermediateRegion: "",
    },
    {
      country: "Ghana",
      alpha2: "GH",
      alpha3: "GHA",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Gibraltar",
      alpha2: "GI",
      alpha3: "GIB",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Greece",
      alpha2: "GR",
      alpha3: "GRC",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Greenland",
      alpha2: "GL",
      alpha3: "GRL",
      region: "Americas",
      subRegion: "Northern America",
      intermediateRegion: "",
    },
    {
      country: "Grenada",
      alpha2: "GD",
      alpha3: "GRD",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Guadeloupe",
      alpha2: "GP",
      alpha3: "GLP",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Guam",
      alpha2: "GU",
      alpha3: "GUM",
      region: "Oceania",
      subRegion: "Micronesia",
      intermediateRegion: "",
    },
    {
      country: "Guatemala",
      alpha2: "GT",
      alpha3: "GTM",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Central America",
    },
    {
      country: "Guernsey",
      alpha2: "GG",
      alpha3: "GGY",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "Channel Islands",
    },
    {
      country: "Guinea",
      alpha2: "GN",
      alpha3: "GIN",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Guinea Bissau",
      alpha2: "GW",
      alpha3: "GNB",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Guyana",
      alpha2: "GY",
      alpha3: "GUY",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Haiti",
      alpha2: "HT",
      alpha3: "HTI",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Heard Island and McDonald Islands",
      alpha2: "HM",
      alpha3: "HMD",
      region: "Oceania",
      subRegion: "Australia and New Zealand",
      intermediateRegion: "",
    },
    {
      country: "Holy See",
      alpha2: "VA",
      alpha3: "VAT",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Honduras",
      alpha2: "HN",
      alpha3: "HND",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Central America",
    },
    {
      country: "Hong Kong",
      alpha2: "HK",
      alpha3: "HKG",
      region: "Asia",
      subRegion: "Eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Hungary",
      alpha2: "HU",
      alpha3: "HUN",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "Iceland",
      alpha2: "IS",
      alpha3: "ISL",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "India",
      alpha2: "IN",
      alpha3: "IND",
      region: "Asia",
      subRegion: "Southern Asia",
      intermediateRegion: "",
    },
    {
      country: "Indonesia",
      alpha2: "ID",
      alpha3: "IDN",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Iran",
      alpha2: "IR",
      alpha3: "IRN",
      region: "Asia",
      subRegion: "Southern Asia",
      intermediateRegion: "",
    },
    {
      country: "Iraq",
      alpha2: "IQ",
      alpha3: "IRQ",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Ireland",
      alpha2: "IE",
      alpha3: "IRL",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Isle of Man",
      alpha2: "IM",
      alpha3: "IMN",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Israel",
      alpha2: "IL",
      alpha3: "ISR",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Italy",
      alpha2: "IT",
      alpha3: "ITA",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Jamaica",
      alpha2: "JM",
      alpha3: "JAM",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Japan",
      alpha2: "JP",
      alpha3: "JPN",
      region: "Asia",
      subRegion: "Eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Jersey",
      alpha2: "JE",
      alpha3: "JEY",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "Channel Islands",
    },
    {
      country: "Jordan",
      alpha2: "JO",
      alpha3: "JOR",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Kazakhstan",
      alpha2: "KZ",
      alpha3: "KAZ",
      region: "Asia",
      subRegion: "Central Asia",
      intermediateRegion: "",
    },
    {
      country: "Kenya",
      alpha2: "KE",
      alpha3: "KEN",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Kiribati",
      alpha2: "KI",
      alpha3: "KIR",
      region: "Oceania",
      subRegion: "Micronesia",
      intermediateRegion: "",
    },
    {
      country: "Korea, Republic of",
      alpha2: "KR",
      alpha3: "KOR",
      region: "Asia",
      subRegion: "Eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Kuwait",
      alpha2: "KW",
      alpha3: "KWT",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Kyrgyzstan",
      alpha2: "KG",
      alpha3: "KGZ",
      region: "Asia",
      subRegion: "Central Asia",
      intermediateRegion: "",
    },
    {
      country: "Laos",
      alpha2: "LA",
      alpha3: "LAO",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Latvia",
      alpha2: "LV",
      alpha3: "LVA",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Lebanon",
      alpha2: "LB",
      alpha3: "LBN",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Lesotho",
      alpha2: "LS",
      alpha3: "LSO",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Southern Africa",
    },
    {
      country: "Liberia",
      alpha2: "LR",
      alpha3: "LBR",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Libya",
      alpha2: "LY",
      alpha3: "LBY",
      region: "Africa",
      subRegion: "Northern Africa",
      intermediateRegion: "",
    },
    {
      country: "Liechtenstein",
      alpha2: "LI",
      alpha3: "LIE",
      region: "Europe",
      subRegion: "Western Europe",
      intermediateRegion: "",
    },
    {
      country: "Lithuania",
      alpha2: "LT",
      alpha3: "LTU",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Luxembourg",
      alpha2: "LU",
      alpha3: "LUX",
      region: "Europe",
      subRegion: "Western Europe",
      intermediateRegion: "",
    },
    {
      country: "Macao",
      alpha2: "MO",
      alpha3: "MAC",
      region: "Asia",
      subRegion: "Eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Macedonia",
      alpha2: "MK",
      alpha3: "MKD",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Madagascar",
      alpha2: "MG",
      alpha3: "MDG",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Malawi",
      alpha2: "MW",
      alpha3: "MWI",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Malaysia",
      alpha2: "MY",
      alpha3: "MYS",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Maldives",
      alpha2: "MV",
      alpha3: "MDV",
      region: "Asia",
      subRegion: "Southern Asia",
      intermediateRegion: "",
    },
    {
      country: "Mali",
      alpha2: "ML",
      alpha3: "MLI",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Malta",
      alpha2: "MT",
      alpha3: "MLT",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Marshall Islands",
      alpha2: "MH",
      alpha3: "MHL",
      region: "Oceania",
      subRegion: "Micronesia",
      intermediateRegion: "",
    },
    {
      country: "Martinique",
      alpha2: "MQ",
      alpha3: "MTQ",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Mauritania",
      alpha2: "MR",
      alpha3: "MRT",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Mauritius",
      alpha2: "MU",
      alpha3: "MUS",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Mayotte",
      alpha2: "YT",
      alpha3: "MYT",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Mexico",
      alpha2: "MX",
      alpha3: "MEX",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Central America",
    },
    {
      country: "Micronesia (Federated States of)",
      alpha2: "FM",
      alpha3: "FSM",
      region: "Oceania",
      subRegion: "Micronesia",
      intermediateRegion: "",
    },
    {
      country: "Moldova",
      alpha2: "MD",
      alpha3: "MDA",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "Monaco",
      alpha2: "MC",
      alpha3: "MCO",
      region: "Europe",
      subRegion: "Western Europe",
      intermediateRegion: "",
    },
    {
      country: "Mongolia",
      alpha2: "MN",
      alpha3: "MNG",
      region: "Asia",
      subRegion: "Eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Montenegro",
      alpha2: "ME",
      alpha3: "MNE",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Montserrat",
      alpha2: "MS",
      alpha3: "MSR",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Morocco",
      alpha2: "MA",
      alpha3: "MAR",
      region: "Africa",
      subRegion: "Northern Africa",
      intermediateRegion: "",
    },
    {
      country: "Mozambique",
      alpha2: "MZ",
      alpha3: "MOZ",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Myanmar",
      alpha2: "MM",
      alpha3: "MMR",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Namibia",
      alpha2: "NA",
      alpha3: "NAM",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Southern Africa",
    },
    {
      country: "Nauru",
      alpha2: "NR",
      alpha3: "NRU",
      region: "Oceania",
      subRegion: "Micronesia",
      intermediateRegion: "",
    },
    {
      country: "Nepal",
      alpha2: "NP",
      alpha3: "NPL",
      region: "Asia",
      subRegion: "Southern Asia",
      intermediateRegion: "",
    },
    {
      country: "Netherlands",
      alpha2: "NL",
      alpha3: "NLD",
      region: "Europe",
      subRegion: "Western Europe",
      intermediateRegion: "",
    },
    {
      country: "Netherlands Antilles",
      alpha2: "AN",
      alpha3: "ANT",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "New Caledonia",
      alpha2: "NC",
      alpha3: "NCL",
      region: "Oceania",
      subRegion: "Melanesia",
      intermediateRegion: "",
    },
    {
      country: "New Zealand",
      alpha2: "NZ",
      alpha3: "NZL",
      region: "Oceania",
      subRegion: "Australia and New Zealand",
      intermediateRegion: "",
    },
    {
      country: "Nicaragua",
      alpha2: "NI",
      alpha3: "NIC",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Central America",
    },
    {
      country: "Niger",
      alpha2: "NE",
      alpha3: "NER",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Nigeria",
      alpha2: "NG",
      alpha3: "NGA",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Niue",
      alpha2: "NU",
      alpha3: "NIU",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "Norfolk Island",
      alpha2: "NF",
      alpha3: "NFK",
      region: "Oceania",
      subRegion: "Australia and New Zealand",
      intermediateRegion: "",
    },
    {
      country: "North Korea",
      alpha2: "KP",
      alpha3: "PRK",
      region: "Asia",
      subRegion: "Eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Northern Mariana Islands",
      alpha2: "MP",
      alpha3: "MNP",
      region: "Oceania",
      subRegion: "Micronesia",
      intermediateRegion: "",
    },
    {
      country: "Norway",
      alpha2: "NO",
      alpha3: "NOR",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Oman",
      alpha2: "OM",
      alpha3: "OMN",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Pakistan",
      alpha2: "PK",
      alpha3: "PAK",
      region: "Asia",
      subRegion: "Southern Asia",
      intermediateRegion: "",
    },
    {
      country: "Palau",
      alpha2: "PW",
      alpha3: "PLW",
      region: "Oceania",
      subRegion: "Micronesia",
      intermediateRegion: "",
    },
    {
      country: "Palestine, State of",
      alpha2: "PS",
      alpha3: "PSE",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Panama",
      alpha2: "PA",
      alpha3: "PAN",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Central America",
    },
    {
      country: "Papua New Guinea",
      alpha2: "PG",
      alpha3: "PNG",
      region: "Oceania",
      subRegion: "Melanesia",
      intermediateRegion: "",
    },
    {
      country: "Paraguay",
      alpha2: "PY",
      alpha3: "PRY",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Peru",
      alpha2: "PE",
      alpha3: "PER",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Philippines",
      alpha2: "PH",
      alpha3: "PHL",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Pitcairn",
      alpha2: "PN",
      alpha3: "PCN",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "Poland",
      alpha2: "PL",
      alpha3: "POL",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "Portugal",
      alpha2: "PT",
      alpha3: "PRT",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Puerto Rico",
      alpha2: "PR",
      alpha3: "PRI",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Qatar",
      alpha2: "QA",
      alpha3: "QAT",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Réunion",
      alpha2: "RE",
      alpha3: "REU",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Romania",
      alpha2: "RO",
      alpha3: "ROU",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "Russia",
      alpha2: "RU",
      alpha3: "RUS",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "Rwanda",
      alpha2: "RW",
      alpha3: "RWA",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Saint Barthélemy",
      alpha2: "BL",
      alpha3: "BLM",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Saint Helena, Ascension and Tristan da Cunha",
      alpha2: "SH",
      alpha3: "SHN",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Saint Kitts and Nevis",
      alpha2: "KN",
      alpha3: "KNA",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Saint Lucia",
      alpha2: "LC",
      alpha3: "LCA",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Saint Martin (French part)",
      alpha2: "MF",
      alpha3: "MAF",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Saint Pierre and Miquelon",
      alpha2: "PM",
      alpha3: "SPM",
      region: "Americas",
      subRegion: "Northern America",
      intermediateRegion: "",
    },
    {
      country: "Saint Vincent and the Grenadines",
      alpha2: "VC",
      alpha3: "VCT",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Samoa",
      alpha2: "WS",
      alpha3: "WSM",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "San Marino",
      alpha2: "SM",
      alpha3: "SMR",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Sao Tome and Principe",
      alpha2: "ST",
      alpha3: "STP",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      country: "Saudi Arabia",
      alpha2: "SA",
      alpha3: "SAU",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Senegal",
      alpha2: "SN",
      alpha3: "SEN",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Serbia",
      alpha2: "RS",
      alpha3: "SRB",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Seychelles",
      alpha2: "SC",
      alpha3: "SYC",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Sierra Leone",
      alpha2: "SL",
      alpha3: "SLE",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Singapore",
      alpha2: "SG",
      alpha3: "SGP",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Sint Maarten (Dutch part)",
      alpha2: "SX",
      alpha3: "SXM",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Slovakia",
      alpha2: "SK",
      alpha3: "SVK",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "Slovenia",
      alpha2: "SI",
      alpha3: "SVN",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Solomon Islands",
      alpha2: "SB",
      alpha3: "SLB",
      region: "Oceania",
      subRegion: "Melanesia",
      intermediateRegion: "",
    },
    {
      country: "Somalia",
      alpha2: "SO",
      alpha3: "SOM",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "South Africa",
      alpha2: "ZA",
      alpha3: "ZAF",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Southern Africa",
    },
    {
      country: "South Georgia and the South Sandwich Islands",
      alpha2: "GS",
      alpha3: "SGS",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "South Sudan",
      alpha2: "SS",
      alpha3: "SSD",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Spain",
      alpha2: "ES",
      alpha3: "ESP",
      region: "Europe",
      subRegion: "Southern Europe",
      intermediateRegion: "",
    },
    {
      country: "Sri Lanka",
      alpha2: "LK",
      alpha3: "LKA",
      region: "Asia",
      subRegion: "Southern Asia",
      intermediateRegion: "",
    },
    {
      country: "Sudan",
      alpha2: "SD",
      alpha3: "SDN",
      region: "Africa",
      subRegion: "Northern Africa",
      intermediateRegion: "",
    },
    {
      country: "Suriname",
      alpha2: "SR",
      alpha3: "SUR",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Svalbard and Jan Mayen",
      alpha2: "SJ",
      alpha3: "SJM",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Sweden",
      alpha2: "SE",
      alpha3: "SWE",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "Switzerland",
      alpha2: "CH",
      alpha3: "CHE",
      region: "Europe",
      subRegion: "Western Europe",
      intermediateRegion: "",
    },
    {
      country: "Syria",
      alpha2: "SY",
      alpha3: "SYR",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Taiwan",
      alpha2: "TW",
      alpha3: "TWN",
      region: "Asia",
      subRegion: "Eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Tajikistan",
      alpha2: "TJ",
      alpha3: "TJK",
      region: "Asia",
      subRegion: "Central Asia",
      intermediateRegion: "",
    },
    {
      country: "Tanzania",
      alpha2: "TZ",
      alpha3: "TZA",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Thailand",
      alpha2: "TH",
      alpha3: "THA",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Timor-Leste",
      alpha2: "TL",
      alpha3: "TLS",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Togo",
      alpha2: "TG",
      alpha3: "TGO",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      country: "Tokelau",
      alpha2: "TK",
      alpha3: "TKL",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "Tonga",
      alpha2: "TO",
      alpha3: "TON",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "Trinidad and Tobago",
      alpha2: "TT",
      alpha3: "TTO",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Tunisia",
      alpha2: "TN",
      alpha3: "TUN",
      region: "Africa",
      subRegion: "Northern Africa",
      intermediateRegion: "",
    },
    {
      country: "Turkey",
      alpha2: "TR",
      alpha3: "TUR",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Turkmenistan",
      alpha2: "TM",
      alpha3: "TKM",
      region: "Asia",
      subRegion: "Central Asia",
      intermediateRegion: "",
    },
    {
      country: "Turks and Caicos Islands",
      alpha2: "TC",
      alpha3: "TCA",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Tuvalu",
      alpha2: "TV",
      alpha3: "TUV",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "Uganda",
      alpha2: "UG",
      alpha3: "UGA",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Ukraine",
      alpha2: "UA",
      alpha3: "UKR",
      region: "Europe",
      subRegion: "Eastern Europe",
      intermediateRegion: "",
    },
    {
      country: "United Arab Emirates",
      alpha2: "AE",
      alpha3: "ARE",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "United Kingdom",
      alpha2: "GB",
      alpha3: "GBR",
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "",
    },
    {
      country: "United States",
      alpha2: "US",
      alpha3: "USA",
      region: "Americas",
      subRegion: "Northern America",
      intermediateRegion: "",
    },
    {
      country: "United States Minor Outlying Islands",
      alpha2: "UM",
      alpha3: "UMI",
      region: "Oceania",
      subRegion: "Micronesia",
      intermediateRegion: "",
    },
    {
      country: "Uruguay",
      alpha2: "UY",
      alpha3: "URY",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Uzbekistan",
      alpha2: "UZ",
      alpha3: "UZB",
      region: "Asia",
      subRegion: "Central Asia",
      intermediateRegion: "",
    },
    {
      country: "Vanuatu",
      alpha2: "VU",
      alpha3: "VUT",
      region: "Oceania",
      subRegion: "Melanesia",
      intermediateRegion: "",
    },
    {
      country: "Venezuela",
      alpha2: "VE",
      alpha3: "VEN",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      country: "Vietnam",
      alpha2: "VN",
      alpha3: "VNM",
      region: "Asia",
      subRegion: "South-eastern Asia",
      intermediateRegion: "",
    },
    {
      country: "Virgin Islands (British)",
      alpha2: "VG",
      alpha3: "VGB",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Virgin Islands (U.S.)",
      alpha2: "VI",
      alpha3: "VIR",
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      country: "Wallis and Futuna",
      alpha2: "WF",
      alpha3: "WLF",
      region: "Oceania",
      subRegion: "Polynesia",
      intermediateRegion: "",
    },
    {
      country: "Western Sahara",
      alpha2: "EH",
      alpha3: "ESH",
      region: "Africa",
      subRegion: "Northern Africa",
      intermediateRegion: "",
    },
    {
      country: "Yemen",
      alpha2: "YE",
      alpha3: "YEM",
      region: "Asia",
      subRegion: "Western Asia",
      intermediateRegion: "",
    },
    {
      country: "Zambia",
      alpha2: "ZM",
      alpha3: "ZMB",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      country: "Zimbabwe",
      alpha2: "ZW",
      alpha3: "ZWE",
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
  ],
  regions: [
    { region: "Asia" },
    { region: "Europe" },
    { region: "Africa" },
    { region: "Oceania" },
    { region: "Americas" },
  ],
  subRegions: [
    { region: "Europe", subRegion: "Scandinavia" },
    { region: "Asia", subRegion: "Southern Asia" },
    { region: "Europe", subRegion: "Northern Europe" },
    { region: "Europe", subRegion: "Southern Europe" },
    { region: "Africa", subRegion: "Northern Africa" },
    { region: "Oceania", subRegion: "Polynesia" },
    { region: "Africa", subRegion: "Sub-Saharan Africa" },
    { region: "Americas", subRegion: "Latin America and the Caribbean" },
    { region: "Asia", subRegion: "Western Asia" },
    { region: "Oceania", subRegion: "Australia and New Zealand" },
    { region: "Europe", subRegion: "Western Europe" },
    { region: "Europe", subRegion: "Eastern Europe" },
    { region: "Americas", subRegion: "Northern America" },
    { region: "Asia", subRegion: "South-eastern Asia" },
    { region: "Asia", subRegion: "Eastern Asia" },
    { region: "Oceania", subRegion: "Melanesia" },
    { region: "Oceania", subRegion: "Micronesia" },
    { region: "Asia", subRegion: "Central Asia" },
  ],
  intermediateRegions: [
    {
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Middle Africa",
    },
    {
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Caribbean",
    },
    {
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "South America",
    },
    {
      region: "Americas",
      subRegion: "Latin America and the Caribbean",
      intermediateRegion: "Central America",
    },
    {
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Western Africa",
    },
    {
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Southern Africa",
    },
    {
      region: "Africa",
      subRegion: "Sub-Saharan Africa",
      intermediateRegion: "Eastern Africa",
    },
    {
      region: "Europe",
      subRegion: "Northern Europe",
      intermediateRegion: "Channel Islands",
    },
  ],
};
