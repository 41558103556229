import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// -------------------------------------------------------------
import { newProject } from "Redux/projects/actions";
import { notify } from "Redux/user/actions";

// ------------------------------------------------------
// ------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState("");
  const [running, setRunning] = useState(false);

  const user = useSelector((state) => state.user);

  const handleChange = (evt) => {
    const string = evt.target.value;
    if (string.length > 39) {
      dispatch(
        notify(
          "Max Title Length Exceeded",
          "Project titles must not exceed 39 characters",
          2
        )
      );
    }
    setTitle(string.substr(0, 39));
  };

  const submit = () => {
    if (title) {
      dispatch(newProject(title, user.user, history));
      setRunning(true);
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">New Project</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              dispatch({ type: "NEW_PROJECT_HIDE" });
            }}
          />
        </header>
        <section className="modal-card-body">
          <div className="context-block">
            Title
            <input
              id="entry"
              type="text"
              value={title}
              className="input"
              placeholder="Enter Title"
              onChange={handleChange}
              autoFocus={true}
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button ${running ? "is-loading" : ""} is-primary`}
            disabled={!title}
            onClick={submit}
          >
            Add Project
          </button>

          <button
            className="button"
            onClick={() => {
              dispatch({ type: "NEW_PROJECT_HIDE" });
            }}
          >
            Discard
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Index;
