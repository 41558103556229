import { apFly, backend, apPySharpies, engine } from "System/system";
import store from "System/mainStore";
import { saveProjectAfterTimeout } from "Redux/projects/actions";
import { io } from "socket.io-client";
import { getSongs } from "Redux/filters/actions";

// -------------------------------------------------------
// -------------------------------------------------------
export const fromDB = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project,
    project: { datadir, database, _id },
  } = projects;
  if (project) {
    const result = await apFly("fromdb", { datadir, database });
    dispatch({ type: "SET_ACTIVE_FRAMES", activeFrames: result.data.frames });
    dispatch({ type: "SET_PROJECT_HAS_FRAME" });
    dispatch(saveProjectAfterTimeout(project));
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const escapeFrame = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project,
    project: { datadir, database, _id },
  } = projects;
  if (project) {
    await apFly("escape", { datadir, database });
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const activateFrame = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;
  const result = await apFly("activate", { datadir, database });
  dispatch({ type: "SET_ACTIVE_FRAMES", activeFrames: result.data.frames });
  cb();
};
// -------------------------------------------------------
// -------------------------------------------------------
export const deactivateFrame = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;
  const result = await apFly("deactivate", { datadir, database });
  dispatch({ type: "SET_ACTIVE_FRAMES", activeFrames: result.data.frames });
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const readFrame = (frameid, cb) => async (dispatch) => {
  dispatch({ type: "SET_FRAME_DATA", frameData: "loading" });
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;
  const result = await apFly("read", { datadir, database, frameid });
  dispatch({ type: "SET_FRAME_DATA", frameData: result.data });
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getActiveFrames = () => async (dispatch) => {
  const projects = store.getState().projects;
  const frame = store.getState().frame;
  const { project } = projects;

  const result = await apFly("frames", {});
  console.log("Get Active Frames", result);
  dispatch({ type: "SET_ACTIVE_FRAMES", activeFrames: result.data.frames });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getStats = (cb) => async (dispatch) => {
  /*
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;
  const result = await apFly("stats", { database });
  cb(result.data);
  */
};

// -------------------------------------------------------
// -------------------------------------------------------
export const downloadNorming = (datatype, cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project: { database },
  } = projects;
  const result = await apFly("downloadNorming", { datatype, database });
  cb(result.data);
};

// -------------------------------------------------------
// -------------------------------------------------------
export const downloadTriple = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project: { database },
  } = projects;
  const result = await apFly("downloadNormingTripleSongs", { database });
  cb(result.data);
};

// -------------------------------------------------------
// -------------------------------------------------------
export const doNorming = (datatype, cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;
  await apFly("doNormingAfterUpload", { datadir, datatype, database });
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const replaceMaster = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project: { datadir, database },
  } = projects;
  await apFly("todb", { datadir, database });
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const toCSV = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project: { database },
  } = projects;
  const result = await apFly("downloadMaster", { database });
  cb(result.data);
};

// -------------------------------------------------------
// -------------------------------------------------------
export const publish = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const { project } = projects;
  const projectStatus = "published";
  await backend("outputs", "setStatus", {
    projectid: project._id,
    projectStatus,
  });
  dispatch({ type: "UPDATE_PROJECT_STATUS", projectStatus });

  const socket = io("https://api.bademeister-jan.pro:336");
  socket.on("start", () => {
    console.log("Socket started");
    socket.emit("GenerateCatalogOutput", {
      data_directory: `/mnt/disks/birddata2/birddata/${project._id}/`,
      otherOutputs: "standard",
      yearParam: "NormalData",
      theyear: 1,
      currency: "USD",
      currencyValue: 1,
      returnValue: false,
    });
  });
  socket.on("end", () => {
    console.log("Socket disconnected");
    socket.disconnect();
    socket.close();
    cb();
  });
  console.log("Done publishing");
};

// -------------------------------------------------------
// -------------------------------------------------------
export const generateOutputs = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const { project } = projects;
  const excludedSongs = project.excludedSongs;
  const setDiff = (a, b) => {
    return a.filter((x) => !b.includes(x));
  };
  
  getSongs(async songs => {
    const includedSongs = setDiff(songs, excludedSongs);
    await apPySharpies("GenerateTabOutput", {
      catalog_id: project._id,
      included_songs: JSON.stringify(includedSongs),
    });
    cb();
  })

};

// -------------------------------------------------------
// -------------------------------------------------------
export const release = (cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const { project } = projects;
  const projectStatus = "released";
  console.log("Releasing now");
  await backend("outputs", "setStatus", {
    projectid: project._id,
    projectStatus,
  });
  dispatch({ type: "UPDATE_PROJECT_STATUS", projectStatus });
  console.log("Has released");
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const doPredict =
  (projectid, datatype, keyPerson, cb) => async (dispatch) => {
    const result = await backend("songnorm", "norm", { projectid, keyPerson });
    let uri = null;
    if (result) {
      uri = result.data.uri;
    }
    cb({ uri });
  };

// -------------------------------------------------------
// -------------------------------------------------------

export const updateProjectStatus =
  (projectStatus, projectid) => async (dispatch) => {
    await backend("outputs", "setStatus", {
      projectid,
      projectStatus,
    });
    dispatch({ type: "UPDATE_PROJECT_STATUS", projectStatus });
  };

// -------------------------------------------------------
// -------------------------------------------------------
export const fetchNorming = (datatype, cb) => async (dispatch) => {
  const projects = store.getState().projects;
  const {
    project: { database },
  } = projects;
  const result = await apFly("fetchNorming", { datatype, database });
  cb(result);
};

// -------------------------------------------------------
// -------------------------------------------------------
export const predictSource = (title, cb) => async (dispatch) => {
  const result = await apFly("predictSource", { title });
  if (result.data) {
    console.log(title, result.data);
    cb(result.data.prediction);
    return;
  }
  cb();
};

// -------------------------------------------------------
// -------------------------------------------------------
export const songDetails = (title, artist, cb) => async (dispatch) => {
  engine("songnorm", "spotify", { title, artist }).then((result) => {
    if (result.data) {
      const song = {};
      if (result.data.result) {
        const data = result.data.result;
        console.log(data);
        const [year, month, day] = data["releaseDate"].split("-");
        data["releaseDate"] = `${day}/${month}/${year}`;

        const map = {
          Release_Artist_9LC: "artist",
          Featured_Artist_9LC: "featured",
          Release_Date_9LC: "releaseDate",
          Album_Name_9LC: "album",
          Project_Type_9LC: "albumType",
        };

        Object.keys(map).forEach((key) => {
          song[key] = data[map[key]];
        });
      }
      cb(song);
      return;
    }
    cb({});
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateNorming =
  (datatype, row, updatecols, cb) => async (dispatch) => {
    console.log("Row is", row);
    const projects = store.getState().projects;
    const {
      project: { datadir, database },
    } = projects;
    dispatch({ type: "SET_BACKEND_ACCESS", backendaccess: true });
    await apFly("updateNorming", {
      datadir,
      datatype,
      database,
      updatecols: JSON.stringify(updatecols),
      row,
    });
    dispatch({ type: "SET_BACKEND_ACCESS", backendaccess: false });
    cb();
  };
