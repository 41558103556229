import axios from "axios";
import queryString from "query-string";
import store from "System/mainStore";

// -------------------------------------------------------------
// -------------------------------------------------------------
const IS_LOCAL = window.location.href.slice(7, 16) === "localhost";

const backendHost =
  IS_LOCAL && false
    ? "http://localhost:8080"
    : "https://api.bademeister-jan.pro";

const apPyHost =
  IS_LOCAL && false
    ? "http://localhost:5000"
    : "https://api.bademeister-jan.pro:330";

const apPySharpiesHost =
  IS_LOCAL && false
    ? "http://localhost:5100"
    : "https://api.bademeister-jan.pro:336";

const outPyHost = "https://api.bademeister-jan.pro:335";

const flythonHost = "https://api.bademeister-jan.pro:337";

const engineHost = backendHost;

// -------------------------------------------------------------
// -------------------------------------------------------------
export const backend = async (rubric, call, input) => {
  store.dispatch({ type: "SET_DB_ACCESS", dbaccess: true });
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    url: backendHost + "/" + rubric + "/" + call,
    data: queryString.stringify(input),
  };
  const request = await axios(requestData);
  store.dispatch({ type: "SET_DB_ACCESS", dbaccess: false });
  return request;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const engine = (rubric, call, input) => {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    url: engineHost + "/" + rubric + "/" + call,
    data: queryString.stringify(input),
  };
  const request = axios(requestData);
  return request;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const apPy = (rubric, call, input) => {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    url: apPyHost + "/" + rubric + "/" + call,
    data: queryString.stringify(input),
  };
  const request = axios(requestData);
  return request;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const apPySharpies = (call, input) => {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    url: apPySharpiesHost + "/" + call,
    data: queryString.stringify(input),
  };
  const request = axios(requestData);
  return request;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const apFly = async (call, input) => {
  store.dispatch({ type: "SET_BACKEND_ACCESS", backendaccess: true });
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    url: flythonHost + "/" + call,
    data: queryString.stringify(input),
  };
  const request = await axios(requestData);
  store.dispatch({ type: "SET_BACKEND_ACCESS", backendaccess: false });
  return request;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const outPy = (call, input) => {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    url: outPyHost + "/" + call,
    data: queryString.stringify(input),
  };
  const request = axios(requestData);
  return request;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const upload = async (formData, onProgress) => {
  const config = {
    onUploadProgress: (progressEvent) => {
      onProgress(
        Math.round((100 * progressEvent.loaded) / progressEvent.total)
      );
    },
  };
  return axios.post(backendHost + "/upload", formData, config);
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const uploadAndReadCSV = (formData) => {
  return axios.post(backendHost + "/uploadAndReadCSV", formData);
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const uploadNorming = (formData) => {
  return axios.post(backendHost + "/uploadNorming", formData);
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const download = (queryArg) => {
  let query = `?fid=${encodeURI(queryArg)}`;
  window.location.href = `${backendHost}/download/${query}`;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const downloadFile = (filepath, file) => {
  let query = `?filepath=${encodeURI(filepath)}&file=${encodeURI(file)}`;
  window.location.href = `${backendHost}/file${query}`;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const getStaticURI = (path) => {
  const rhs = path.split("birddata/")[1];
  return `${backendHost}${rhs}`;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const downloadDataFile = (fileid, datatype) => {
  console.log("In Download", fileid);
  let query = `?fileid=${encodeURI(fileid)}&datatype=${datatype}`;
  window.location.href = `${backendHost}/datadata/${query}`;
};

// -------------------------------------------------------------
// -------------------------------------------------------------
export const doDownloadOutputFile = (file) => {
  console.log("In Download Output File", file);
  let query = `?file=${encodeURI(file)}`;
  console.log(`${backendHost}/output_data/${query}`);
  window.location.href = `${backendHost}/output_data/${query}`;
};
